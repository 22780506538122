// src/stores/malaysiaListingStore.js
import { defineStore } from 'pinia';
import { db } from '../../firebase';
import { doc, getDoc, query, collection, where, getDocs } from 'firebase/firestore';

export const useMalaysiaListingStore = defineStore('malaysiaListing', {
    state: () => ({
        property: null,
        similarProperties: [],
        propertyImages: [],
        isLoading: false,
        error: null,
    }),
    actions: {
        async fetchProperty(glb_id) {
            this.isLoading = true;
            try {
                const docRef = doc(db, "property_listings", glb_id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    this.property = docSnap.data();
                    this.propertyImages = this.property.image_link || [];
                } else {
                    console.log("No such property!");
                }
            } catch (error) {
                console.error("Error fetching property:", error);
                this.error = error;
            } finally {
                this.isLoading = false;
            }
        },
        async fetchSimilarProperties(currentGlbId) {
            this.isLoading = true;
            try {
                const q = query(
                    collection(db, "property_listings"),
                    where("glb_id", ">=", "GLB_MY_"),
                    where("glb_id", "<", "GLB_MY_~")
                );
                const querySnapshot = await getDocs(q);
                this.similarProperties = querySnapshot.docs
                    .map(doc => doc.data())
                    .filter(property => property.glb_id !== currentGlbId); // Exclude the current property
            } catch (error) {
                console.error("Error fetching similar properties:", error);
                this.error = error;
            } finally {
                this.isLoading = false;
            }
        },
    }
});
