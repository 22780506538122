<template>
    <div>
      <h2>WhatsApp Phone Verification</h2>
      <div>
        <label for="phone">Phone Number:</label>
        <input type="tel" v-model="phoneNumber" placeholder="+1234567890" />
        <button @click="sendWhatsAppCode">Send Verification Code via WhatsApp</button>
      </div>
  
      <div v-if="codeSent">
        <label for="code">Verification Code:</label>
        <input type="text" v-model="verificationCode" />
        <button @click="verifyWhatsAppCode">Verify Code</button>
      </div>
  
      <div v-if="verificationStatus">{{ verificationStatus }}</div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        phoneNumber: '',
        verificationCode: '',
        codeSent: false,
        verificationStatus: '',
      };
    },
    methods: {
      async sendWhatsAppCode() {
        try {
          const response = await axios.post('http://localhost:3000/send-whatsapp-code', {
            phoneNumber: this.phoneNumber,
          });
          this.codeSent = true;
          this.verificationStatus = 'Code sent via WhatsApp';
          console.log(response)
        } catch (error) {
          this.verificationStatus = `Error: ${error.response.data.message}`;
        }
      },
      async verifyWhatsAppCode() {
        try {
          const response = await axios.post('http://localhost:3000/verify-whatsapp-code', {
            phoneNumber: this.phoneNumber,
            code: this.verificationCode,
          });
  
          if (response.data.message === 'Phone number verified successfully') {
            this.verificationStatus = 'Phone number verified successfully!';
          } else {
            this.verificationStatus = 'Verification failed. Try again.';
          }
        } catch (error) {
          this.verificationStatus = `Error: ${error.response.data.message}`;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add some basic styles */
  </style>
  