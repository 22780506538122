<template>
    <div :key="$i18n.locale" class="yellow-skin">

        <!-- This is the preloader -->
        <!-- <div class="preloader"></div> -->
        <div id="main-wrapper">
           <!-- Start Navigation -->
           <Navigation />
			<!-- ============================ End Navigation ================================== -->

            <div class="clearfix"></div>
            <!-- ============================ Page Title Start================================== -->
            <section class="image-cover faq-sec text-center"
                :style="{ backgroundImage: `url(${require('@/assets/img/bg.jpg')})` }" data-overlay="6">
                <div class="container">
                    <div class="row">

                        <div class="col-lg-12 col-md-12 position-relative z-1">
                            <h1 class="text-light">Global Visa Services</h1>
                            <!-- <div class="faq-search">
                                <form>
                                    <input name="search" class="form-control" placeholder="Search Your Query...">
                                    <button type="submit" class="theme-cl"> <i class="ti-search"></i> </button>
                                </form>
                            </div> -->
                        </div>

                    </div>
                </div>
            </section>
            <!-- ============================ Page Title End ================================== -->

            <!-- ================= Global Visa Services ================= -->
            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-10 col-md-12 col-sm-12" style="margin-left: 50px;">
                            <!-- Single Basics List -->
                            <div class="faq_wrap">
                                <div class="faq_wrap_title">
                                    <!-- <h4>Basics</h4> -->
                                </div>
                                <div class="faq_wrap_body mb-5">
                                    <div class="accordion" id="generalac">
                                        <!-- Greece Golden Visa -->
                                        <div class="card">
                                            <div class="card-header" id="headingOne">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#Greece_Golden_Visa" aria-expanded="true"
                                                        aria-controls="collapseOne">
                                                        <img :src="oldIcon" alt="Icon"
                                                            style="width: 30px; height: 30px; margin-right: 8px;" />
                                                        <h3 style="display: inline-block; margin: 0;">
                                                            {{ $t('visa.greece_header') }}
                                                        </h3>
                                                    </button>
                                                </h2>
                                            </div>

                                            <div id="Greece_Golden_Visa" class="collapse show"
                                                aria-labelledby="headingOne" data-parent="#generalac">
                                                <div class="card-body">
                                                    <div class="col-lg-12 col-md-12">
                                                        <div class="pricing_wrap">
                                                            <div class="fr-grid-thumb">
                                                                <a href="#">
                                                                    <img :src="greeceFlag" class="img-fluid mx-auto"
                                                                        alt="" />
                                                                </a>
                                                            </div>
                                                            <div class="prt_head">
                                                                <h4>{{ $t('visa.greece') }}</h4>
                                                                <p>{{ $t('visa.greece_description') }}</p>
                                                            </div>
                                                            <div class="prt_body">
                                                                <ul>
                                                                    <li>{{ $t('visa.greece_title1') }}</li>
                                                                    <p>{{ $t('visa.greece_text1') }}</p>
                                                                    <li>{{ $t('visa.greece_title2') }}</li>
                                                                    <p>{{ $t('visa.greece_text2') }}</p>
                                                                    <li>{{ $t('visa.greece_title3') }}</li>
                                                                    <p>{{ $t('visa.greece_text3') }}</p>
                                                                    <li>{{ $t('visa.greece_title4') }}</li>
                                                                    <p>{{ $t('visa.greece_text4') }}</p>
                                                                    <li>{{ $t('visa.greece_title5') }}</li>
                                                                    <p>{{ $t('visa.greece_text5') }}</p>
                                                                    <li>{{ $t('visa.greece_title6') }}</li>
                                                                    <p>{{ $t('visa.greece_text6') }}</p>
                                                                    <li>{{ $t('visa.greece_title7') }}</li>
                                                                    <p>{{ $t('visa.greece_text7') }}</p>
                                                                </ul>
                                                            </div>
                                                            <div class="prt_footer">
                                                                <a href="https://wa.link/fw20eo"
                                                                    class="btn choose_package">
                                                                    {{ $t('visa.enquire_now') }}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Spain Golden Visa -->
                                        <div class="card">
                                            <div class="card-header" id="headingTwo">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#Spain_Golden_Visa"
                                                        aria-expanded="false" aria-controls="collapseTwo">
                                                        <img :src="oldIcon" alt="Icon"
                                                            style="width: 30px; height: 30px; margin-right: 8px;" />
                                                        <h3 style="display: inline-block; margin: 0;">
                                                            {{ $t('visa.spain_header') }}
                                                        </h3>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="Spain_Golden_Visa" class="collapse" aria-labelledby="headingTwo"
                                                data-parent="#generalac">
                                                <div class="card-body">
                                                    <div class="col-lg-12 col-md-12">
                                                        <div class="pricing_wrap">
                                                            <div class="fr-grid-thumb">
                                                                <a href="#">
                                                                    <img :src="spanyolFlag" class="img-fluid mx-auto"
                                                                        alt="" />
                                                                </a>
                                                            </div>
                                                            <div class="prt_head">
                                                                <h4>{{ $t('visa.spain') }}</h4>
                                                                <p>{{ $t('visa.spain_description') }}</p>
                                                            </div>
                                                            <div class="prt_body">
                                                                <ul>
                                                                    <li>{{ $t('visa.spain_title1') }}</li>
                                                                    <p>{{ $t('visa.spain_text1') }}</p>
                                                                    <li>{{ $t('visa.spain_title2') }}</li>
                                                                    <p>{{ $t('visa.spain_text2') }}</p>
                                                                    <li>{{ $t('visa.spain_title3') }}</li>
                                                                    <p>{{ $t('visa.spain_text3') }}</p>
                                                                    <li>{{ $t('visa.spain_title4') }}</li>
                                                                    <p>{{ $t('visa.spain_text4') }}</p>
                                                                    <li>{{ $t('visa.spain_title5') }}</li>
                                                                    <p>{{ $t('visa.spain_text5') }}</p>
                                                                    <li>{{ $t('visa.spain_title6') }}</li>
                                                                    <p>{{ $t('visa.spain_text6_1') }}</p>
                                                                    <p>{{ $t('visa.spain_text6_2') }}</p>
                                                                    <p>{{ $t('visa.spain_text6_3') }}</p>
                                                                </ul>
                                                            </div>
                                                            <div class="prt_footer">
                                                                <a href="https://wa.link/fw20eo"
                                                                    class="btn choose_package">
                                                                    {{ $t('visa.enquire_now') }}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Portugal Golden Visa -->
                                        <div class="card">
                                            <div class="card-header" id="headingThree">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#Portugal_Golden_Visa"
                                                        aria-expanded="false" aria-controls="collapseThree">
                                                        <img :src="oldIcon" alt="Icon"
                                                            style="width: 30px; height: 30px; margin-right: 8px;" />
                                                        <h3 style="display: inline-block; margin: 0;">
                                                            {{ $t('visa.portugal_header') }}
                                                        </h3>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="Portugal_Golden_Visa" class="collapse"
                                                aria-labelledby="headingThree" data-parent="#generalac">
                                                <div class="card-body">
                                                    <div class="col-lg-12 col-md-12">
                                                        <div class="pricing_wrap">
                                                            <div class="fr-grid-thumb">
                                                                <a href="#">
                                                                    <img :src="portugalFlag" class="img-fluid mx-auto"
                                                                        alt="">
                                                                </a>
                                                            </div>
                                                            <div class="prt_head">
                                                                <h4>{{ $t('visa.portugal') }}</h4>
                                                                <p>{{ $t('visa.portugal_description') }}</p>
                                                            </div>
                                                            <div class="prt_body">
                                                                <ul>
                                                                    <li>{{ $t('visa.portugal_title1') }}</li>
                                                                    <p>{{ $t('visa.portugal_text1') }}</p>
                                                                    <li>{{ $t('visa.portugal_title2') }}</li>
                                                                    <p>{{ $t('visa.portugal_text2') }}</p>
                                                                    <li>{{ $t('visa.portugal_title3') }}</li>
                                                                    <p>{{ $t('visa.portugal_text3') }}</p>
                                                                    <li>{{ $t('visa.portugal_title4') }}</li>
                                                                    <p>{{ $t('visa.portugal_text4') }}</p>
                                                                    <li>{{ $t('visa.portugal_title5') }}</li>
                                                                    <p>{{ $t('visa.portugal_text5') }}</p>
                                                                    <li>{{ $t('visa.portugal_title6') }}</li>
                                                                    <p>{{ $t('visa.portugal_text6') }}</p>
                                                                    <li>{{ $t('visa.portugal_title7') }}</li>
                                                                    <p>{{ $t('visa.portugal_text7') }}</p>
                                                                    <li>{{ $t('visa.portugal_title8') }}</li>
                                                                    <p>{{ $t('visa.portugal_text8') }}</p>
                                                                    <li>{{ $t('visa.portugal_title9') }}</li>
                                                                    <p>{{ $t('visa.portugal_text9') }}</p>
                                                                </ul>
                                                            </div>
                                                            <div class="prt_footer">
                                                                <a href="https://wa.link/fw20eo"
                                                                    class="btn choose_package">
                                                                    {{ $t('visa.enquire_now') }}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Cyprus Golden Visa -->
                                        <div class="card">
                                            <div class="card-header" id="headingFour">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#Cyprus_Golden_Visa"
                                                        aria-expanded="false" aria-controls="collapseFour">
                                                        <img :src="oldIcon" alt="Icon"
                                                            style="width: 30px; height: 30px; margin-right: 8px;" />
                                                        <h3 style="display: inline-block; margin: 0;">
                                                            {{ $t('visa.cyprus_header') }}
                                                        </h3>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="Cyprus_Golden_Visa" class="collapse" aria-labelledby="headingFour"
                                                data-parent="#generalac">
                                                <div class="card-body">
                                                    <div class="col-lg-12 col-md-12">
                                                        <div class="pricing_wrap">
                                                            <div class="fr-grid-thumb">
                                                                <a href="#">
                                                                    <img :src="cyprusFlag" class="img-fluid mx-auto"
                                                                        alt="" />
                                                                </a>
                                                            </div>
                                                            <div class="prt_head">
                                                                <h4>{{ $t('visa.cyprus') }}</h4>
                                                                <p>{{ $t('visa.cyprus_description') }}</p>
                                                            </div>
                                                            <div class="prt_body">
                                                                <ul>
                                                                    <li>{{ $t('visa.cyprus_title1') }}</li>
                                                                    <p>{{ $t('visa.cyprus_text1') }}</p>
                                                                    <li>{{ $t('visa.cyprus_title2') }}</li>
                                                                    <p>{{ $t('visa.cyprus_text2') }}</p>
                                                                    <li>{{ $t('visa.cyprus_title3') }}</li>
                                                                    <p>{{ $t('visa.cyprus_text3') }}</p>
                                                                    <li>{{ $t('visa.cyprus_title4') }}</li>
                                                                    <p>{{ $t('visa.cyprus_text4') }}</p>
                                                                    <li>{{ $t('visa.cyprus_title5') }}</li>
                                                                    <p>{{ $t('visa.cyprus_text5') }}</p>
                                                                </ul>
                                                            </div>
                                                            <div class="prt_footer">
                                                                <a href="https://wa.link/fw20eo"
                                                                    class="btn choose_package">
                                                                    {{ $t('visa.enquire_now') }}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- UK Innovator Founder Visa -->
                                        <div class="card">
                                            <div class="card-header" id="headingFive">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#UK_Innovator_Visa"
                                                        aria-expanded="false" aria-controls="collapseFive">
                                                        <img :src="oldIcon" alt="Icon"
                                                            style="width: 30px; height: 30px; margin-right: 8px;" />
                                                        <h3 style="display: inline-block; margin: 0;">
                                                            {{ $t('visa.uk_header') }}
                                                        </h3>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="UK_Innovator_Visa" class="collapse" aria-labelledby="headingFive"
                                                data-parent="#generalac">
                                                <div class="card-body">
                                                    <div class="col-lg-12 col-md-12">
                                                        <div class="pricing_wrap">
                                                            <div class="fr-grid-thumb">
                                                                <a href="#">
                                                                    <img :src="ukFlag" class="img-fluid mx-auto"
                                                                        alt="" />
                                                                </a>
                                                            </div>
                                                            <div class="prt_head">
                                                                <h4>{{ $t('visa.uk') }}</h4>
                                                                <p>{{ $t('visa.uk_description') }}</p>
                                                            </div>
                                                            <div class="prt_body">
                                                                <ul>
                                                                    <li>{{ $t('visa.uk_title1') }}</li>
                                                                    <p>{{ $t('visa.uk_text1') }}</p>
                                                                    <li>{{ $t('visa.uk_title2') }}</li>
                                                                    <p>{{ $t('visa.uk_text2') }}</p>
                                                                    <li>{{ $t('visa.uk_title3') }}</li>
                                                                    <p>{{ $t('visa.uk_text3') }}</p>
                                                                    <li>{{ $t('visa.uk_title4') }}</li>
                                                                    <p>{{ $t('visa.uk_text4') }}</p>
                                                                    <li>{{ $t('visa.uk_title5') }}</li>
                                                                    <p>{{ $t('visa.uk_text5') }}</p>
                                                                    <li>{{ $t('visa.uk_title6') }}</li>
                                                                    <p>{{ $t('visa.uk_text6_1') }}</p>
                                                                    <p>{{ $t('visa.uk_text6_2') }}</p>
                                                                    <p>{{ $t('visa.uk_text6_3') }}</p>
                                                                    <p>{{ $t('visa.uk_text6_4') }}</p>
                                                                    <li>{{ $t('visa.uk_title7') }}</li>
                                                                    <p>{{ $t('visa.uk_text7') }}</p>
                                                                    <li>{{ $t('visa.uk_title8') }}</li>
                                                                    <p>{{ $t('visa.uk_text8') }}</p>
                                                                </ul>
                                                            </div>
                                                            <div class="prt_footer">
                                                                <a href="https://wa.link/fw20eo"
                                                                    class="btn choose_package">
                                                                    {{ $t('visa.enquire_now') }}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Italy Golden Visa -->
                                        <div class="card">
                                            <div class="card-header" id="headingSix">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#Italy_Golden_Visa"
                                                        aria-expanded="false" aria-controls="collapseSix">
                                                        <img :src="oldIcon" alt="Icon"
                                                            style="width: 30px; height: 30px; margin-right: 8px;" />
                                                        <h3 style="display: inline-block; margin: 0;">
                                                            {{ $t('visa.italy_header') }}
                                                        </h3>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="Italy_Golden_Visa" class="collapse" aria-labelledby="headingSix"
                                                data-parent="#generalac">
                                                <div class="card-body">
                                                    <div class="col-lg-12 col-md-12">
                                                        <div class="pricing_wrap">
                                                            <div class="fr-grid-thumb">
                                                                <a href="#">
                                                                    <img :src="italyFlag" class="img-fluid mx-auto"
                                                                        alt="" />
                                                                </a>
                                                            </div>
                                                            <div class="prt_head">
                                                                <h4>{{ $t('visa.italy') }}</h4>
                                                                <p>{{ $t('visa.italy_description') }}</p>
                                                            </div>
                                                            <div class="prt_body">
                                                                <ul>
                                                                    <li>{{ $t('visa.italy_title1') }}</li>
                                                                    <li>{{ $t('visa.italy_title2') }}</li>
                                                                    <li>{{ $t('visa.italy_title3') }}</li>
                                                                    <li>{{ $t('visa.italy_title4') }}</li>
                                                                    <li>{{ $t('visa.italy_title5') }}</li>
                                                                    <li>{{ $t('visa.italy_title6') }}</li>
                                                                    <li>{{ $t('visa.italy_title7') }}</li>
                                                                    <li>{{ $t('visa.italy_title8') }}</li>
                                                                    <p>- {{ $t('visa.italy_text8_1') }}</p>
                                                                    <p>- {{ $t('visa.italy_text8_2') }}</p>
                                                                    <p>- {{ $t('visa.italy_text8_3') }}</p>
                                                                    <li>{{ $t('visa.italy_title9') }}</li>
                                                                    <p>{{ $t('visa.italy_text9_1') }}</p>
                                                                    <p>{{ $t('visa.italy_text9_2') }}</p>
                                                                    <p>{{ $t('visa.italy_text9_3') }}</p>
                                                                    <p>{{ $t('visa.italy_text9_4') }}</p>
                                                                    <p>{{ $t('visa.italy_text9_5') }}</p>
                                                                    <li>{{ $t('visa.italy_title10') }}</li>
                                                                    <p>- {{ $t('visa.italy_text10_1') }}</p>
                                                                    <p>- {{ $t('visa.italy_text10_2') }}</p>
                                                                    <p>- {{ $t('visa.italy_text10_3') }}</p>
                                                                </ul>
                                                            </div>
                                                            <div class="prt_footer">
                                                                <a href="https://wa.link/fw20eo"
                                                                    class="btn choose_package">
                                                                    {{ $t('visa.enquire_now') }}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- ================= Our Mission ================= -->

            <!-- ============================ Call To Action ================================== -->
            <PageFooter />
            <!-- ============================ Call To Action End ================================== -->
            <!-- ============================ Footer Start ================================== -->
            <!-- ============================ Footer End ================================== -->
        </div>
    </div>
</template>

<script>
import 'magnific-popup';
import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';

import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import sampleImage1 from '@/assets/img/p-4.webp';
import oldIcon from '@/assets/img/old.png';
import greeceFlag from '@/assets/img/greece_flag.webp';
import spanyolFlag from '@/assets/img/spanyol_flag.webp';
import portugalFlag from '@/assets/img/portugal_flag.webp';
import ukFlag from '@/assets/img/united-kingdom.webp';
import italyFlag from '@/assets/img/italy.webp';
import usFlag from '@/assets/img/united-states.webp';
import cyprusFlag from '@/assets/img/cyprus_flag.webp';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';

export default {
    name: 'GlobalVisa',
    components: {
		Navigation,
        PageFooter,
	},
    props: ['glb_id'],
    data() {
        return {
            addProperty,
            menuWidget,
            profileLogo,
            forgotPasswordEmail: '',
            resetPasswordError: '',
            resetPasswordSuccess: '',
            selectedLanguage: this.$i18n.locale,
            phoneNumber: '',
            loginEmail: '',
            loginPassword: '',
            fullName: '',
            registerEmail: '',
            registerPassword: '',
            globalHomeLogo,
            whatsappLogo,
            wechatLogo,
            loginLogo,
            settingsLogo,
            qrCodeLogo,
            sampleImage1,
            greeceFlag,
            spanyolFlag,
            portugalFlag,
            ukFlag,
            italyFlag,
            usFlag,
            cyprusFlag,
            oldIcon,
            activeSubmenu: null,
            navMenuVisible: false,
            userLoggedIn: false,
            loginError: '',
        };
    },
    methods: {
        toggleNavMenu() {
            this.navMenuVisible = !this.navMenuVisible;
        },
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
    },
};
</script>

<style scoped>
.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
	background-color: white;
	/* Set background color to white */
	color: #000000;
	/* Set icon color */
	/* padding: 5px; */
	/* Adjust padding to ensure icon is centered */
	border: none;
	cursor: pointer;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	/* Center the icon */
	width: 50px;
	/* Set a fixed width for the button */
	height: 50px;
	/* Set a fixed height for the button */
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	/* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.input-with-icon select.form-control {
    padding-bottom: 1px;
    /* Adjust the value as needed */
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
}

.add-listing a {
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

.d-flex {
    flex-wrap: nowrap;
}

.bg-danger {
    background-color: #d9534f;
    color: white;
}

a img {
    vertical-align: middle;
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
}

#main-wrapper {
    padding-top: 70px;
}

.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left:40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 150px;
    margin-left: 50px;
}
</style>
