<template>
    <div :key="$i18n.locale" class="yellow-skin">
        <div id="main-wrapper">
            <!-- Start Navigation -->
            <Navigation />
            <!-- ============================ End Navigation ================================== -->
            <div class="clearfix"></div>
            <!-- ============================ Page Title Start================================== -->
            <div class="page-title" :style="{ background: `#f4f4f4 url(${bgImage})` }" data-overlay="5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">

                            <div class="breadcrumbs-wrap position-relative z-1">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item active" aria-current="page">{{
                                        $t('propertySubmit.advertiseYourProperty') }}</li>
                                </ol>
                                <h2 class="breadcrumb-title">{{ $t('propertySubmit.submitYourProperty') }}</h2>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- ============================ Page Title End ================================== -->

            <!-- ================= Property Submission Form ================= -->
            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">

                            <!-- Single Basics List -->
                            <div class="faq_wrap">
                                <div class="faq_wrap_title">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-10 col-md-12">
                                            <div class="sec-heading center">
                                                <h2>{{ $t('propertySubmit.propertyListingCountries') }}</h2>
                                                <p style="color: black;">{{
                                                    $t('propertySubmit.propertyCountriesText') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="custom-tab style-1" style="margin-left: 10px;">
                                                <ul class="nav nav-tabs pb-2 b-0" id="myTab" role="tablist">
                                                    <li class="nav-item">
                                                        <a class="nav-link active" id="Greece-tab" data-bs-toggle="tab"
                                                            href="#Greece" role="tab" aria-controls="Greece"
                                                            aria-selected="true">{{
                                                                $t('propertySubmit.greece') }}</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" id="Malaysia-tab" data-bs-toggle="tab"
                                                            href="#malaysia" role="tab" aria-controls="malaysia"
                                                            aria-selected="false">{{
                                                                $t('propertySubmit.malaysia') }}</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" id="Singapore-tab" data-bs-toggle="tab"
                                                            href="#singapore" role="tab" aria-controls="singapore"
                                                            aria-selected="false">{{
                                                                $t('propertySubmit.singapore') }}</a>
                                                    </li>
                                                </ul>
                                                <div class="tab-content" id="myTabContent">
                                                    <!-- Greece Tab -->
                                                    <div class="tab-pane fade show active" id="Greece" role="tabpanel"
                                                        aria-labelledby="Greece-tab">
                                                        <!-- ============================ Submit Property Start ================================== -->
                                                        <section>
                                                            <div class="container">
                                                                <div class="row">
                                                                    <div class="col-lg-12 col-md-12">

                                                                        <div class="alert alert-danger" role="alert">
                                                                            <p class="m-0">{{
                                                                                $t('propertySubmit.propertyCategoryText')
                                                                            }} <a href="#">{{
                                                                                    $t('propertySubmit.clickHere') }}
                                                                                </a>
                                                                            </p>
                                                                        </div>

                                                                    </div>

                                                                    <!-- Submit Form -->
                                                                    <div class="col-lg-12 col-md-12">

                                                                        <div class="submit-page p-0">

                                                                            <!-- Greece Basic Information -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>{{
                                                                                    $t('propertySubmit.greecePropertyDetailedInformation')
                                                                                }}
                                                                                </h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.propertyTitle')
                                                                                            }}<a href="#"
                                                                                                    class="tip-topdata"
                                                                                                    data-tip="Property Title"><i
                                                                                                        class="ti-help"></i></a></label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="propertyTitle">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.propertyStatus')
                                                                                            }}</label>
                                                                                            <select id="status"
                                                                                                class="form-control"
                                                                                                v-model="propertyStatus">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">{{
                                                                                                    $t('propertySubmit.forRent')
                                                                                                }}</option>
                                                                                                <option value="2">{{
                                                                                                    $t('propertySubmit.forSale')
                                                                                                }}</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.propertyType')
                                                                                            }}</label>
                                                                                            <select id="ptypes"
                                                                                                class="form-control"
                                                                                                v-model="propertyType">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">{{
                                                                                                    $t('propertySubmit.condominium')
                                                                                                }}</option>
                                                                                                <option value="2">{{
                                                                                                    $t('propertySubmit.apartment')
                                                                                                }}</option>
                                                                                                <option value="3">{{
                                                                                                    $t('propertySubmit.landedHouse')
                                                                                                }}</option>
                                                                                                <option value="4">{{
                                                                                                    $t('propertySubmit.commercial')
                                                                                                }}</option>
                                                                                                <option value="5">{{
                                                                                                    $t('propertySubmit.offices')
                                                                                                }}</option>
                                                                                                <option value="6">{{
                                                                                                    $t('propertySubmit.land')
                                                                                                }}
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.land')
                                                                                            }}</label>
                                                                                            <select id="pcategory"
                                                                                                class="form-control"
                                                                                                v-model="propertyCategory">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">{{
                                                                                                    $t('propertySubmit.new')
                                                                                                }}
                                                                                                </option>
                                                                                                <option value="2">
                                                                                                    {{
                                                                                                        $t('propertySubmit.secondHand')
                                                                                                    }}</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.surfaceArea')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="m²"
                                                                                                v-model="surfaceArea">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.plotSurfaceArea')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="m²"
                                                                                                v-model="plotSurfaceArea">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.price')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="Euro"
                                                                                                v-model="price">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.pricePerM2')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="Euro"
                                                                                                v-model="pricePerSqM">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.bedrooms')
                                                                                            }}</label>
                                                                                            <select id="bedrooms"
                                                                                                class="form-control"
                                                                                                v-model="bedrooms">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.bathrooms')
                                                                                            }}</label>
                                                                                            <select id="bathrooms"
                                                                                                class="form-control"
                                                                                                v-model="bathrooms">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Greece Gallery -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>{{ $t('propertySubmit.gallery') }}
                                                                                </h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row">
                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.uploadGallery')
                                                                                            }}</label>
                                                                                            <form action="#"
                                                                                                class="dropzone dz-clickable primary-dropzone">
                                                                                                <div class="card">
                                                                                                    <div class="drag-area"
                                                                                                        @dragover.prevent="onDragOver"
                                                                                                        @dragleave.prevent="onDragLeave"
                                                                                                        @drop.prevent="onDrop('greeceGallery')">
                                                                                                        <span
                                                                                                            v-if="!isDragging">
                                                                                                            {{
                                                                                                                $t('propertySubmit.dragAndDropImage')
                                                                                                            }}
                                                                                                            <span
                                                                                                                class="select"
                                                                                                                role="button"
                                                                                                                @click="selectFiles('greeceGallery')">
                                                                                                                {{
                                                                                                                    $t('propertySubmit.choose')
                                                                                                                }}
                                                                                                            </span>
                                                                                                        </span>
                                                                                                        <div v-else
                                                                                                            class="select">
                                                                                                            {{
                                                                                                                $t('propertySubmit.dropImagesHere')
                                                                                                            }}
                                                                                                        </div>
                                                                                                        <input
                                                                                                            name="file"
                                                                                                            type="file"
                                                                                                            class="file"
                                                                                                            ref="greeceFileInput"
                                                                                                            multiple
                                                                                                            @change="onFileSelect($event, 'greeceGallery')" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </form>
                                                                                            <!-- Image Preview Section for Greece -->
                                                                                            <div
                                                                                                class="image-preview-container">
                                                                                                <div class="image-preview"
                                                                                                    v-for="(image, index) in greeceGallery"
                                                                                                    :key="index">
                                                                                                    <span class="delete"
                                                                                                        @click="deleteImage(index, 'greeceGallery')">&times;</span>
                                                                                                    <img :src="image.url"
                                                                                                        alt="Image preview"
                                                                                                        class="image-preview-img" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>



                                                                            <!-- Property Video -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>{{
                                                                                    $t('propertySubmit.propertyVideo')
                                                                                }}</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.youtubeEmbedLink')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Location -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>{{
                                                                                    $t('propertySubmit.locationInGreece')
                                                                                }}</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.address')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="address">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.city')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="city">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.state')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="state">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.zipCode')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="zipCode">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Greece Property Detailed Information -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>{{
                                                                                    $t('propertySubmit.greecePropertyDetailedInformation')
                                                                                }}
                                                                                </h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.description')
                                                                                            }}</label>
                                                                                            <textarea
                                                                                                class="form-control h-120"
                                                                                                v-model="description"></textarea>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.buildingAgeOptional')
                                                                                            }}</label>
                                                                                            <select id="bage"
                                                                                                class="form-control"
                                                                                                v-model="buildingAge">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">0 - 5
                                                                                                    {{
                                                                                                        $t('propertySubmit.years')
                                                                                                    }}</option>
                                                                                                <option value="2">0 - 10
                                                                                                    {{
                                                                                                        $t('propertySubmit.years')
                                                                                                    }}</option>
                                                                                                <option value="3">0 - 15
                                                                                                    {{
                                                                                                        $t('propertySubmit.years')
                                                                                                    }}</option>
                                                                                                <option value="4">0 - 20
                                                                                                    {{
                                                                                                        $t('propertySubmit.years')
                                                                                                    }}</option>
                                                                                                <option value="5">20+
                                                                                                    {{
                                                                                                        $t('propertySubmit.years')
                                                                                                    }}</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.garage')
                                                                                            }}</label>
                                                                                            <select id="garage"
                                                                                                class="form-control"
                                                                                                v-model="garage">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.rooms')
                                                                                            }}</label>
                                                                                            <select id="rooms"
                                                                                                class="form-control"
                                                                                                v-model="rooms">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.heatingSystemOptional')
                                                                                            }}</label>
                                                                                            <select id="heatingSystem"
                                                                                                class="form-control"
                                                                                                v-model="heatingSystem">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">
                                                                                                    {{
                                                                                                        $t('propertySubmit.centralPetrol')
                                                                                                    }}
                                                                                                </option>
                                                                                                <option value="2">
                                                                                                    {{
                                                                                                        $t('propertySubmit.electricHeating')
                                                                                                    }}
                                                                                                </option>
                                                                                                <option value="3">{{
                                                                                                    $t('propertySubmit.heatPumps')
                                                                                                }}</option>
                                                                                                <option value="4">{{
                                                                                                    $t('propertySubmit.notAvailable')
                                                                                                }}</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.energyClassOptional')
                                                                                            }}</label>
                                                                                            <select id="energyClass"
                                                                                                class="form-control"
                                                                                                v-model="energyClass">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">227% -
                                                                                                    273%</option>
                                                                                                <option value="2">> 273%
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.completionYearOptional')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="completionYear">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.internalFeaturesOptional')
                                                                                            }}</label>
                                                                                            <div class="o-features">
                                                                                                <ul
                                                                                                    class="no-ul-list third-row">
                                                                                                    <li>
                                                                                                        <input id="a-1"
                                                                                                            class="form-check-input"
                                                                                                            name="a-1"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.fullFurnished">
                                                                                                        <label for="a-1"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.fullFurnished')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-2"
                                                                                                            class="form-check-input"
                                                                                                            name="a-2"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.partialFurnished">
                                                                                                        <label for="a-2"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.partialFurnished')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-3"
                                                                                                            class="form-check-input"
                                                                                                            name="a-3"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.noFurnished">
                                                                                                        <label for="a-3"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.noFurnished')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-4"
                                                                                                            class="form-check-input"
                                                                                                            name="a-4"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.elevator">
                                                                                                        <label for="a-4"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.elevator')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-5"
                                                                                                            class="form-check-input"
                                                                                                            name="a-5"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.internalStaircase">
                                                                                                        <label for="a-5"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.internalStaircase')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-6"
                                                                                                            class="form-check-input"
                                                                                                            name="a-6"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.nightPower">
                                                                                                        <label for="a-6"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.nightPower')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-7"
                                                                                                            class="form-check-input"
                                                                                                            name="a-7"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.secureDoor">
                                                                                                        <label for="a-7"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.secureDoor')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-8"
                                                                                                            class="form-check-input"
                                                                                                            name="a-8"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.doubleGlass">
                                                                                                        <label for="a-8"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.doubleGlass')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-9"
                                                                                                            class="form-check-input"
                                                                                                            name="a-9"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.painted">
                                                                                                        <label for="a-9"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.painted')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-10"
                                                                                                            class="form-check-input"
                                                                                                            name="a-10"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.wifi">
                                                                                                        <label
                                                                                                            for="a-10"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.wifi')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-11"
                                                                                                            class="form-check-input"
                                                                                                            name="a-11"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.fireplace">
                                                                                                        <label
                                                                                                            for="a-11"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.fireplace')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-12"
                                                                                                            class="form-check-input"
                                                                                                            name="a-12"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.woodFloor">
                                                                                                        <label
                                                                                                            for="a-12"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.woodFloorType')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-13"
                                                                                                            class="form-check-input"
                                                                                                            name="a-13"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.alarm">
                                                                                                        <label
                                                                                                            for="a-13"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.alarm')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-14"
                                                                                                            class="form-check-input"
                                                                                                            name="a-14"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.windowScreens">
                                                                                                        <label
                                                                                                            for="a-14"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.windowScreens')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-15"
                                                                                                            class="form-check-input"
                                                                                                            name="a-15"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.airConditioning">
                                                                                                        <label
                                                                                                            for="a-15"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.airConditioning')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-16"
                                                                                                            class="form-check-input"
                                                                                                            name="a-16"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.petsWelcome">
                                                                                                        <label
                                                                                                            for="a-16"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.petsWelcome')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-17"
                                                                                                            class="form-check-input"
                                                                                                            name="a-17"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.aluminumFrames">
                                                                                                        <label
                                                                                                            for="a-17"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.aluminiumFrames')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-18"
                                                                                                            class="form-check-input"
                                                                                                            name="a-18"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.mannedReception">
                                                                                                        <label
                                                                                                            for="a-18"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.mannedReception')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-19"
                                                                                                            class="form-check-input"
                                                                                                            name="a-19"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.storageSpace">
                                                                                                        <label
                                                                                                            for="a-19"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.storageSpace')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-20"
                                                                                                            class="form-check-input"
                                                                                                            name="a-20"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.marbleFloor">
                                                                                                        <label
                                                                                                            for="a-20"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.marbleFloorType')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-21"
                                                                                                            class="form-check-input"
                                                                                                            name="a-21"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.airy">
                                                                                                        <label
                                                                                                            for="a-21"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.airy')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-22"
                                                                                                            class="form-check-input"
                                                                                                            name="a-22"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.underfloorHeating">
                                                                                                        <label
                                                                                                            for="a-22"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.underfloorHeating')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-23"
                                                                                                            class="form-check-input"
                                                                                                            name="a-23"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.solarWaterHeating">
                                                                                                        <label
                                                                                                            for="a-23"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.solarWaterHeating')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-24"
                                                                                                            class="form-check-input"
                                                                                                            name="a-24"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.attic">
                                                                                                        <label
                                                                                                            for="a-24"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.attic')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-25"
                                                                                                            class="form-check-input"
                                                                                                            name="a-25"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.playroom">
                                                                                                        <label
                                                                                                            for="a-25"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.playroom')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-26"
                                                                                                            class="form-check-input"
                                                                                                            name="a-26"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.satelliteReceiver">
                                                                                                        <label
                                                                                                            for="a-26"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.satelliteReceiver')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-27"
                                                                                                            class="form-check-input"
                                                                                                            name="a-27"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.chargingFacilities">
                                                                                                        <label
                                                                                                            for="a-27"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.chargingFacilitiesForElectricCar')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-28"
                                                                                                            class="form-check-input"
                                                                                                            name="a-28"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.balcony">
                                                                                                        <label
                                                                                                            for="a-28"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.balcony')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-29"
                                                                                                            class="form-check-input"
                                                                                                            name="a-29"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.woodenFrames">
                                                                                                        <label
                                                                                                            for="a-29"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.woodenFrames')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-30"
                                                                                                            class="form-check-input"
                                                                                                            name="a-30"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.waterCloset">
                                                                                                        <label
                                                                                                            for="a-30"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.waterCloset')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.externalFeaturesOptional')
                                                                                            }}</label>
                                                                                            <div class="o-features">
                                                                                                <ul
                                                                                                    class="no-ul-list third-row">
                                                                                                    <li>
                                                                                                        <input id="a-1"
                                                                                                            class="form-check-input"
                                                                                                            name="a-1"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.privateGarden">
                                                                                                        <label for="a-1"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.privateGarden')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-2"
                                                                                                            class="form-check-input"
                                                                                                            name="a-2"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.awning">
                                                                                                        <label for="a-2"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.awning')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-3"
                                                                                                            class="form-check-input"
                                                                                                            name="a-3"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.sharedGarden">
                                                                                                        <label for="a-3"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.sharedGarden')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-4"
                                                                                                            class="form-check-input"
                                                                                                            name="a-4"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.facade">
                                                                                                        <label for="a-4"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.facade')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-5"
                                                                                                            class="form-check-input"
                                                                                                            name="a-5"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.roadType">
                                                                                                        <label for="a-5"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.roadTypeAsphalt')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-6"
                                                                                                            class="form-check-input"
                                                                                                            name="a-6"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.residentialZone">
                                                                                                        <label for="a-6"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.residentialZone')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-7"
                                                                                                            class="form-check-input"
                                                                                                            name="a-7"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.accessForDisabilities">
                                                                                                        <label for="a-7"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.accessForPeopleWithDisabilities')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-8"
                                                                                                            class="form-check-input"
                                                                                                            name="a-8"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.swimmingPool">
                                                                                                        <label for="a-8"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.swimmingPool')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-9"
                                                                                                            class="form-check-input"
                                                                                                            name="a-9"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.builtInBBQ">
                                                                                                        <label for="a-9"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.builtInBBQ')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-10"
                                                                                                            class="form-check-input"
                                                                                                            name="a-10"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.parkingSpace">
                                                                                                        <label
                                                                                                            for="a-10"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.parkingSpace')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-11"
                                                                                                            class="form-check-input"
                                                                                                            name="a-11"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.garden">
                                                                                                        <label
                                                                                                            for="a-11"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.garden')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-12"
                                                                                                            class="form-check-input"
                                                                                                            name="a-12"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.balcony">
                                                                                                        <label
                                                                                                            for="a-12"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.balconyDuplicate')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.suitableForOptional')
                                                                                            }}</label>
                                                                                            <div class="o-features">
                                                                                                <ul
                                                                                                    class="no-ul-list third-row">
                                                                                                    <li>
                                                                                                        <input id="a-1"
                                                                                                            class="form-check-input"
                                                                                                            name="a-1"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.student">
                                                                                                        <label for="a-1"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.student')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-2"
                                                                                                            class="form-check-input"
                                                                                                            name="a-2"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.holidayHome">
                                                                                                        <label for="a-2"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.holidayHome')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-3"
                                                                                                            class="form-check-input"
                                                                                                            name="a-3"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.investment">
                                                                                                        <label for="a-3"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.investment')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-4"
                                                                                                            class="form-check-input"
                                                                                                            name="a-4"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.shortTermRental">
                                                                                                        <label for="a-4"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.shortTermRental')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-5"
                                                                                                            class="form-check-input"
                                                                                                            name="a-5"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.commercialUse">
                                                                                                        <label for="a-5"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.commercialUse')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-6"
                                                                                                            class="form-check-input"
                                                                                                            name="a-6"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.medicalOffice">
                                                                                                        <label for="a-6"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.medicalOffice')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Contact Information -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>{{
                                                                                    $t('propertySubmit.contactInformation')
                                                                                }}</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.name')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="contactName">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.email')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="contactEmail">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.phone')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="contactPhone">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="form-group">
                                                                                <div class="col-lg-12 col-md-12">
                                                                                    <button @click="submitForm"
                                                                                        class="btn btn-danger"
                                                                                        type="submit">{{
                                                                                            $t('propertySubmit.submit')
                                                                                        }}</button>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </section>
                                                        <!-- ============================ Submit Property End ================================== -->
                                                    </div>

                                                    <!-- Malaysia Tab -->
                                                    <div class="tab-pane fade" id="malaysia" role="tabpanel"
                                                        aria-labelledby="Malaysia-tab">
                                                        <!-- ============================ Submit Property Start ================================== -->
                                                        <section>
                                                            <div class="container">
                                                                <div class="row">
                                                                    <div class="col-lg-12 col-md-12">

                                                                        <div class="alert alert-danger" role="alert">
                                                                            <p class="m-0">{{
                                                                                $t('propertySubmit.propertyCategoryText')
                                                                            }} <a href="#">{{
                                                                                    $t('propertySubmit.clickHere') }}
                                                                                </a>
                                                                            </p>
                                                                        </div>

                                                                    </div>

                                                                    <!-- Submit Form -->
                                                                    <div class="col-lg-12 col-md-12">

                                                                        <div class="submit-page p-0">

                                                                            <!-- Malaysia Basic Information -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>{{
                                                                                    $t('propertySubmit.malaysiaPropertyBasicInformation')
                                                                                }}
                                                                                </h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.propertyTitle')
                                                                                            }}<a href="#"
                                                                                                    class="tip-topdata"
                                                                                                    data-tip="Property Title"><i
                                                                                                        class="ti-help"></i></a></label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="propertyTitle">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.propertyStatus')
                                                                                            }}</label>
                                                                                            <select id="status"
                                                                                                class="form-control"
                                                                                                v-model="status">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">{{
                                                                                                    $t('propertySubmit.forRent')
                                                                                                }}</option>
                                                                                                <option value="2">{{
                                                                                                    $t('propertySubmit.forSale')
                                                                                                }}</option>
                                                                                            </select>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.propertyType')
                                                                                            }}</label>
                                                                                            <select id="property_type"
                                                                                                class="form-control"
                                                                                                v-model="propertyType">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">{{
                                                                                                    $t('propertySubmit.condominium')
                                                                                                }}</option>
                                                                                                <option value="2">{{
                                                                                                    $t('propertySubmit.apartment')
                                                                                                }}</option>
                                                                                                <option value="3">{{
                                                                                                    $t('propertySubmit.landedHouse')
                                                                                                }}</option>
                                                                                                <option value="4">{{
                                                                                                    $t('propertySubmit.commercial')
                                                                                                }}</option>
                                                                                                <option value="5">{{
                                                                                                    $t('propertySubmit.offices')
                                                                                                }}</option>
                                                                                                <option value="6">{{
                                                                                                    $t('propertySubmit.land')
                                                                                                }}
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.propertyCategory')
                                                                                            }}</label>
                                                                                            <select
                                                                                                id="property_category"
                                                                                                class="form-control"
                                                                                                v-model="propertyCategory">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">{{
                                                                                                    $t('propertySubmit.new')
                                                                                                }}
                                                                                                </option>
                                                                                                <option value="2">
                                                                                                    {{
                                                                                                        $t('propertySubmit.secondHand')
                                                                                                    }}</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.surfaceArea')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="ft²"
                                                                                                v-model="surfaceArea">
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.lotType')
                                                                                            }}</label>
                                                                                            <select
                                                                                                id="property_lot_type"
                                                                                                class="form-control"
                                                                                                v-model="lotType">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">
                                                                                                    {{
                                                                                                        $t('propertySubmit.internationalLot')
                                                                                                    }}
                                                                                                </option>
                                                                                                <option value="2">
                                                                                                    {{
                                                                                                        $t('propertySubmit.bumiReleased')
                                                                                                    }}
                                                                                                </option>
                                                                                                <option value="3">{{
                                                                                                    $t('propertySubmit.bumiLot')
                                                                                                }}</option>
                                                                                            </select>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.price')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="RM"
                                                                                                v-model="price">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.pricePerFt2')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="RM"
                                                                                                v-model="pricePerSqft">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.bedrooms')
                                                                                            }}</label>
                                                                                            <select id="bedrooms"
                                                                                                class="form-control"
                                                                                                v-model="bedrooms">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.bathrooms')
                                                                                            }}</label>
                                                                                            <select id="bathrooms"
                                                                                                class="form-control"
                                                                                                v-model="bathrooms">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Malaysia Gallery -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>{{ $t('propertySubmit.gallery') }}
                                                                                </h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row">
                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.uploadGallery')
                                                                                            }}</label>
                                                                                            <form action="#"
                                                                                                class="dropzone dz-clickable primary-dropzone">
                                                                                                <div class="card">
                                                                                                    <div class="drag-area"
                                                                                                        @dragover.prevent="onDragOver"
                                                                                                        @dragleave.prevent="onDragLeave"
                                                                                                        @drop.prevent="onDrop('malaysiaGallery')">
                                                                                                        <span
                                                                                                            v-if="!isDragging">
                                                                                                            {{
                                                                                                                $t('propertySubmit.dragAndDropImage')
                                                                                                            }}
                                                                                                            <span
                                                                                                                class="select"
                                                                                                                role="button"
                                                                                                                @click="selectFiles('malaysiaGallery')">
                                                                                                                {{
                                                                                                                    $t('propertySubmit.choose')
                                                                                                                }}
                                                                                                            </span>
                                                                                                        </span>
                                                                                                        <div v-else
                                                                                                            class="select">
                                                                                                            {{
                                                                                                                $t('propertySubmit.dropImagesHere')
                                                                                                            }}
                                                                                                        </div>
                                                                                                        <input
                                                                                                            name="file"
                                                                                                            type="file"
                                                                                                            class="file"
                                                                                                            ref="malaysiaFileInput"
                                                                                                            multiple
                                                                                                            @change="onFileSelect($event, 'malaysiaGallery')" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </form>
                                                                                            <!-- Image Preview Section for Greece -->
                                                                                            <div
                                                                                                class="image-preview-container">
                                                                                                <div class="image-preview"
                                                                                                    v-for="(image, index) in malaysiaGallery"
                                                                                                    :key="index">
                                                                                                    <span class="delete"
                                                                                                        @click="deleteImage(index, 'malaysiaGallery')">&times;</span>
                                                                                                    <img :src="image.url"
                                                                                                        alt="Image preview"
                                                                                                        class="image-preview-img" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Property Video -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>{{
                                                                                    $t('propertySubmit.propertyVideo')
                                                                                }}</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.youtubeEmbedLink')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Location -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>{{
                                                                                    $t('propertySubmit.locationInMalaysia')
                                                                                }}</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.address')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="address">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.city')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="city">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.state')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="state">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.zipCode')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="zipCode">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Malaysia Property Detailed Information -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>{{
                                                                                    $t('propertySubmit.malaysiaPropertyDetailedInformation')
                                                                                }}</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.description')
                                                                                            }}</label>
                                                                                            <textarea
                                                                                                class="form-control h-120"
                                                                                                v-model="description"></textarea>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.buildingAgeOptional')
                                                                                            }}</label>
                                                                                            <select id="building_age"
                                                                                                class="form-control"
                                                                                                v-model="buildingAge">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">0 - 5
                                                                                                    {{
                                                                                                        $t('propertySubmit.years')
                                                                                                    }}</option>
                                                                                                <option value="2">0 - 10
                                                                                                    {{
                                                                                                        $t('propertySubmit.years')
                                                                                                    }}</option>
                                                                                                <option value="3">0 - 15
                                                                                                    {{
                                                                                                        $t('propertySubmit.years')
                                                                                                    }}</option>
                                                                                                <option value="4">0 - 20
                                                                                                    {{
                                                                                                        $t('propertySubmit.years')
                                                                                                    }}</option>
                                                                                                <option value="5">20+
                                                                                                    {{
                                                                                                        $t('propertySubmit.years')
                                                                                                    }}</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.conditionOptional')
                                                                                            }}</label>
                                                                                            <select
                                                                                                id="property_condition"
                                                                                                class="form-control"
                                                                                                v-model="condition">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">{{
                                                                                                    $t('propertySubmit.likeNew')
                                                                                                }}</option>
                                                                                                <option value="2">
                                                                                                    {{
                                                                                                        $t('propertySubmit.renovated')
                                                                                                    }}</option>
                                                                                                <option value="3">{{
                                                                                                    $t('propertySubmit.needRenovation')
                                                                                                }}</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.landTenure')
                                                                                            }}</label>
                                                                                            <select id="land_tenure"
                                                                                                class="form-control"
                                                                                                v-model="landTenure">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">
                                                                                                    {{
                                                                                                        $t('propertySubmit.freehold')
                                                                                                    }}</option>
                                                                                                <option value="2">
                                                                                                    {{
                                                                                                        $t('propertySubmit.leasehold')
                                                                                                    }}</option>
                                                                                                <option value="3">
                                                                                                    {{
                                                                                                        $t('propertySubmit.ninetyNineYearsLeasehold')
                                                                                                    }}
                                                                                                </option>
                                                                                                <option value="4">
                                                                                                    {{
                                                                                                        $t('propertySubmit.nineHundredNinetyNineYearsLeasehold')
                                                                                                    }}
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.nineHundredNinetyNineYearsLeasehold')
                                                                                            }}</label>
                                                                                            <select id="land_title"
                                                                                                class="form-control"
                                                                                                v-model="landTitle">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">
                                                                                                    {{
                                                                                                        $t('propertySubmit.commercialStrata')
                                                                                                    }}
                                                                                                </option>
                                                                                                <option value="2">
                                                                                                    {{
                                                                                                        $t('propertySubmit.residentialStrata')
                                                                                                    }}
                                                                                                </option>
                                                                                                <option value="3">
                                                                                                    {{
                                                                                                        $t('propertySubmit.individualTitle')
                                                                                                    }}
                                                                                                </option>
                                                                                                <option value="4">{{
                                                                                                    $t('propertySubmit.masterTitle')
                                                                                                }}</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.landSizeOptional')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="acres"
                                                                                                v-model="landSize">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.totalUnitsOptional')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="totalUnits">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.totalCarParkOptional')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="totalCarPark">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.totalTowersOptional')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="totalTowers">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.totalFloorsOptional')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="totalFloors">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.startingUnitPriceOptional')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="RM"
                                                                                                v-model="startingUnitPrice">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.highestUnitPriceOptional')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="RM"
                                                                                                v-model="highestUnitPrice">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.rooms')
                                                                                            }}</label>
                                                                                            <select id="rooms"
                                                                                                class="form-control"
                                                                                                v-model="rooms">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.carParkOptional')
                                                                                            }}</label>
                                                                                            <select id="car_park"
                                                                                                class="form-control"
                                                                                                v-model="carPark">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.furnishingOptional')
                                                                                            }}</label>
                                                                                            <select id="furnishing"
                                                                                                class="form-control"
                                                                                                v-model="furnishing">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">{{
                                                                                                    $t('propertySubmit.fullFurnishedDuplicate')
                                                                                                }}</option>
                                                                                                <option value="2">
                                                                                                    {{
                                                                                                        $t('propertySubmit.partialFurnishedDuplicate')
                                                                                                    }}
                                                                                                </option>
                                                                                                <option value="3">{{
                                                                                                    $t('propertySubmit.notFurnished')
                                                                                                }}</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.completionYearOptional')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="completionYear">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.internalFeaturesOptional')
                                                                                            }}</label>
                                                                                            <div class="o-features">
                                                                                                <ul
                                                                                                    class="no-ul-list third-row">
                                                                                                    <li>
                                                                                                        <input id="a-1"
                                                                                                            class="form-check-input"
                                                                                                            name="a-1"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.airConditioning">
                                                                                                        <label for="a-1"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.airConditioningDuplicate')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-2"
                                                                                                            class="form-check-input"
                                                                                                            name="a-2"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.fullyFittedKitchen">
                                                                                                        <label for="a-2"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.fullyFittedKitchen')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-3"
                                                                                                            class="form-check-input"
                                                                                                            name="a-3"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.waterHeaters">
                                                                                                        <label for="a-3"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.waterHeaters')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-4"
                                                                                                            class="form-check-input"
                                                                                                            name="a-4"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.builtInWardrobes">
                                                                                                        <label for="a-4"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.builtInWardrobes')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-5"
                                                                                                            class="form-check-input"
                                                                                                            name="a-5"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.balcony">
                                                                                                        <label for="a-5"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.balconyDuplicate2')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-6"
                                                                                                            class="form-check-input"
                                                                                                            name="a-6"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.ensuiteBathrooms">
                                                                                                        <label for="a-6"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.enSuiteBathrooms')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-7"
                                                                                                            class="form-check-input"
                                                                                                            name="a-7"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.highQualityFlooring">
                                                                                                        <label for="a-7"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.highQualityFlooring')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-8"
                                                                                                            class="form-check-input"
                                                                                                            name="a-8"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.openPlanLayout">
                                                                                                        <label for="a-8"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.openPlanLayout')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-9"
                                                                                                            class="form-check-input"
                                                                                                            name="a-9"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.highCeilings">
                                                                                                        <label for="a-9"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.highCeilings')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-10"
                                                                                                            class="form-check-input"
                                                                                                            name="a-10"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.smartHomeFeatures">
                                                                                                        <label
                                                                                                            for="a-10"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.smartHomeFeatures')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-11"
                                                                                                            class="form-check-input"
                                                                                                            name="a-11"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.utilityStorageRoom">
                                                                                                        <label
                                                                                                            for="a-11"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.utilityStorageRoom')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-12"
                                                                                                            class="form-check-input"
                                                                                                            name="a-12"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.privateLiftLobby">
                                                                                                        <label
                                                                                                            for="a-12"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.privateLiftLobby')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-13"
                                                                                                            class="form-check-input"
                                                                                                            name="a-13"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.homeOfficeSpace">
                                                                                                        <label
                                                                                                            for="a-13"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.homeOfficeSpace')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-14"
                                                                                                            class="form-check-input"
                                                                                                            name="a-14"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.dualKeyAccess">
                                                                                                        <label
                                                                                                            for="a-14"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.dualKeyAccess')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-15"
                                                                                                            class="form-check-input"
                                                                                                            name="a-15"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.pantryArea">
                                                                                                        <label
                                                                                                            for="a-15"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.pantryArea')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-16"
                                                                                                            class="form-check-input"
                                                                                                            name="a-16"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.laundryArea">
                                                                                                        <label
                                                                                                            for="a-16"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.laundryArea')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-17"
                                                                                                            class="form-check-input"
                                                                                                            name="a-17"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.highQualityFinishes">
                                                                                                        <label
                                                                                                            for="a-17"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.highQualityFinishes')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-18"
                                                                                                            class="form-check-input"
                                                                                                            name="a-18"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.largeWindowsNaturalLight">
                                                                                                        <label
                                                                                                            for="a-18"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.largeWindowsNaturalLight')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-19"
                                                                                                            class="form-check-input"
                                                                                                            name="a-19"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.soundproofing">
                                                                                                        <label
                                                                                                            for="a-19"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.soundproofing')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-20"
                                                                                                            class="form-check-input"
                                                                                                            name="a-20"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.walkInCloset">
                                                                                                        <label
                                                                                                            for="a-20"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.walkInCloset')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-21"
                                                                                                            class="form-check-input"
                                                                                                            name="a-21"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.guestPowderRoom">
                                                                                                        <label
                                                                                                            for="a-21"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.guestPowderRoom')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-22"
                                                                                                            class="form-check-input"
                                                                                                            name="a-22"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.smartWiring">
                                                                                                        <label
                                                                                                            for="a-22"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.smartWiring')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-23"
                                                                                                            class="form-check-input"
                                                                                                            name="a-23"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.privateGarden">
                                                                                                        <label
                                                                                                            for="a-23"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.privateGardenDuplicate')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-24"
                                                                                                            class="form-check-input"
                                                                                                            name="a-24"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.emergencyEscapePlan">
                                                                                                        <label
                                                                                                            for="a-24"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.emergencyEscapePlan')
                                                                                                            }}</label>
                                                                                                    </li>

                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.facilitiesOptional')
                                                                                            }}</label>
                                                                                            <div class="o-features">
                                                                                                <ul
                                                                                                    class="no-ul-list third-row">
                                                                                                    <li>
                                                                                                        <input id="f-1"
                                                                                                            class="form-check-input"
                                                                                                            name="f-1"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.swimmingPool">
                                                                                                        <label for="f-1"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.swimmingPoolDuplicate')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-2"
                                                                                                            class="form-check-input"
                                                                                                            name="f-2"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.gymnasium">
                                                                                                        <label for="f-2"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.gymnasium')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-3"
                                                                                                            class="form-check-input"
                                                                                                            name="f-3"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.security">
                                                                                                        <label for="f-3"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.twentyFourHourSecurity')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-4"
                                                                                                            class="form-check-input"
                                                                                                            name="f-4"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.parkingSpaces">
                                                                                                        <label for="f-4"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.parkingSpaces')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-5"
                                                                                                            class="form-check-input"
                                                                                                            name="f-5"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.childrensPlayground">
                                                                                                        <label for="f-5"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.childrensPlayground')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-6"
                                                                                                            class="form-check-input"
                                                                                                            name="f-6"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.multipurposeHall">
                                                                                                        <label for="f-6"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.multipurposeHall')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-7"
                                                                                                            class="form-check-input"
                                                                                                            name="f-7"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.bbqArea">
                                                                                                        <label for="f-7"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.bbqArea')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-8"
                                                                                                            class="form-check-input"
                                                                                                            name="f-8"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.joggingTrack">
                                                                                                        <label for="f-8"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.joggingTrack')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-9"
                                                                                                            class="form-check-input"
                                                                                                            name="f-9"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.wadingPool">
                                                                                                        <label for="f-9"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.wadingPool')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-10"
                                                                                                            class="form-check-input"
                                                                                                            name="f-10"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.saunaSteamRoom">
                                                                                                        <label
                                                                                                            for="f-10"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.saunaSteamRoom')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-11"
                                                                                                            class="form-check-input"
                                                                                                            name="f-11"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.readingStudyRoom">
                                                                                                        <label
                                                                                                            for="f-11"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.readingStudyRoom')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-12"
                                                                                                            class="form-check-input"
                                                                                                            name="f-12"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.clubhouse">
                                                                                                        <label
                                                                                                            for="f-12"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.clubhouse')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-13"
                                                                                                            class="form-check-input"
                                                                                                            name="f-13"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.managementOffice">
                                                                                                        <label
                                                                                                            for="f-13"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.managementOffice')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-14"
                                                                                                            class="form-check-input"
                                                                                                            name="f-14"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.gardenOrGreenSpaces">
                                                                                                        <label
                                                                                                            for="f-14"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.gardenOrGreenSpaces')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-15"
                                                                                                            class="form-check-input"
                                                                                                            name="f-15"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.functionRoom">
                                                                                                        <label
                                                                                                            for="f-15"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.functionRoom')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-16"
                                                                                                            class="form-check-input"
                                                                                                            name="f-16"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.tennisCourt">
                                                                                                        <label
                                                                                                            for="f-16"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.tennisCourt')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-17"
                                                                                                            class="form-check-input"
                                                                                                            name="f-17"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.badmintonCourt">
                                                                                                        <label
                                                                                                            for="f-17"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.badmintonCourt')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-18"
                                                                                                            class="form-check-input"
                                                                                                            name="f-18"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.surau">
                                                                                                        <label
                                                                                                            for="f-18"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.surau')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-19"
                                                                                                            class="form-check-input"
                                                                                                            name="f-19"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.coveredCarPark">
                                                                                                        <label
                                                                                                            for="f-19"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.coveredCarPark')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-20"
                                                                                                            class="form-check-input"
                                                                                                            name="f-20"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.liftLobby">
                                                                                                        <label
                                                                                                            for="f-20"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.liftLobby')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-21"
                                                                                                            class="form-check-input"
                                                                                                            name="f-21"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.airbnbManagement">
                                                                                                        <label
                                                                                                            for="f-21"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.airbnbManagement')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.suitableForOptional')
                                                                                            }}</label>
                                                                                            <div class="o-features">
                                                                                                <ul
                                                                                                    class="no-ul-list third-row">
                                                                                                    <li>
                                                                                                        <input id="a-1"
                                                                                                            class="form-check-input"
                                                                                                            name="a-1"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.student">
                                                                                                        <label for="a-1"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.student')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-2"
                                                                                                            class="form-check-input"
                                                                                                            name="a-2"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.holidayHome">
                                                                                                        <label for="a-2"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.holidayHome')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-3"
                                                                                                            class="form-check-input"
                                                                                                            name="a-3"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.investment">
                                                                                                        <label for="a-3"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.investment')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-4"
                                                                                                            class="form-check-input"
                                                                                                            name="a-4"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.shortTermRental">
                                                                                                        <label for="a-4"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.shortTermRental')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-5"
                                                                                                            class="form-check-input"
                                                                                                            name="a-5"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.commercialUse">
                                                                                                        <label for="a-5"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.commercialUse')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-6"
                                                                                                            class="form-check-input"
                                                                                                            name="a-6"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.medicalOffice">
                                                                                                        <label for="a-6"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.medicalOffice')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Contact Information -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>{{
                                                                                    $t('propertySubmit.contactInformation')
                                                                                }}</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.name')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="contactName">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.email')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="contactEmail">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.phone')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="contactPhone">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="form-group">
                                                                                <div class="col-lg-12 col-md-12">
                                                                                    <button @click="malaysiaSubmitForm"
                                                                                        class="btn btn-danger"
                                                                                        type="submit">{{
                                                                                            $t('propertySubmit.submit')
                                                                                        }}</button>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </section>
                                                        <!-- ============================ Submit Property End ================================== -->
                                                    </div>

                                                    <!-- Singapore Tab -->
                                                    <div class="tab-pane fade" id="singapore" role="tabpanel"
                                                        aria-labelledby="Singapore-tab">
                                                        <!-- ============================ Submit Property Start ================================== -->
                                                        <section>
                                                            <div class="container">
                                                                <div class="row">
                                                                    <div class="col-lg-12 col-md-12">

                                                                        <div class="alert alert-danger" role="alert">
                                                                            <p class="m-0">{{
                                                                                $t('propertySubmit.propertyCategoryText')
                                                                            }} <a href="#">{{
                                                                                    $t('propertySubmit.clickHere') }}
                                                                                </a>
                                                                            </p>
                                                                        </div>

                                                                    </div>

                                                                    <!-- Submit Form -->
                                                                    <div class="col-lg-12 col-md-12">

                                                                        <div class="submit-page p-0">

                                                                            <!-- Singapore Basic Information -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>{{
                                                                                    $t('propertySubmit.singaporePropertyBasicInformation')
                                                                                }}
                                                                                </h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">
                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.propertyTitle')
                                                                                            }}<a href="#"
                                                                                                    class="tip-topdata"
                                                                                                    data-tip="Property Title"><i
                                                                                                        class="ti-help"></i></a></label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="propertyTitle">
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.propertyStatus')
                                                                                            }}</label>
                                                                                            <select id="status"
                                                                                                class="form-control"
                                                                                                v-model="status">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">{{
                                                                                                    $t('propertySubmit.forRent')
                                                                                                }}</option>
                                                                                                <option value="2">{{
                                                                                                    $t('propertySubmit.forSale')
                                                                                                }}</option>
                                                                                            </select>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.propertyType')
                                                                                            }}</label>
                                                                                            <select id="ptypes"
                                                                                                class="form-control"
                                                                                                v-model="propertyType">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">
                                                                                                    {{
                                                                                                        $t('propertySubmit.condominium')
                                                                                                    }}</option>
                                                                                                <option value="2">{{
                                                                                                    $t('propertySubmit.apartment')
                                                                                                }}</option>
                                                                                                <option value="3">{{
                                                                                                    $t('propertySubmit.landedHouse')
                                                                                                }}</option>
                                                                                                <option value="4">{{
                                                                                                    $t('propertySubmit.commercial')
                                                                                                }}</option>
                                                                                                <option value="5">{{
                                                                                                    $t('propertySubmit.offices')
                                                                                                }}</option>
                                                                                                <option value="6">{{
                                                                                                    $t('propertySubmit.land')
                                                                                                }}
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.land')
                                                                                            }}</label>
                                                                                            <select id="ptypes"
                                                                                                class="form-control"
                                                                                                v-model="propertyCategory">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">{{
                                                                                                    $t('propertySubmit.new')
                                                                                                }}
                                                                                                </option>
                                                                                                <option value="2">
                                                                                                    {{
                                                                                                        $t('propertySubmit.secondHand')
                                                                                                    }}</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.surfaceArea')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="ft²"
                                                                                                v-model="surfaceArea">
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.nearestMRT')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="nearestMRT">
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.price')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="SGD"
                                                                                                v-model="price">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.pricePerFt2')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="SGD"
                                                                                                v-model="pricePerSqft">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.bedrooms')
                                                                                            }}</label>
                                                                                            <select id="bedrooms"
                                                                                                class="form-control"
                                                                                                v-model="bedrooms">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.bathrooms')
                                                                                            }}</label>
                                                                                            <select id="bathrooms"
                                                                                                class="form-control"
                                                                                                v-model="bathrooms">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Singapore Gallery -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>{{ $t('propertySubmit.gallery') }}
                                                                                </h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row">
                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.uploadGallery')
                                                                                            }}</label>
                                                                                            <form action="#"
                                                                                                class="dropzone dz-clickable primary-dropzone">
                                                                                                <div class="card">
                                                                                                    <div class="drag-area"
                                                                                                        @dragover.prevent="onDragOver"
                                                                                                        @dragleave.prevent="onDragLeave"
                                                                                                        @drop.prevent="onDrop('singaporeGallery')">
                                                                                                        <span
                                                                                                            v-if="!isDragging">
                                                                                                            {{
                                                                                                                $t('propertySubmit.dragAndDropImage')
                                                                                                            }}
                                                                                                            <span
                                                                                                                class="select"
                                                                                                                role="button"
                                                                                                                @click="selectFiles('singaporeGallery')">
                                                                                                                {{
                                                                                                                    $t('propertySubmit.choose')
                                                                                                                }}
                                                                                                            </span>
                                                                                                        </span>
                                                                                                        <div v-else
                                                                                                            class="select">
                                                                                                            {{
                                                                                                                $t('propertySubmit.dropImagesHere')
                                                                                                            }}
                                                                                                        </div>
                                                                                                        <input
                                                                                                            name="file"
                                                                                                            type="file"
                                                                                                            class="file"
                                                                                                            ref="singaporeFileInput"
                                                                                                            multiple
                                                                                                            @change="onFileSelect($event, 'singaporeGallery')" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </form>
                                                                                            <!-- Image Preview Section for Greece -->
                                                                                            <div
                                                                                                class="image-preview-container">
                                                                                                <div class="image-preview"
                                                                                                    v-for="(image, index) in singaporeGallery"
                                                                                                    :key="index">
                                                                                                    <span class="delete"
                                                                                                        @click="deleteImage(index, 'singaporeGallery')">&times;</span>
                                                                                                    <img :src="image.url"
                                                                                                        alt="Image preview"
                                                                                                        class="image-preview-img" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                            <!-- Property Video -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>{{
                                                                                    $t('propertySubmit.propertyVideo')
                                                                                }}</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.youtubeEmbedLink')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Location -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>{{
                                                                                    $t('propertySubmit.locationInSingapore')
                                                                                }}</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.address')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="address">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.neighbourhood')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="neighbourhood">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.district')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="district">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.zipCode')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="zipCode">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Singapore Property Detailed Information -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>{{
                                                                                    $t('propertySubmit.singaporePropertyDetailedInformation')
                                                                                }}</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.description')
                                                                                            }}</label>
                                                                                            <textarea
                                                                                                class="form-control h-120"
                                                                                                v-model="description"></textarea>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.buildingAgeOptional')
                                                                                            }}</label>
                                                                                            <select id="bage"
                                                                                                class="form-control"
                                                                                                v-model="buildingAge">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">0 - 5
                                                                                                    {{
                                                                                                        $t('propertySubmit.years')
                                                                                                    }}</option>
                                                                                                <option value="2">0 - 10
                                                                                                    {{
                                                                                                        $t('propertySubmit.years')
                                                                                                    }}</option>
                                                                                                <option value="3">0 - 15
                                                                                                    {{
                                                                                                        $t('propertySubmit.years')
                                                                                                    }}</option>
                                                                                                <option value="4">0 - 20
                                                                                                    {{
                                                                                                        $t('propertySubmit.years')
                                                                                                    }}</option>
                                                                                                <option value="5">20+
                                                                                                    {{
                                                                                                        $t('propertySubmit.years')
                                                                                                    }}</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.conditionOptional')
                                                                                            }}</label>
                                                                                            <select id="bage"
                                                                                                class="form-control"
                                                                                                v-model="condition">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">{{
                                                                                                    $t('propertySubmit.likeNew')
                                                                                                }}</option>
                                                                                                <option value="2">
                                                                                                    {{
                                                                                                        $t('propertySubmit.renovated')
                                                                                                    }}</option>
                                                                                                <option value="3">{{
                                                                                                    $t('propertySubmit.needRenovation')
                                                                                                }}</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.landTenure')
                                                                                            }}</label>
                                                                                            <select id="garage"
                                                                                                class="form-control"
                                                                                                v-model="landTenure">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">
                                                                                                    {{
                                                                                                        $t('propertySubmit.freehold')
                                                                                                    }}</option>
                                                                                                <option value="2">
                                                                                                    {{
                                                                                                        $t('propertySubmit.leasehold')
                                                                                                    }}</option>
                                                                                                <option value="3">
                                                                                                    {{
                                                                                                        $t('propertySubmit.ninetyNineYearsLeasehold')
                                                                                                    }}
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.typeOfProperty')
                                                                                            }}</label>
                                                                                            <select id="garage"
                                                                                                class="form-control"
                                                                                                v-model="typeOfProperty">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">{{
                                                                                                    $t('propertySubmit.publicHousingHDBFlats')
                                                                                                }}
                                                                                                </option>
                                                                                                <option value="2">
                                                                                                    {{
                                                                                                        $t('propertySubmit.privateResidentialProperties')
                                                                                                    }}</option>
                                                                                                <option value="3">
                                                                                                    {{
                                                                                                        $t('propertySubmit.executiveCondominiums')
                                                                                                    }}
                                                                                                </option>
                                                                                                <option value="4">
                                                                                                    {{
                                                                                                        $t('propertySubmit.commercialProperties')
                                                                                                    }}</option>
                                                                                                <option value="5">
                                                                                                    {{
                                                                                                        $t('propertySubmit.industrialProperties')
                                                                                                    }}</option>
                                                                                                <option value="6">
                                                                                                    {{
                                                                                                        $t('propertySubmit.mixedUseDevelopments')
                                                                                                    }}
                                                                                                </option>
                                                                                                <option value="7">
                                                                                                    {{
                                                                                                        $t('propertySubmit.servicedApartments')
                                                                                                    }}
                                                                                                </option>
                                                                                                <option value="8">{{
                                                                                                    $t('propertySubmit.hotels')
                                                                                                }}
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.landSizeOptional')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="acres"
                                                                                                v-model="landSize">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.totalUnitsOptional')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="totalUnits">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.totalCarParkOptional')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="totalCarPark">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.totalTowersOptional')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="totalTowers">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.totalFloorsOptional')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="totalFloors">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.startingUnitPriceOptional')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="SGD"
                                                                                                v-model="startingUnitPrice">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.highestUnitPriceOptional')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="SGD"
                                                                                                v-model="highestUnitPrice">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.rooms')
                                                                                            }}</label>
                                                                                            <select id="rooms"
                                                                                                class="form-control"
                                                                                                v-model="rooms">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.carParkOptional')
                                                                                            }}</label>
                                                                                            <select id="rooms"
                                                                                                class="form-control"
                                                                                                v-model="carPark">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.furnishingOptional')
                                                                                            }}</label>
                                                                                            <select id="bage"
                                                                                                class="form-control"
                                                                                                v-model="furnishing">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">{{
                                                                                                    $t('propertySubmit.fullFurnishedDuplicate')
                                                                                                }}</option>
                                                                                                <option value="2">
                                                                                                    {{
                                                                                                        $t('propertySubmit.partialFurnishedDuplicate')
                                                                                                    }}
                                                                                                </option>
                                                                                                <option value="3">{{
                                                                                                    $t('propertySubmit.notFurnished')
                                                                                                }}</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.completionYearOptional')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="completionYear">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.internalFeaturesOptional')
                                                                                            }}</label>
                                                                                            <div class="o-features">
                                                                                                <ul
                                                                                                    class="no-ul-list third-row">
                                                                                                    <li>
                                                                                                        <input id="a-1"
                                                                                                            class="form-check-input"
                                                                                                            name="a-1"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.airConditioning">
                                                                                                        <label for="a-1"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.airConditioningDuplicate')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-2"
                                                                                                            class="form-check-input"
                                                                                                            name="a-2"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.fullyFittedKitchen">
                                                                                                        <label for="a-2"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.fullyFittedKitchen')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-3"
                                                                                                            class="form-check-input"
                                                                                                            name="a-3"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.waterHeaters">
                                                                                                        <label for="a-3"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.waterHeaters')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-4"
                                                                                                            class="form-check-input"
                                                                                                            name="a-4"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.builtInWardrobes">
                                                                                                        <label for="a-4"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.builtInWardrobes')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-5"
                                                                                                            class="form-check-input"
                                                                                                            name="a-5"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.balcony">
                                                                                                        <label for="a-5"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.balconyDuplicate2')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-6"
                                                                                                            class="form-check-input"
                                                                                                            name="a-6"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.ensuiteBathrooms">
                                                                                                        <label for="a-6"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.enSuiteBathrooms')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-7"
                                                                                                            class="form-check-input"
                                                                                                            name="a-7"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.highQualityFlooring">
                                                                                                        <label for="a-7"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.highQualityFlooring')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-8"
                                                                                                            class="form-check-input"
                                                                                                            name="a-8"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.openPlanLayout">
                                                                                                        <label for="a-8"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.openPlanLayout')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-9"
                                                                                                            class="form-check-input"
                                                                                                            name="a-9"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.highCeilings">
                                                                                                        <label for="a-9"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.highCeilings')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-10"
                                                                                                            class="form-check-input"
                                                                                                            name="a-10"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.smartHomeFeatures">
                                                                                                        <label
                                                                                                            for="a-10"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.smartHomeFeatures')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-11"
                                                                                                            class="form-check-input"
                                                                                                            name="a-11"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.utilityStorageRoom">
                                                                                                        <label
                                                                                                            for="a-11"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.utilityStorageRoom')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-12"
                                                                                                            class="form-check-input"
                                                                                                            name="a-12"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.privateLiftLobby">
                                                                                                        <label
                                                                                                            for="a-12"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.privateLiftLobby')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-13"
                                                                                                            class="form-check-input"
                                                                                                            name="a-13"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.homeOfficeSpace">
                                                                                                        <label
                                                                                                            for="a-13"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.homeOfficeSpace')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-14"
                                                                                                            class="form-check-input"
                                                                                                            name="a-14"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.dualKeyAccess">
                                                                                                        <label
                                                                                                            for="a-14"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.dualKeyAccess')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-15"
                                                                                                            class="form-check-input"
                                                                                                            name="a-15"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.pantryArea">
                                                                                                        <label
                                                                                                            for="a-15"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.pantryArea')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-16"
                                                                                                            class="form-check-input"
                                                                                                            name="a-16"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.laundryArea">
                                                                                                        <label
                                                                                                            for="a-16"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.laundryArea')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-17"
                                                                                                            class="form-check-input"
                                                                                                            name="a-17"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.highQualityFinishes">
                                                                                                        <label
                                                                                                            for="a-17"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.highQualityFinishes')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-18"
                                                                                                            class="form-check-input"
                                                                                                            name="a-18"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.largeWindowsNaturalLight">
                                                                                                        <label
                                                                                                            for="a-18"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.largeWindowsNaturalLight')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-19"
                                                                                                            class="form-check-input"
                                                                                                            name="a-19"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.soundproofing">
                                                                                                        <label
                                                                                                            for="a-19"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.soundproofing')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-20"
                                                                                                            class="form-check-input"
                                                                                                            name="a-20"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.walkInCloset">
                                                                                                        <label
                                                                                                            for="a-20"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.walkInCloset')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-21"
                                                                                                            class="form-check-input"
                                                                                                            name="a-21"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.guestPowderRoom">
                                                                                                        <label
                                                                                                            for="a-21"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.guestPowderRoom')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-22"
                                                                                                            class="form-check-input"
                                                                                                            name="a-22"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.smartWiring">
                                                                                                        <label
                                                                                                            for="a-22"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.smartWiring')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-23"
                                                                                                            class="form-check-input"
                                                                                                            name="a-23"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.privateGarden">
                                                                                                        <label
                                                                                                            for="a-23"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.privateGardenDuplicate')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-24"
                                                                                                            class="form-check-input"
                                                                                                            name="a-24"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.emergencyEscapePlan">
                                                                                                        <label
                                                                                                            for="a-24"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.emergencyEscapePlan')
                                                                                                            }}</label>
                                                                                                    </li>

                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.facilitiesOptional')
                                                                                            }}</label>
                                                                                            <div class="o-features">
                                                                                                <ul
                                                                                                    class="no-ul-list third-row">
                                                                                                    <li>
                                                                                                        <input id="f-1"
                                                                                                            class="form-check-input"
                                                                                                            name="f-1"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.swimmingPool">
                                                                                                        <label for="f-1"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.swimmingPoolDuplicate')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-2"
                                                                                                            class="form-check-input"
                                                                                                            name="f-2"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.gymnasium">
                                                                                                        <label for="f-2"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.gymnasium')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-3"
                                                                                                            class="form-check-input"
                                                                                                            name="f-3"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.security">
                                                                                                        <label for="f-3"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.twentyFourHourSecurity')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-4"
                                                                                                            class="form-check-input"
                                                                                                            name="f-4"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.parkingSpaces">
                                                                                                        <label for="f-4"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.parkingSpaces')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-5"
                                                                                                            class="form-check-input"
                                                                                                            name="f-5"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.childrensPlayground">
                                                                                                        <label for="f-5"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.childrensPlayground')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-6"
                                                                                                            class="form-check-input"
                                                                                                            name="f-6"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.multipurposeHall">
                                                                                                        <label for="f-6"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.multipurposeHall')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-7"
                                                                                                            class="form-check-input"
                                                                                                            name="f-7"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.bbqArea">
                                                                                                        <label for="f-7"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.bbqArea')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-8"
                                                                                                            class="form-check-input"
                                                                                                            name="f-8"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.joggingTrack">
                                                                                                        <label for="f-8"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.joggingTrack')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-9"
                                                                                                            class="form-check-input"
                                                                                                            name="f-9"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.wadingPool">
                                                                                                        <label for="f-9"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.wadingPool')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-10"
                                                                                                            class="form-check-input"
                                                                                                            name="f-10"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.saunaSteamRoom">
                                                                                                        <label
                                                                                                            for="f-10"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.saunaSteamRoom')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-11"
                                                                                                            class="form-check-input"
                                                                                                            name="f-11"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.readingStudyRoom">
                                                                                                        <label
                                                                                                            for="f-11"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.readingStudyRoom')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-12"
                                                                                                            class="form-check-input"
                                                                                                            name="f-12"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.clubhouse">
                                                                                                        <label
                                                                                                            for="f-12"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.clubhouse')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-13"
                                                                                                            class="form-check-input"
                                                                                                            name="f-13"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.managementOffice">
                                                                                                        <label
                                                                                                            for="f-13"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.managementOffice')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-14"
                                                                                                            class="form-check-input"
                                                                                                            name="f-14"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.gardenOrGreenSpaces">
                                                                                                        <label
                                                                                                            for="f-14"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.gardenOrGreenSpaces')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-15"
                                                                                                            class="form-check-input"
                                                                                                            name="f-15"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.functionRoom">
                                                                                                        <label
                                                                                                            for="f-15"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.functionRoom')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-16"
                                                                                                            class="form-check-input"
                                                                                                            name="f-16"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.tennisCourt">
                                                                                                        <label
                                                                                                            for="f-16"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.tennisCourt')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-17"
                                                                                                            class="form-check-input"
                                                                                                            name="f-17"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.badmintonCourt">
                                                                                                        <label
                                                                                                            for="f-17"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.badmintonCourt')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-18"
                                                                                                            class="form-check-input"
                                                                                                            name="f-18"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.surau">
                                                                                                        <label
                                                                                                            for="f-18"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.surau')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-19"
                                                                                                            class="form-check-input"
                                                                                                            name="f-19"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.coveredCarPark">
                                                                                                        <label
                                                                                                            for="f-19"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.coveredCarPark')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-20"
                                                                                                            class="form-check-input"
                                                                                                            name="f-20"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.liftLobby">
                                                                                                        <label
                                                                                                            for="f-20"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.liftLobby')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-21"
                                                                                                            class="form-check-input"
                                                                                                            name="f-21"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.airbnbManagement">
                                                                                                        <label
                                                                                                            for="f-21"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.airbnbManagement')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.suitableForOptional')
                                                                                            }}</label>
                                                                                            <div class="o-features">
                                                                                                <ul
                                                                                                    class="no-ul-list third-row">
                                                                                                    <li>
                                                                                                        <input id="a-1"
                                                                                                            class="form-check-input"
                                                                                                            name="a-1"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.student">
                                                                                                        <label for="a-1"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.student')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-2"
                                                                                                            class="form-check-input"
                                                                                                            name="a-2"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.holidayHome">
                                                                                                        <label for="a-2"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.holidayHome')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-3"
                                                                                                            class="form-check-input"
                                                                                                            name="a-3"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.investment">
                                                                                                        <label for="a-3"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.investment')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-4"
                                                                                                            class="form-check-input"
                                                                                                            name="a-4"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.shortTermRental">
                                                                                                        <label for="a-4"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.shortTermRental')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-5"
                                                                                                            class="form-check-input"
                                                                                                            name="a-5"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.commercialUse">
                                                                                                        <label for="a-5"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.commercialUse')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-6"
                                                                                                            class="form-check-input"
                                                                                                            name="a-6"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.medicalOffice">
                                                                                                        <label for="a-6"
                                                                                                            class="form-check-label">{{
                                                                                                                $t('propertySubmit.medicalOffice')
                                                                                                            }}</label>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Contact Information -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>{{
                                                                                    $t('propertySubmit.contactInformation')
                                                                                }}</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.name')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="contactName">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.email')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="contactEmail">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>{{
                                                                                                $t('propertySubmit.phone')
                                                                                            }}</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="contactPhone">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="form-group">
                                                                                <div class="col-lg-12 col-md-12">
                                                                                    <button @click="singaporeSubmitForm"
                                                                                        class="btn btn-danger"
                                                                                        type="submit">{{
                                                                                            $t('propertySubmit.submit')
                                                                                        }}</button>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <!-- ============================ Submit Property End ================================== -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /row -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- ================= Our Mission ================= -->

            <!-- ============================ Call To Action ================================== -->
            <PageFooter />
            <!-- ============================ Call To Action End ================================== -->
            <!-- ============================ Footer Start ================================== -->
            <!-- ============================ Footer End ================================== -->
        </div>
    </div>
</template>

<script>
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db, storage } from '../../firebase'; // Import the auth object
import { defineComponent } from 'vue';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

import 'magnific-popup';

import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';

import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import sampleImage1 from '@/assets/img/p-4.webp';
import oldIcon from '@/assets/img/old.png';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';
import bgImage from '@/assets/img/bg.jpg';

export default defineComponent({
    name: 'PropertySubmission',
    components: {
        Navigation,
        PageFooter,
    },
    props: ['glb_id'],
    data() {
        return {
            bgImage,
            addProperty,
            menuWidget,
            profileLogo,
            globalHomeLogo,
            whatsappLogo,
            wechatLogo,
            loginLogo,
            settingsLogo,
            qrCodeLogo,
            sampleImage1,
            oldIcon,
            navMenuVisible: false,
            activeSubmenu: null,
            userLoggedIn: false,
            loginError: '',
            propertyTitle: '',
            propertyStatus: '',
            propertyType: '',
            propertyCategory: '',
            surfaceArea: '',
            plotSurfaceArea: '',
            price: '',
            pricePerSqM: '',
            bedrooms: '',
            bathrooms: '',
            greeceGallery: [],
            singaporeGallery: [],
            malaysiaGallery: [],
            address: '',
            city: '',
            state: '',
            zipCode: '',
            description: '',
            buildingAge: '',
            garage: '',
            rooms: '',
            heatingSystem: '',
            energyClass: '',
            completionYear: '',
            internalFeatures: {
                fullFurnished: false,
                partialFurnished: false,
                noFurnished: false,
                elevator: false,
                internalStaircase: false,
                nightPower: false,
                secureDoor: false,
                doubleGlass: false,
                painted: false,
                wifi: false,
                fireplace: false,
                woodFloor: false,
                alarm: false,
                windowScreens: false,
                airConditioning: false,
                petsWelcome: false,
                aluminumFrames: false,
                mannedReception: false,
                storageSpace: false,
                marbleFloor: false,
                airy: false,
                underfloorHeating: false,
                solarWaterHeating: false,
                attic: false,
                playroom: false,
                satelliteReceiver: false,
                chargingFacilities: false,
                balcony: false,
                woodenFrames: false,
                waterCloset: false,
            },
            externalFeatures: {
                privateGarden: false,
                awning: false,
                sharedGarden: false,
                facade: false,
                roadType: false,
                residentialZone: false,
                accessForDisabilities: false,
                swimmingPool: false,
                builtInBBQ: false,
                parkingSpace: false,
                garden: false,
                balcony: false,
            },
            suitableFor: {
                student: false,
                holidayHome: false,
                investment: false,
                shortTermRental: false,
                commercialUse: false,
                medicalOffice: false,
            },
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            status: '',
            lotType: '',
            pricePerSqft: '',
            landSize: '',
            totalUnits: '',
            totalCarPark: '',
            totalTowers: '',
            totalFloors: '',
            startingUnitPrice: '',
            highestUnitPrice: '',
            carPark: '',
            furnishing: '',
            malaysiainternalFeatures: {
                airConditioning: false,
                fullyFittedKitchen: false,
                waterHeaters: false,
                builtInWardrobes: false,
                balcony: false,  // Also part of externalFeatures in Greece property
                ensuiteBathrooms: false,
                highQualityFlooring: false,
                openPlanLayout: false,
                highCeilings: false,
                smartHomeFeatures: false,
                utilityStorageRoom: false,
                privateLiftLobby: false,
                homeOfficeSpace: false,
                dualKeyAccess: false,
                pantryArea: false,
                laundryArea: false,
                highQualityFinishes: false,
                largeWindowsNaturalLight: false,
                soundproofing: false,
                walkInCloset: false,
                guestPowderRoom: false,
                smartWiring: false,
                privateGarden: false,
                emergencyEscapePlan: false,
            },
            facilities: {
                swimmingPool: false,
                gymnasium: false,
                security: false,
                parkingSpaces: false,
                childrensPlayground: false,
                multipurposeHall: false,
                bbqArea: false,
                joggingTrack: false,
                wadingPool: false,
                saunaSteamRoom: false,
                readingStudyRoom: false,
                clubhouse: false,
                managementOffice: false,
                gardenOrGreenSpaces: false,
                functionRoom: false,
                tennisCourt: false,
                badmintonCourt: false,
                surau: false,
                coveredCarPark: false,
                liftLobby: false,
                airbnbManagement: false,
            },
            singaporeInternalFeatures: {
                airConditioning: false,
                fullyFittedKitchen: false,
                waterHeaters: false,
                builtInWardrobes: false,
                balcony: false,
                ensuiteBathrooms: false,
                highQualityFlooring: false,
                openPlanLayout: false,
                highCeilings: false,
                smartHomeFeatures: false,
                utilityStorageRoom: false,
                privateLiftLobby: false,
                homeOfficeSpace: false,
                dualKeyAccess: false,
                pantryArea: false,
                laundryArea: false,
                highQualityFinishes: false,
                largeWindowsNaturalLight: false,
                soundproofing: false,
                walkInCloset: false,
                guestPowderRoom: false,
                smartWiring: false,
                privateGarden: false,
                emergencyEscapePlan: false,
            },
            singaporeFacilities: {
                swimmingPool: false,
                gymnasium: false,
                security: false,
                parkingSpaces: false,
                childrensPlayground: false,
                multipurposeHall: false,
                bbqArea: false,
                joggingTrack: false,
                wadingPool: false,
                saunaSteamRoom: false,
                readingStudyRoom: false,
                clubhouse: false,
                managementOffice: false,
                gardenOrGreenSpaces: false,
                functionRoom: false,
                tennisCourt: false,
                badmintonCourt: false,
                surau: false,
                coveredCarPark: false,
                liftLobby: false,
                airbnbManagement: false,
            },
            typeOfProperty: '',
            nearestMRT: '',
            neighbourhood: '',
            district: '',
            landTenure: '',
            condition: '',
            landTitle: '',
            modal: false,
            images: [], // Drag and drop images
            isDragging: false, // Drag and drop state
        };
    },
    methods: {
        async uploadImage(file) {
            const storageRef = ref(storage, `propertyform/${uuidv4()}-${file.name}`);
            const snapshot = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref);
            return downloadURL;
        },
        selectFiles(galleryType) {
            if (galleryType === 'greeceGallery') {
                this.$refs.greeceFileInput.click();
            } else if (galleryType === 'singaporeGallery') {
                this.$refs.singaporeFileInput.click();
            } else if (galleryType === 'malaysiaGallery') {
                this.$refs.malaysiaFileInput.click();
            }
        },
        async onFileSelect(event, galleryType) {
            const files = event.target.files;
            if (files.length === 0) return;

            const gallery = this.getGalleryByType(galleryType);

            for (let i = 0; i < files.length; i++) {
                if (files[i].type.split("/")[0] !== "image") continue;
                const imageURL = URL.createObjectURL(files[i]);  // Create image preview URL
                gallery.push({ name: files[i].name, url: imageURL, file: files[i] });  // Store image and file object
            }
        },
        deleteImage(index, galleryType) {
            const gallery = this.getGalleryByType(galleryType);
            gallery.splice(index, 1);  // Remove image from the corresponding gallery
        },
        onDragOver(event) {
            event.preventDefault();
            this.isDragging = true;
            event.dataTransfer.dropEffect = "copy";
        },
        onDragLeave(event) {
            event.preventDefault();
            this.isDragging = false;
        },
        async onDrop(event, galleryType) {
            event.preventDefault();
            this.isDragging = false;

            const files = event.dataTransfer.files;
            const gallery = this.getGalleryByType(galleryType);

            for (let i = 0; i < files.length; i++) {
                if (files[i].type.split("/")[0] !== "image") continue;
                const imageURL = URL.createObjectURL(files[i]);  // Create image preview URL
                gallery.push({ name: files[i].name, url: imageURL, file: files[i] });  // Store image and file object
            }
        },
        // Utility function to return the correct gallery array
        getGalleryByType(galleryType) {
            if (galleryType === 'greeceGallery') {
                return this.greeceGallery;
            } else if (galleryType === 'singaporeGallery') {
                return this.singaporeGallery;
            } else if (galleryType === 'malaysiaGallery') {
                return this.malaysiaGallery;
            } else {
                return [];
            }
        },
        async submitForm() {
            try {
                // Upload images to Firebase Storage before submitting the form
                for (let i = 0; i < this.greeceGallery.length; i++) {
                    const file = this.greeceGallery[i].file;
                    const imageURL = await this.uploadImage(file);  // Upload image
                    this.greeceGallery[i].url = imageURL;  // Update URL to Firebase storage URL
                }

                // Submit the form with updated URLs to Firestore
                await addDoc(collection(db, 'greecepropertyform'), {
                    propertyTitle: this.propertyTitle,
                    propertyStatus: this.propertyStatus,
                    propertyType: this.propertyType,
                    propertyCategory: this.propertyCategory,
                    surfaceArea: this.surfaceArea,
                    plotSurfaceArea: this.plotSurfaceArea,
                    price: this.price,
                    pricePerSqM: this.pricePerSqM,
                    bedrooms: this.bedrooms,
                    bathrooms: this.bathrooms,
                    gallery: this.greeceGallery.map(img => img.url),  // Only store the URLs in Firestore
                    address: this.address,
                    city: this.city,
                    state: this.state,
                    zipCode: this.zipCode,
                    description: this.description,
                    buildingAge: this.buildingAge,
                    garage: this.garage,
                    rooms: this.rooms,
                    heatingSystem: this.heatingSystem,
                    energyClass: this.energyClass,
                    completionYear: this.completionYear,
                    internalFeatures: this.internalFeatures,
                    externalFeatures: this.externalFeatures,
                    suitableFor: this.suitableFor,
                    contactName: this.contactName,
                    contactEmail: this.contactEmail,
                    contactPhone: this.contactPhone,
                    createdAt: serverTimestamp(),
                });

                // Clear the gallery after successful form submission
                this.greeceGallery = [];
                this.$refs.greeceFileInput.value = '';  // Clear the file input

                alert('Greece Property added successfully!');

            } catch (error) {
                console.error('Error adding Greece property: ', error);
                alert('Failed to add Greece property. Please try again.');
            }
        },
        async malaysiaSubmitForm() {
            try {
                // Upload images to Firebase Storage before submitting the form
                for (let i = 0; i < this.malaysiaGallery.length; i++) {
                    const file = this.malaysiaGallery[i].file;
                    const imageURL = await this.uploadImage(file);  // Upload image
                    this.malaysiaGallery[i].url = imageURL;  // Update URL to Firebase storage URL
                }

                await addDoc(collection(db, 'malaysiapropertyform'), {
                    propertyTitle: this.propertyTitle,
                    propertyStatus: this.propertyStatus,
                    propertyType: this.propertyType,
                    propertyCategory: this.propertyCategory,
                    surfaceArea: this.surfaceArea,
                    lotType: this.lotType,
                    price: this.price,
                    pricePerSqft: this.pricePerSqft,
                    bedrooms: this.bedrooms,
                    bathrooms: this.bathrooms,
                    gallery: this.malaysiaGallery.map(img => img.url),  // Only store the URLs in Firestore
                    address: this.address,
                    city: this.city,
                    state: this.state,
                    zipCode: this.zipCode,
                    description: this.description,
                    buildingAge: this.buildingAge,
                    condition: this.condition,
                    landTenure: this.landTenure,
                    landTitle: this.landTitle,
                    landSize: this.landSize,
                    totalUnits: this.totalUnits,
                    totalCarPark: this.totalCarPark,
                    totalTowers: this.totalTowers,
                    totalFloors: this.totalFloors,
                    startingUnitPrice: this.startingUnitPrice,
                    highestUnitPrice: this.highestUnitPrice,
                    rooms: this.rooms,
                    carPark: this.carPark,
                    furnishing: this.furnishing,
                    completionYear: this.completionYear,
                    malaysiainternalFeatures: this.malaysiainternalFeatures,
                    facilities: this.externalFeatures,
                    suitableFor: this.suitableFor,
                    contactName: this.contactName,
                    contactEmail: this.contactEmail,
                    contactPhone: this.contactPhone,
                    createdAt: serverTimestamp(),
                });

                // Clear the gallery after successful form submission
                this.malaysiaGallery = [];
                this.$refs.malaysiaFileInput.value = '';  // Clear the file input

                alert('Malaysia Property added successfully!');
            } catch (error) {
                console.error('Error adding Malaysia property: ', error);
                alert('Failed to add Malaysia property. Please try again.');
            }
        },
        async singaporeSubmitForm() {
            try {
                await addDoc(collection(db, 'singaporepropertyform'), {
                    // Other fields here...
                    gallery: this.gallery,  // Updated gallery field
                    createdAt: serverTimestamp(),
                });

                alert('Singapore Property added successfully!');
            } catch (error) {
                console.error('Error adding Singapore property: ', error);
                alert('Failed to add Singapore property. Please try again.');
            }
        },
        toggleNavMenu() {
            this.navMenuVisible = !this.navMenuVisible;
        },
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
    },
});
</script>

<style scoped>
.image-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.image-preview {
    position: relative;
    width: 100px;
    height: 100px;
}

.image-preview-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.delete {
    position: absolute;
    top: 5px;
    right: 5px;
    color: white;
    background-color: red;
    border-radius: 50%;
    padding: 2px 6px;
    cursor: pointer;
}

.card {
    width: 100%;
    padding: 10px;
    box-shadow: 0 0 5px #fdfdfd;
    border-radius: 5px;
    overflow: hidden;
}

.card .top {
    text-align: center;
}

.card p {
    font-weight: bold;
    color: #fe0000;
}

.card button {
    outline: 0;
    border: 0;
    color: #fff;
    border-radius: 4px;
    font-weight: 400;
    padding: 8px 13px;
    width: 100%;
    background: #fe0000;
}

.card .drag-area {
    height: 150px;
    border-radius: 5px;
    border: 2px dashed #fe0000;
    background: #f4f3f9;
    color: #fe0000;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    -webkit-user-select: none;
    margin-top: 10px;
}

.card .drag-area .visible {
    font-size: 18px;
}

.card .select {
    color: #5256ad;
    margin-left: 5px;
    cursor: pointer;
    transition: 0.4s;
}

.card .select:hover {
    opacity: 0.6;
}

.card .container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 200px;
    position: relative;
    margin-bottom: 8px;
    padding-top: 15px;
}

.card .container .image {
    width: 75px;
    margin-right: 5px;
    height: 75px;
    position: relative;
    margin-bottom: 8px;
}

.card .container .image img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.card .container .image span {
    position: absolute;
    top: -2px;
    right: 9px;
    font-size: 20px;
    cursor: pointer;
}

.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
    background-color: white;
    /* Set background color to white */
    color: #000000;
    /* Set icon color */
    /* padding: 5px; */
    /* Adjust padding to ensure icon is centered */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the icon */
    width: 50px;
    /* Set a fixed width for the button */
    height: 50px;
    /* Set a fixed height for the button */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
}

.add-listing a {
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

.d-flex {
    flex-wrap: nowrap;
}

.bg-danger {
    background-color: #d9534f;
    color: white;
}

a img {
    vertical-align: middle;
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
}

#main-wrapper {
    padding-top: 70px;
}

.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.input-with-icon select.form-control {
    padding-bottom: 1px;
    /* Adjust the value as needed */
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left: 40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 70px;
    margin-left: 50px;
}
</style>
