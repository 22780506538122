<template>
    <div :key="$i18n.locale" class="yellow-skin">

        <!-- This is the preloader -->
        <!-- <div class="preloader"></div> -->
        <div id="main-wrapper">
            <!-- Start Navigation -->
            <Navigation />
            <!-- ============================ End Navigation ================================== -->

            <div class="clearfix"></div>
            <!-- ============================ Page Title Start================================== -->
            <div class="page-title" :style="{ background: `#f4f4f4 url(${bgImage})` }" data-overlay="5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">

                            <div class="breadcrumbs-wrap position-relative z-1">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item active" aria-current="page">{{
                                        $t('userProperty.propertyCategoryMyProperties') }}</li>
                                </ol>
                                <h2 class="breadcrumb-title">{{ $t('userProperty.propertyCategoryAllProperties') }}</h2>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- ============================ Page Title End ================================== -->

            <!-- ============================ User Dashboard ================================== -->
            <section class="gray pt-5 pb-5">
                <div class="container-fluid">

                    <div class="row">

                        <div class="col-lg-3 col-md-4 col-sm-12">
                            <div class="property_dashboard_navbar">

                                <div class="dash_user_avater">
                                    <img :src="profileLogo" class="img-fluid avater" alt="">
                                    <h4>{{ userProfile.fullName }}</h4>
                                    <span>{{ userProfile.email }}</span>
                                </div>

                                <div class="dash_user_menues">
                                    <ul>
                                        <li><router-link to="/user-dashboard"><i class="fa fa-tachometer-alt"></i>{{
                                            $t('userProperty.propertyCategoryDashboard') }}<span
                                                    class="notti_coun style-1">4</span></router-link></li>
                                        <li><router-link to="/user-profile"><i class="fa fa-user-tie"></i>{{
                                            $t('userProperty.propertyCategoryProfile') }}</router-link></li>
                                        <li><router-link to="/user-saved-property"><i class="fa fa-bookmark"></i>{{
                                            $t('userProperty.propertyCategorySavedProperty') }}<span
                                                    class="notti_coun style-2">7</span></router-link></li>
                                        <li class="active"><router-link to="/user-property"><i
                                                    class="fa fa-tasks"></i>{{
                                                        $t('userProperty.propertyCategoryMyProperties') }}</router-link></li>
                                        <!-- <li><a href="messages.html"><i class="fa fa-envelope"></i>Messages<span
                                                    class="notti_coun style-3">3</span></a></li> -->
                                        <li><router-link to="/user-subscription"><i class="fa fa-gift"></i>{{
                                            $t('userProperty.propertyCategoryMySubscription') }}<span
                                                    class="expiration">{{
                                                        $t('userProperty.propertyCategorySubscriptionDaysLeft')
                                                    }}</span></router-link></li>
                                        <li><router-link to="/user-change-password"><i class="fa fa-unlock-alt"></i>{{
                                            $t('userProperty.propertyCategoryChangePassword') }}</router-link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-9 col-md-8 col-sm-12">
                            <div class="dashboard-body">

                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="_prt_filt_dash">
                                            <div class="_prt_filt_dash_flex">
                                                <div class="foot-news-last">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                            placeholder="Search Property">
                                                        <div class="input-group-append">
                                                            <span type="button"
                                                                class="input-group-text bg-danger border-0 text-light"><i
                                                                    class="fas fa-search"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="_prt_filt_dash_last m2_hide">
                                                <div class="_prt_filt_radius">

                                                </div>
                                                <div class="_prt_filt_add_new">
                                                    <a href="submit-property-dashboard.html" class="prt_submit_link"><i
                                                            class="fas fa-plus-circle"></i><span
                                                            class="d-none d-lg-block d-md-block">{{
                                                                $t('userProperty.propertyCategoryListNewProperty')
                                                            }}</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="dashboard_property">
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead class="thead-dark">
                                                        <tr>
                                                            <th scope="col">{{
                                                                $t('userProperty.propertyCategoryProperty') }}</th>
                                                            <th scope="col" class="m2_hide">{{
                                                                $t('userProperty.propertyCountry') }}</th>
                                                            <th scope="col" class="m2_hide">{{
                                                                $t('userProperty.propertyCategory') }}</th>
                                                            <th scope="col" class="m2_hide">{{
                                                                $t('userProperty.propertyCategoryStats') }}</th>
                                                            <th scope="col" class="m2_hide">{{
                                                                $t('userProperty.propertyCategoryPostedOn') }}</th>
                                                            <th scope="col">{{ $t('userProperty.propertyCategoryStatus')
                                                                }}</th>
                                                            <th scope="col">{{ $t('userProperty.propertyCategoryAction')
                                                                }}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="propertyListing in propertyListings"
                                                            :key="propertyListing.id">
                                                            <td>
                                                                <div class="dash_prt_wrap">
                                                                    <div class="dash_prt_thumb">
                                                                        <img :src="getFirstImage(propertyListing.image_link) || 'assets/img/default-property.png'"
                                                                            class="img-fluid property-image" alt="Property Image" />
                                                                    </div>
                                                                    <div class="dash_prt_caption">
                                                                        <h5>{{ propertyListing.property_name }}</h5>
                                                                        <div class="prt_dashb_lot">{{
                                                                            propertyListing.area_address }}</div>
                                                                        <div class="prt_dash_rate"><span>${{
                                                                            propertyListing.price }}</span></div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td class="m2_hide">
                                                                <div class="_leads_view">
                                                                    <h5 class="up">{{ propertyListing.country }}</h5>
                                                                </div>
                                                            </td>
                                                            <td v-if="propertyListing.status === 'active'">
                                                                <div class="_leads_status">
                                                                    <span class="active">{{ propertyListing.userCategory
                                                                        }}</span>
                                                                </div>
                                                            </td>
                                                            <td v-else>
                                                                <div class="_leads_status">
                                                                    <span class="expire">{{
                                                                        propertyListing.userCategory }}</span>
                                                                </div>
                                                            </td>
                                                            <td class="m2_hide">
                                                                <div class="_leads_view">
                                                                    <h5 class="up">{{ propertyListing.totalViews }}</h5>
                                                                </div>
                                                                <div class="_leads_view_title">
                                                                    <span>{{
                                                                        $t('userProperty.propertyCategoryTotalViews')
                                                                    }}</span>
                                                                </div>
                                                            </td>
                                                            <td class="m2_hide">
                                                                <div class="_leads_posted">
                                                                    <h5>{{
                                                                        convertTimestampToDate(propertyListing.updated_at)
                                                                        }}</h5>
                                                                </div>
                                                                <div class="_leads_view_title">
                                                                    <span>{{
                                                                        calculateDaysAgo(propertyListing.updated_at) }}
                                                                        {{
                                                                            $t('userProperty.propertyCategoryDaysAgo')
                                                                        }}</span>
                                                                </div>
                                                            </td>
                                                            <td v-if="propertyListing.status === 'active'">
                                                                <div class="_leads_status">
                                                                    <span class="active">{{ propertyListing.status
                                                                        }}</span>
                                                                </div>
                                                            </td>
                                                            <td v-else>
                                                                <div class="_leads_status">
                                                                    <span class="expire">{{
                                                                        propertyListing.status }}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="_leads_action">
                                                                    <a href="#"><i class="fas fa-edit"></i></a>
                                                                    <a href="#"><i class="fas fa-trash"></i></a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- row -->
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- ============================ User Dashboard End ================================== -->

            <!-- ============================ Call To Action ================================== -->
            <PageFooter />
            <!-- ============================ Call To Action End ================================== -->
            <!-- ============================ Footer Start ================================== -->
            <!-- ============================ Footer End ================================== -->
        </div>
    </div>
</template>

<script>
import { auth, db } from '../../firebase'; // Import the auth object
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    sendPasswordResetEmail,
} from 'firebase/auth';
import { collection, query, where, doc, getDoc, getDocs } from 'firebase/firestore';

import 'magnific-popup';
import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';


import sampleImage1 from '@/assets/img/p-4.webp';
import oldIcon from '@/assets/img/old.png';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';
import bgImage from '@/assets/img/bg.jpg';


export default {
    name: 'UserProperty',
    components: {
        Navigation,
        PageFooter,
    },
    props: ['glb_id'],
    data() {
        return {
            bgImage,
            addProperty,
            menuWidget,
            profileLogo,
            forgotPasswordEmail: '',
            resetPasswordError: '',
            resetPasswordSuccess: '',
            selectedLanguage: this.$i18n.locale,
            sampleImage1,
            oldIcon,
            activeSubmenu: null,
            navMenuVisible: false,
            userLoggedIn: false,
            loginError: '',
            userProfile: {
                fullName: '',
                email: '',
            },
            propertyListings: [],
        };
    },
    methods: {
        getFirstImage(imageLinks) {
            if (imageLinks && Array.isArray(imageLinks) && imageLinks.length > 0) {
                return imageLinks[0]; // Return the first image in the array
            }
            return null; // Return null if no images found
        },
        convertTimestampToDate(timestamp) {
            if (timestamp && timestamp.seconds) {
                const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
                return date.toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                });
            }
            return 'Invalid date';
        },
        calculateDaysAgo(timestamp) {
            if (timestamp && timestamp.seconds) {
                const now = new Date();
                const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds

                // Calculate the difference in time (in milliseconds)
                const differenceInTime = now - date;

                // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 ms)
                const differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24));

                return differenceInDays;
            }
            return 'N/A';
        },
        async fetchUserProfile(email) {
            try {
                // Fetch user profile from Firestore using email as the document ID
                const docRef = doc(db, 'userProfiles', email);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    this.userProfile = docSnap.data(); // Update user profile data with the fetched document
                } else {
                    console.log("No user profile found.");
                }
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        },
        async fetchUserProperties(email) {
            try {
                // Query Firestore for property listings where the userEmail matches the authenticated user
                const q = query(
                    collection(db, 'property_listings'),
                    where('userEmail', '==', email)
                );

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    // Push each property listing into the propertyListings array
                    this.propertyListings.push({ id: doc.id, ...doc.data() });
                });
            } catch (error) {
                console.error('Error fetching property listings:', error);
            }
        },
        toggleNavMenu() {
            this.navMenuVisible = !this.navMenuVisible;
        },
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
        async handleForgotPassword() {
            try {
                await sendPasswordResetEmail(auth, this.forgotPasswordEmail);
                this.resetPasswordSuccess = 'A password reset email has been sent to your email address.';
                this.resetPasswordError = '';
            } catch (error) {
                console.error('Error sending password reset email:', error.message);
                this.resetPasswordError = 'Failed to send password reset email. Please try again.';
                this.resetPasswordSuccess = '';
            }
        },
        async handleLogin() {
            try {
                const userCredential = await signInWithEmailAndPassword(
                    auth,
                    this.loginEmail,
                    this.loginPassword
                );
                // Handle successful login
                console.log('User logged in:', userCredential.user);
                this.userLoggedIn = true; // Set login state to true
                this.loginError = '';
                this.$refs.closeBtn.click();
            } catch (error) {
                console.error('Error logging in:', error.message);
                this.loginError = 'Invalid username or password. Please try again.';
            }
        },
        async handleRegister() {
            try {
                const userCredential = await createUserWithEmailAndPassword(
                    auth,
                    this.registerEmail,
                    this.registerPassword
                );
                // Handle successful registration
                console.log('User registered:', userCredential.user);
                this.userLoggedIn = true; // Set login state to true
                this.$refs.closeBtn.click();
            } catch (error) {
                console.error('Error registering:', error.message);
                // Handle errors (e.g., show an error message)
            }
        },
        async handleLogout() {
            try {
                await signOut(auth);
                this.userLoggedIn = false; // Set login state to false
                console.log('User logged out');
            } catch (error) {
                console.error('Error logging out:', error.message);
            }
        },
        changeLanguage() {
            console.log("Language changing to:", this.selectedLanguage);
            this.$i18n.locale = this.selectedLanguage;
            localStorage.setItem('userLanguage', this.selectedLanguage);
            this.$forceUpdate();
        },
    },
    created() {
        // Check authentication state on component creation
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.userLoggedIn = true;
                this.fetchUserProfile(user.email);
                this.fetchUserProperties(user.email);
            } else {
                this.userLoggedIn = false;
                this.userName = 'Guest';
                this.userEmail = '';
            }
        });
        const savedLanguage = localStorage.getItem('userLanguage');
        if (savedLanguage) {
            this.selectedLanguage = savedLanguage;
            this.$i18n.locale = savedLanguage;
        }
    },
};
</script>

<style scoped>
.property-image {
    width: 100%; /* Set the image to take up the full width of the container */
    height: 150px; /* Set a fixed height */
    object-fit: cover; /* Ensures the image maintains its aspect ratio and fills the container */
    border-radius: 8px; /* Optional: Add rounded corners if you prefer */
}

.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
    background-color: white;
    /* Set background color to white */
    color: #000000;
    /* Set icon color */
    /* padding: 5px; */
    /* Adjust padding to ensure icon is centered */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the icon */
    width: 50px;
    /* Set a fixed width for the button */
    height: 50px;
    /* Set a fixed height for the button */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.input-with-icon select.form-control {
    padding-bottom: 1px;
    /* Adjust the value as needed */
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
}

.add-listing a {
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

.d-flex {
    flex-wrap: nowrap;
}

.bg-danger {
    background-color: #d9534f;
    color: white;
}

a img {
    vertical-align: middle;
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
}

#main-wrapper {
    padding-top: 70px;
}

.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left: 40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 150px;
    margin-left: 50px;
}
</style>
