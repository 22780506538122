// listingSingaporeStore.js
import { defineStore } from 'pinia';
import { db } from '../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

export const useListingSingaporeStore = defineStore('listingSingaporeStore', {
    state: () => ({
        listings: [],
        isLoading: false,
    }),
    actions: {
        async fetchListings() {
            this.isLoading = true;
            try {
                const q = query(
                    collection(db, 'property_listings'),
                    where('glb_id', '>=', 'GLB_SG_'),
                    where('glb_id', '<', 'GLB_SG_\uf8ff')
                );
                const querySnapshot = await getDocs(q);
                this.listings = querySnapshot.docs.map(doc => doc.data());
            } catch (error) {
                console.error('Error fetching listings:', error);
            } finally {
                this.isLoading = false;
            }
        },
    },
});
