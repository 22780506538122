<template>
    <div :key="$i18n.locale" class="yellow-skin">
        <div id="main-wrapper">
            <!-- Start Navigation -->
            <Navigation />
            <!-- ============================ End Navigation ================================== -->
            <div class="clearfix"></div>
            <!-- ============================ Page Title Start================================== -->
            <section class="image-cover faq-sec text-center"
                :style="{ backgroundImage: `url(${require('@/assets/img/bg.jpg')})` }" data-overlay="6">
                <div class="container">
                    <div class="row">

                        <div class="col-lg-12 col-md-12 position-relative z-1">
                            <h1 class="text-light">{{ $t('citizenship.citizenship_investment') }}</h1>
                            <!-- <div class="faq-search">
                                <form>
                                    <input name="search" class="form-control" placeholder="Search Your Query...">
                                    <button type="submit" class="theme-cl"> <i class="ti-search"></i> </button>
                                </form>
                            </div> -->
                        </div>

                    </div>
                </div>
            </section>
            <!-- ============================ Page Title End ================================== -->

            <!-- ================= Citizenship by Investment  ================= -->
            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">

                            <!-- Single Basics List -->
                            <div class="faq_wrap">
                                <div class="faq_wrap_title">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-10 col-md-12">
                                            <div class="sec-heading center">
                                                <h2>{{ $t('citizenship.list_of_countries') }}</h2>
                                                <p style="color: black;">{{
                                                    $t('citizenship.citizenship_investment_text') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="custom-tab style-1" style="margin-left: 10px;">
                                                <ul class="nav nav-tabs pb-2 b-0" id="myTab" role="tablist">
                                                    <li class="nav-item">
                                                        <a class="nav-link active" id="Dominica-tab"
                                                            data-bs-toggle="tab" href="#Dominica" role="tab"
                                                            aria-controls="Dominica" aria-selected="true">{{
                                                                $t('citizenship.dominica') }}</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" id="North-Macedonia-tab"
                                                            data-bs-toggle="tab" href="#north_macedonia" role="tab"
                                                            aria-controls="north_macedonia" aria-selected="false">{{
                                                                $t('citizenship.north_macedonia') }}</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" id="Grenada-tab" data-bs-toggle="tab"
                                                            href="#grenada" role="tab" aria-controls="grenada"
                                                            aria-selected="false">{{ $t('citizenship.grenada') }}</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" id="Stkitts-neviss-tab" data-bs-toggle="tab"
                                                            href="#stkitts_neviss" role="tab"
                                                            aria-controls="stkitts_neviss" aria-selected="false">{{
                                                                $t('citizenship.stkitts_neviss') }}</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" id="Antigua-Barbuda-tab"
                                                            data-bs-toggle="tab" href="#antigua_barbuda" role="tab"
                                                            aria-controls="antigua_barbuda" aria-selected="false">{{
                                                                $t('citizenship.antiguaBarbuda') }}</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" id="Montenegro-tab" data-bs-toggle="tab"
                                                            href="#montenegro" role="tab" aria-controls="montenegro"
                                                            aria-selected="false">{{
                                                                $t('citizenship.montenegro') }}</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" id="Vanuatu-tab" data-bs-toggle="tab"
                                                            href="#vanuatu" role="tab" aria-controls="vanuatu"
                                                            aria-selected="false">{{
                                                                $t('citizenship.vanuatu') }}</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" id="Malta-tab" data-bs-toggle="tab"
                                                            href="#malta" role="tab" aria-controls="malta"
                                                            aria-selected="false">{{
                                                                $t('citizenship.malta') }}</a>
                                                    </li>
                                                    <p></p>
                                                    <li class="nav-item">
                                                        <a class="nav-link" id="SaintLucia-tab" data-bs-toggle="tab"
                                                            href="#saintLucia" role="tab" aria-controls="saintLucia"
                                                            aria-selected="false">{{
                                                                $t('citizenship.saintLucia') }}</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" id="Turkey-tab" data-bs-toggle="tab"
                                                            href="#turkey" role="tab" aria-controls="turkey"
                                                            aria-selected="false">{{
                                                                $t('citizenship.turkey') }}</a>
                                                    </li>
                                                </ul>
                                                <div class="tab-content" id="myTabContent">
                                                    <!-- Dominica Tab -->
                                                    <div class="tab-pane fade show active" id="Dominica" role="tabpanel"
                                                        aria-labelledby="Dominica-tab">
                                                        <div class="pricing_wrap">
                                                            <div class="fr-grid-thumb">
                                                                <a href="#">
                                                                    <img :src="dominica_flag" class="img-fluid mx-auto"
                                                                        alt="Dominica citizenship">
                                                                </a>
                                                            </div>
                                                            <div class="prt_head">
                                                                <h4>{{ $t('citizenship.dominica') }}</h4>
                                                                <p style="color: black;">{{
                                                                    $t('citizenship.dominica_description') }}</p>
                                                            </div>
                                                            <div class="prt_body">
                                                                <ul>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title1') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text1') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title2') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title3') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title4') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text4') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title5') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text5') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title6') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text6') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title7') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text7') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title8') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text8_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text8_2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title9') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text9_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text9_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text9_3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title10') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text10_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text10_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text10_3') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text10_4') }}</p>
                                                                </ul>
                                                            </div>
                                                            <div class="prt_footer">
                                                                <a href="https://wa.link/fw20eo"
                                                                    class="btn choose_package">{{
                                                                        $t('citizenship.enquire_now') }}</a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- North Macedonia Tab -->
                                                    <div class="tab-pane fade" id="north_macedonia" role="tabpanel"
                                                        aria-labelledby="North-Macedonia-tab">
                                                        <div class="pricing_wrap">
                                                            <div class="fr-grid-thumb">
                                                                <a href="#">
                                                                    <img :src="north_macedonia"
                                                                        class="img-fluid mx-auto" alt="North Macedonia citizenship">
                                                                </a>
                                                            </div>
                                                            <div class="prt_head">
                                                                <h4>{{ $t('citizenship.north_macedonia') }}</h4>
                                                                <p style="color: black;">{{
                                                                    $t('citizenship.north_macedonia_description') }}</p>
                                                            </div>
                                                            <div class="prt_body">
                                                                <ul>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_title1') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text1') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_title2') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_title3') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_title4') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text4') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_title5') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text5') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_title6') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text6') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_title7') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text7') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_title8') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text8_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text8_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text8_3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_title9') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text9_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text9_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text9_3') }}</p>
                                                                </ul>
                                                            </div>
                                                            <div class="prt_footer">
                                                                <a href="https://wa.link/fw20eo"
                                                                    class="btn choose_package">{{
                                                                        $t('citizenship.enquire_now') }}</a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Grenada Tab -->
                                                    <div class="tab-pane fade" id="grenada" role="tabpanel"
                                                        aria-labelledby="Grenada-tab">
                                                        <div class="pricing_wrap">
                                                            <div class="fr-grid-thumb">
                                                                <a href="#">
                                                                    <img :src="grenadaFlag" class="img-fluid mx-auto"
                                                                        alt="Grenada citizenship">
                                                                </a>
                                                            </div>
                                                            <div class="prt_head">
                                                                <h4>{{ $t('citizenship.grenada') }}</h4>
                                                                <p style="color: black;">{{
                                                                    $t('citizenship.grenada_description') }}</p>
                                                            </div>
                                                            <div class="prt_body">
                                                                <ul>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_title1') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text1') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_title2') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_title3') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_title4') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text4') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_title5') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text5') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_title6') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text6') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_title7') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text7_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text7_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text7_3') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text7_4') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_title8') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text8_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text8_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text8_3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_title9') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text9_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text9_2') }}</p>
                                                                </ul>
                                                            </div>
                                                            <div class="prt_footer">
                                                                <a href="https://wa.link/fw20eo"
                                                                    class="btn choose_package">{{
                                                                        $t('citizenship.enquire_now') }}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Stkitts-neviss Tab -->
                                                    <div class="tab-pane fade" id="stkitts_neviss" role="tabpanel"
                                                        aria-labelledby="Stkitts-neviss-tab">
                                                        <div class="pricing_wrap">
                                                            <div class="fr-grid-thumb">
                                                                <a href="#">
                                                                    <img :src="saintKittsNevis_flag" class="img-fluid mx-auto"
                                                                        alt="Saint Kitts and Navis citizenship">
                                                                </a>
                                                            </div>
                                                            <div class="prt_head">
                                                                <h4>{{ $t('citizenship.stkitts_neviss') }}</h4>
                                                                <p style="color: black;">{{
                                                                    $t('citizenship.stkitts_neviss_description') }}</p>
                                                            </div>
                                                            <div class="prt_body">
                                                                <ul>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_title1') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_text1') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_title2') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_text2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_title3') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_text3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_title4') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_text4') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_title5') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_text5') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_title6') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_text6') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_title7') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_text7a') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_text7b') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_text7c') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_text7d') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_title8') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_text8') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_title9') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_text9') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_title10') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.stkitts_neviss_text10') }}</p>
                                                                </ul>
                                                            </div>
                                                            <div class="prt_footer">
                                                                <a href="https://wa.link/fw20eo"
                                                                    class="btn choose_package">{{
                                                                        $t('citizenship.enquire_now') }}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Antigua and Barbuda Tab -->
                                                    <div class="tab-pane fade" id="antigua_barbuda" role="tabpanel"
                                                        aria-labelledby="Antigua-Barbuda-tab">
                                                        <div class="pricing_wrap">
                                                            <div class="fr-grid-thumb">
                                                                <a href="#">
                                                                    <img :src="antiguaBarbuda_flag" class="img-fluid mx-auto"
                                                                        alt="Antigua and Barbuda citizenship">
                                                                </a>
                                                            </div>
                                                            <div class="prt_head">
                                                                <h4>{{ $t('citizenship.antiguaBarbuda') }}</h4>
                                                                <p style="color: black;">{{
                                                                    $t('citizenship.antiguaBarbuda_description') }}</p>
                                                            </div>
                                                            <div class="prt_body">
                                                                <ul>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_title1') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text1') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_title2') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_title3') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_title4') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text4') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_title5') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text5') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_title6') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text6') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_title7') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text7_1') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_title8') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text8_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text8_2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_title9') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text9_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text9_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text9_3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_title10') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text10_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text10_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text10_3') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text10_4') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_title11') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text11_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text11_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text11_3') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text11_4') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text11_5') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.antiguaBarbuda_text11_6') }}</p>
                                                                </ul>
                                                            </div>

                                                            <div class="prt_footer">
                                                                <a href="https://wa.link/fw20eo"
                                                                    class="btn choose_package">{{
                                                                        $t('citizenship.enquire_now') }}</a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Montenegro Tab -->
                                                    <div class="tab-pane fade" id="montenegro" role="tabpanel"
                                                        aria-labelledby="Montenegro-tab">
                                                        <div class="pricing_wrap">
                                                            <div class="fr-grid-thumb">
                                                                <a href="#">
                                                                    <img :src="montenegro_flag" class="img-fluid mx-auto"
                                                                        alt="Montenegro citizenship">
                                                                </a>
                                                            </div>
                                                            <div class="prt_head">
                                                                <h4>{{ $t('citizenship.montenegro') }}</h4>
                                                                <p style="color: black;">{{
                                                                    $t('citizenship.montenegro_description') }}</p>
                                                            </div>
                                                            <div class="prt_body">
                                                                <ul>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_title1') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text1') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_title2') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text2') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_title3') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text3') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_title4') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text4') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_title5') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text5') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_title6') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text6') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_title7') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text7_1') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_title8') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text8') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_title9') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text9_1') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_title10') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text10_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text10_2') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_title11') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text11_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text11_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text11_3') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text11_4') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text11_5') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_title12') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text12_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text12_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text12_3') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.montenegro_text12_4') }}</p>

                                                                </ul>
                                                            </div>
                                                            <div class="prt_footer">
                                                                <a href="https://wa.link/fw20eo"
                                                                    class="btn choose_package">{{
                                                                        $t('citizenship.enquire_now') }}</a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Vanuatu Tab -->
                                                    <div class="tab-pane fade" id="vanuatu" role="tabpanel"
                                                        aria-labelledby="Vanuatu-tab">
                                                        <div class="pricing_wrap">
                                                            <div class="fr-grid-thumb">
                                                                <a href="#">
                                                                    <img :src="vanuatu_flag" class="img-fluid mx-auto"
                                                                        alt="Vanuatu citizenship">
                                                                </a>
                                                            </div>
                                                            <div class="prt_head">
                                                                <h4>{{ $t('citizenship.vanuatu') }}</h4>
                                                                <p style="color: black;">{{
                                                                    $t('citizenship.vanuatu_description') }}</p>
                                                            </div>
                                                            <div class="prt_body">
                                                                <ul>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_title1') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_text1') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_title2') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_text2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_title3') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_text3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_title4') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_text4') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_title5') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_text5') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_title6') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_text6') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_title7') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_text7_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_text7_2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_title8') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_text8_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_text8_2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_title9') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_text9_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_text9_2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_title10') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_text10_1') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_title11') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_text11_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_text11_2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_title12') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_text12_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_text12_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.vanuatu_text12_3') }}</p>
                                                                </ul>
                                                            </div>

                                                            <div class="prt_footer">
                                                                <a href="https://wa.link/fw20eo"
                                                                    class="btn choose_package">{{
                                                                        $t('citizenship.enquire_now') }}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Malta Tab -->
                                                    <div class="tab-pane fade" id="malta" role="tabpanel"
                                                        aria-labelledby="Malta-tab">
                                                        <div class="pricing_wrap">
                                                            <div class="fr-grid-thumb">
                                                                <a href="#">
                                                                    <img :src="malta_flag" class="img-fluid mx-auto"
                                                                        alt="Malta citizenship">
                                                                </a>
                                                            </div>
                                                            <div class="prt_head">
                                                                <h4>{{ $t('citizenship.malta') }}</h4>
                                                                <p style="color: black;">{{
                                                                    $t('citizenship.malta_description') }}</p>
                                                            </div>
                                                            <div class="prt_body">
                                                                <ul>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_title1') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text1') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_title2') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_title3') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_title4') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text4') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_title5') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text5') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_title6') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text6') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_title7') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text7_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text7_2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_title8') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text8_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text8_2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_title9') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text9_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text9_2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_title10') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text10_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text10_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text10_3') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text10_4') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text10_5') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text10_6') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_title11') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text11_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text11_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text11_3') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.malta_text11_4') }}</p>
                                                                </ul>
                                                            </div>

                                                            <div class="prt_footer">
                                                                <a href="https://wa.link/fw20eo"
                                                                    class="btn choose_package">{{
                                                                        $t('citizenship.enquire_now') }}</a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Saint Lucia Tab -->
                                                    <div class="tab-pane fade" id="saintLucia" role="tabpanel"
                                                        aria-labelledby="SaintLucia-tab">
                                                        <div class="pricing_wrap">
                                                            <div class="fr-grid-thumb">
                                                                <a href="#">
                                                                    <img :src="saintLucia_flag" class="img-fluid mx-auto"
                                                                        alt="Saint Lucia citizenship">
                                                                </a>
                                                            </div>
                                                            <div class="prt_head">
                                                                <h4>{{ $t('citizenship.saintLucia') }}</h4>
                                                                <p style="color: black;">{{
                                                                    $t('citizenship.saintLucia_description') }}</p>
                                                            </div>
                                                            <div class="prt_body">
                                                                <ul>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_title1') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text1') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_title2') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_title3') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_title4') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text4') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_title5') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text5') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_title6') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text6') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_title7') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text7_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text7_2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_title8') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text8_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text8_2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_title9') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text9_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text9_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text9_3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_title10') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text10_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text10_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text10_3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_title11') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text11_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text11_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text11_3') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.saintLucia_text11_4') }}</p>
                                                                </ul>
                                                            </div>

                                                            <div class="prt_footer">
                                                                <a href="https://wa.link/fw20eo"
                                                                    class="btn choose_package">{{
                                                                        $t('citizenship.enquire_now') }}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Turkey Tab -->
                                                    <div class="tab-pane fade" id="turkey" role="tabpanel"
                                                        aria-labelledby="Turkey-tab">
                                                        <div class="pricing_wrap">
                                                            <div class="fr-grid-thumb">
                                                                <a href="#">
                                                                    <img :src="turkey_flag" class="img-fluid mx-auto"
                                                                        alt="Turkey citizenship">
                                                                </a>
                                                            </div>
                                                            <div class="prt_head">
                                                                <h4>{{ $t('citizenship.turkey') }}</h4>
                                                                <p style="color: black;">{{
                                                                    $t('citizenship.turkey_description') }}</p>
                                                            </div>
                                                            <div class="prt_body">
                                                                <ul>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_title1') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text1') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_title2') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text2') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_title3') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text3') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_title4') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text4') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_title5') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text5') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_title6') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text6') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_title7') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text7_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text7_2') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_title8') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text8_1') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_title9') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text9_1') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_title10') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text10_1') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_title11') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text11_1') }}</p>

                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text12_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text12_2') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_title13') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text13_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text13_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text13_3') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text13_4') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text13_5') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text13_6') }}</p>

                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_title14') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text14_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text14_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text14_3') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.turkey_text14_4') }}</p>

                                                                </ul>
                                                            </div>

                                                            <div class="prt_footer">
                                                                <a href="https://wa.link/fw20eo"
                                                                    class="btn choose_package">{{
                                                                    $t('citizenship.enquire_now') }}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- /row -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- ================= Citizenship by Investment End ================= -->

            <!-- ============================ Call To Action ================================== -->
            <PageFooter />
            <!-- ============================ Call To Action End ================================== -->
            <!-- ============================ Footer Start ================================== -->
            <!-- ============================ Footer End ================================== -->
        </div>
    </div>
</template>

<script>
import 'magnific-popup';

import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';

import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import sampleImage1 from '@/assets/img/p-4.webp';
import oldIcon from '@/assets/img/old.png';
import grenadaFlag from '@/assets/img/grenada_flag.webp';
import north_macedonia from '@/assets/img/north-macedonia.webp';
import dominica_flag from '@/assets/img/dominica_flag.webp';
import montenegro_flag from '@/assets/img/montenegro.webp';
import malta_flag from '@/assets/img/malta.webp';
import saintLucia_flag from '@/assets/img/saint_lucia.webp';
import vanuatu_flag from '@/assets/img/vanuatu.webp';
import turkey_flag from '@/assets/img/turkey.webp';
import saintKittsNevis_flag from '@/assets/img/saint-kitts-and-nevis.webp';
import antiguaBarbuda_flag from '@/assets/img/antigua_barbuda.webp';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';


export default {
    name: 'CitizenshipByInvestment',
    components: {
        Navigation,
        PageFooter,
    },
    props: ['glb_id'],
    data() {
        return {
            montenegro_flag,
            malta_flag,
            saintLucia_flag,
            vanuatu_flag,
            turkey_flag,
            saintKittsNevis_flag,
            antiguaBarbuda_flag,
            addProperty,
            menuWidget,
            profileLogo,
            globalHomeLogo,
            whatsappLogo,
            wechatLogo,
            loginLogo,
            settingsLogo,
            qrCodeLogo,
            sampleImage1,
            grenadaFlag,
            north_macedonia,
            dominica_flag,
            oldIcon,
            navMenuVisible: false,
            activeSubmenu: null,
            userLoggedIn: false,
            loginError: '',
        };
    },
    methods: {
        toggleNavMenu() {
            this.navMenuVisible = !this.navMenuVisible;
        },
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
    },
};
</script>

<style scoped>
.nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
}

.nav-item {
    flex-grow: 1;
    text-align: center;
}

.nav-link {
    padding: 10px 15px;
    white-space: nowrap;
}

.nav-item .nav-link {
    margin-bottom: 0;
    /* Remove any unnecessary margins */
}

.nav-item a.nav-link {
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
}

.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
    background-color: white;
    /* Set background color to white */
    color: #000000;
    /* Set icon color */
    /* padding: 5px; */
    /* Adjust padding to ensure icon is centered */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the icon */
    width: 50px;
    /* Set a fixed width for the button */
    height: 50px;
    /* Set a fixed height for the button */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
}

.add-listing a {
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

.d-flex {
    flex-wrap: nowrap;
}

.bg-danger {
    background-color: #d9534f;
    color: white;
}

a img {
    vertical-align: middle;
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
}

#main-wrapper {
    padding-top: 70px;
}

.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.input-with-icon select.form-control {
    padding-bottom: 1px;
    /* Adjust the value as needed */
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left: 40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 70px;
    margin-left: 50px;
}
</style>
