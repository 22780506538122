import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import i18n from './i18n';  // Import the i18n configuration
import router from '../router'; // Import the router
import './assets/css/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap';
import 'slick-carousel';
import 'jquery';
import 'popper.js';
import 'ion-rangeslider';
import 'select2';

const app = createApp(App);
const pinia = createPinia();

app.use(router);
app.use(i18n); // Use the i18n instance in the app
app.use(pinia);
app.mount('#app');
