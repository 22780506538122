<template>
    <div :key="$i18n.locale" class="yellow-skin">

        <!-- This is the preloader -->
        <!-- <div class="preloader"></div> -->
        <div id="main-wrapper">
            <!-- Start Navigation -->
            <Navigation />
            <!-- ============================ End Navigation ================================== -->

            <div class="clearfix"></div>
            <!-- ============================ Page Title Start================================== -->
            <div class="page-title" :style="{ background: `#f4f4f4 url(${bgImage})` }" data-overlay="5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">

                            <div class="breadcrumbs-wrap position-relative z-1">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item active" aria-current="page">{{ $t('user_dashboard.dashboard') }}</li>
                                </ol>
                                <h2 class="breadcrumb-title">{{ $t('user_dashboard.welcome_message') }}</h2>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- ============================ Page Title End ================================== -->

            <!-- ============================ User Dashboard ================================== -->
            <section class="gray pt-5 pb-5">
                <div class="container-fluid">

                    <div class="row">

                        <div class="col-lg-3 col-md-4">
                            <div class="property_dashboard_navbar">

                                <div class="dash_user_avater">
                                    <img :src="profileLogo" class="img-fluid avater" alt="">
                                    <h4>{{ userProfile.fullName }}</h4>
                                    <span>{{ userProfile.email }}</span>
                                </div>

                                <div class="dash_user_menues">
                                    <ul>
                                        <li class="active"><router-link to="/user-dashboard"><i
                                                    class="fa fa-tachometer-alt"></i>{{ $t('user_dashboard.dashboard') }}<span
                                                    class="notti_coun style-1">4</span></router-link></li>
                                        <li><router-link to="/user-profile"><i class="fa fa-user-tie"></i>{{ $t('user_dashboard.my_profile') }}</router-link></li>
                                        <li><router-link to="/user-saved-property"><i class="fa fa-bookmark"></i>{{ $t('user_dashboard.saved_property') }}<span class="notti_coun style-2">7</span></router-link></li>
                                        <li><router-link to="/user-property"><i class="fa fa-tasks"></i>{{ $t('user_dashboard.my_properties') }}</router-link></li>
                                        <!-- <li><a href="messages.html"><i class="fa fa-envelope"></i>Messages<span
                                                    class="notti_coun style-3">3</span></a></li> -->
                                        <li><router-link to="/user-subscription"><i class="fa fa-gift"></i>{{ $t('user_dashboard.my_subscription') }}<span class="expiration">10 days left</span></router-link></li>
                                        <li><router-link to="/user-change-password"><i
                                            class="fa fa-unlock-alt"></i>{{ $t('user_dashboard.change_password') }}</router-link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-9 col-md-8">
                            <div class="dashboard-body">

                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h4>{{ $t('user_dashboard.current_subscription') }} <span class="pc-title theme-cl">{{ $t('user_dashboard.membership_type') }}</span>
                                        </h4>
                                    </div>
                                </div>

                                <div class="row">

                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="dashboard_stats_wrap widget-1">
                                            <div class="dashboard_stats_wrap_content">
                                                <h4>607</h4> <span>{{ $t('user_dashboard.listed_property') }}</span>
                                            </div>
                                            <div class="dashboard_stats_wrap-icon"><i class="ti-location-pin"></i></div>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="dashboard_stats_wrap widget-3">
                                            <div class="dashboard_stats_wrap_content">
                                                <h4>$10,540</h4> <span>{{ $t('user_dashboard.earned_this_week') }}</span>
                                            </div>
                                            <div class="dashboard_stats_wrap-icon"><i class="ti-wallet"></i></div>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="dashboard_stats_wrap widget-2">
                                            <div class="dashboard_stats_wrap_content">
                                                <h4>540,580</h4> <span>{{ $t('user_dashboard.earned_this_month') }}</span>
                                            </div>
                                            <div class="dashboard_stats_wrap-icon"><i class="ti-credit-card"></i></div>
                                        </div>
                                    </div>

                                </div>
                                <!--  row -->

                                <div class="row">
                                    <div class="col-lg-8 col-md-7 col-sm-12">
                                        <div class="card">
                                            <div class="card-header">
                                                <h4 class="mb-0">{{ $t('user_dashboard.extra_area_chart') }}</h4>
											</div>
											<div class="card-body">
                                                <canvas id="myChart" style="height: 300px;"></canvas>
                                            </div>
										</div>
									</div>

                                    <div class="col-lg-4 col-md-5 col-sm-12">
                                        <div class="card">
                                            <div class="card-header">
                                                <h6>{{ $t('user_dashboard.notifications') }}</h6>
                                            </div>
                                            <div class="ground-list ground-list-hove">
                                                <div class="ground ground-single-list">
                                                    <a href="#">
                                                        <div class="btn-circle-40 theme-cl theme-bg-light"><i
                                                                class="ti-home"></i></div>
                                                    </a>

                                                    <div class="ground-content">
                                                        <h6><a href="#">Your listing <strong>Azreal Modern</strong> has
                                                                been approved!.</a></h6>
                                                        <span class="small">Just Now</span>
                                                    </div>
                                                </div>

                                                <div class="ground ground-single-list">
                                                    <a href="#">
                                                        <div class="btn-circle-40 theme-cl theme-bg-light"><i
                                                                class="ti-comment-alt"></i></div>
                                                    </a>

                                                    <div class="ground-content">
                                                        <h6><a href="#">Litha Lynes left a review on <strong>Renovated
                                                                    Apartment</strong></a></h6>
                                                        <span class="small">20 min ago</span>
                                                    </div>
                                                </div>

                                                <div class="ground ground-single-list">
                                                    <a href="#">
                                                        <div class="btn-circle-40 theme-cl theme-bg-light"><i
                                                                class="ti-heart"></i></div>
                                                    </a>

                                                    <div class="ground-content">
                                                        <h6><a href="#">Someone bookmark your View
                                                                listing!<strong>Sargun Villa Bay</strong></a></h6>
                                                        <span class="small">1 day ago</span>
                                                    </div>
                                                </div>

                                                <div class="ground ground-single-list">
                                                    <a href="#">
                                                        <div class="btn-circle-40 theme-cl theme-bg-light"><i
                                                                class="ti-home"></i></div>
                                                    </a>

                                                    <div class="ground-content">
                                                        <h6><a href="#">Your listing <strong>Modern Family Home</strong>
                                                                has been approved!.</a></h6>
                                                        <span class="small">10 days ago</span>
                                                    </div>
                                                </div>

                                                <div class="ground ground-single-list">
                                                    <a href="#">
                                                        <div class="btn-circle-40 theme-cl theme-bg-light"><i
                                                                class="ti-comment-alt"></i></div>
                                                    </a>

                                                    <div class="ground-content">
                                                        <h6><a href="#">Adam Brown left a review on <strong>Renovated
                                                                    Apartment</strong></a></h6>
                                                        <span class="small">Just Now</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- row -->

                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="card">
                                            <div class="card-header">
                                                <h4 class="mb-0">{{ $t('user_dashboard.listing_status') }}</h4>
                                            </div>
                                            <div class="card-body p-0">
                                                <div class="table-responsive">
                                                    <table class="table table-lg table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>{{ $t('user_dashboard.title') }}</th>
                                                                <th>{{ $t('user_dashboard.listing_id') }}</th>
                                                                <th>{{ $t('user_dashboard.status') }}</th>
                                                                <th>{{ $t('user_dashboard.price') }}</th>
                                                                <th>{{ $t('user_dashboard.date_created') }}</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td><a href="#"><img
                                                                            src="https://firebasestorage.googleapis.com/v0/b/globalhome-c6c4b.appspot.com/o/johorbahru_malaysia%2Fparagon_1.webp?alt=media"
                                                                            class="rounded img-fluid me-2" width="80"
                                                                            alt="Avatar">Paragon Gateway</a></td>
                                                                <td>#258475</td>
                                                                <td>
                                                                    <div class="label text-success bg-success-light">
                                                                        Available</div>
                                                                </td>
                                                                <td>starting RM 450000</td>
                                                                <td>04/10/2023</td>
                                                            </tr>

                                                            <tr>
                                                                <td><a href="#"><img
                                                                            src="https://firebasestorage.googleapis.com/v0/b/globalhome-c6c4b.appspot.com/o/johorbahru_malaysia%2Foasis_1.webp?alt=media"
                                                                            class="rounded img-fluid me-2" width="80"
                                                                            alt="Avatar">Oasis Residences</a></td>
                                                                <td>#249578</td>
                                                                <td>
                                                                    <div class="label text-warning bg-warning-light">
                                                                        Available</div>
                                                                </td>
                                                                <td>above RM 585000</td>
                                                                <td>05/08/2024</td>
                                                            </tr>
                                                            <!-- <tr>
																<td><a href="#"><img src="assets/img/p-3.webp" class="rounded img-fluid me-2" width="80" alt="Avatar">Preet Silver City</a></td>
																<td>#248712</td>
																<td><div class="label text-danger bg-danger-light">Sold</div></td>  
																<td>$ 710.5</td>
																<td>11/05/2015</td>                                          
															</tr>
															
															<tr>
																<td><a href="#"><img src="assets/img/p-4.webp" class="rounded img-fluid me-2" width="80" alt="Avatar">Mount See Villa</a></td>
																<td>#287246</td>
																<td><div class="label text-success bg-success-light">Available</div></td>
																<td>$ 482.70</td>
																<td>06/09/2016</td>
															</tr> -->
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- row -->

                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <!-- ============================ User Dashboard End ================================== -->

            <!-- ============================ Call To Action ================================== -->
            <PageFooter />
            <!-- ============================ Call To Action End ================================== -->
            <!-- ============================ Footer Start ================================== -->
            <!-- ============================ Footer End ================================== -->
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Chart, registerables } from 'chart.js';

import { auth, db } from '../../firebase'; // Import the auth object
import {
    onAuthStateChanged,
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

import 'magnific-popup';
import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';

import sampleImage1 from '@/assets/img/p-4.webp';
import oldIcon from '@/assets/img/old.png';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';
import bgImage from '@/assets/img/bg.jpg';

Chart.register(...registerables);

export default defineComponent({
    name: 'UserDashboard',
    components: {
        Navigation,
        PageFooter,
    },
    props: ['glb_id'],
    data() {
        return {
            bgImage,
            addProperty,
            menuWidget,
            profileLogo,
            sampleImage1,
            oldIcon,
            activeSubmenu: null,
            navMenuVisible: false,
            userLoggedIn: false,
            loginError: '',
            userProfile: {
                fullName: '',
                email: '',
            },
        };
    },
    mounted() {
        this.renderChart();
    },
    methods: {
        async fetchUserProfile(email) {
            try {
                // Fetch user profile from Firestore using email as the document ID
                const docRef = doc(db, 'userProfiles', email);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    this.userProfile = docSnap.data(); // Update user profile data with the fetched document
                } else {
                    console.log("No user profile found.");
                }
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        },
        renderChart() {
            const ctx = document.getElementById('myChart').getContext('2d');
            new Chart(ctx, {
                type: 'line',
                data: {
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                    datasets: [
                        {
                            label: 'Listing A',
                            data: [65, 59, 80, 81, 56, 55, 40],
                            borderColor: 'rgba(255, 206, 86, 1)',
                            backgroundColor: 'rgba(255, 206, 86, 0.2)',
                            fill: true,
                        },
                        {
                            label: 'Listing B',
                            data: [28, 48, 40, 19, 86, 27, 90],
                            borderColor: 'rgba(255, 99, 132, 1)',
                            backgroundColor: 'rgba(255, 99, 132, 0.2)',
                            fill: true,
                        },
                        {
                            label: 'Listing C',
                            data: [18, 48, 77, 9, 100, 27, 40],
                            borderColor: 'rgba(75, 192, 192, 1)',
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            fill: true,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true,
                        },
                    },
                },
            });
        },
        toggleNavMenu() {
            this.navMenuVisible = !this.navMenuVisible;
        },
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
    },
    created() {
        // Check authentication state on component creation
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.userLoggedIn = true;
                this.fetchUserProfile(user.email);
            } else {
                this.userLoggedIn = false;
                this.userName = 'Guest';
                this.userEmail = '';
            }
        });
        const savedLanguage = localStorage.getItem('userLanguage');
        if (savedLanguage) {
            this.selectedLanguage = savedLanguage;
            this.$i18n.locale = savedLanguage;
        }
    },
});
</script>

<style scoped>
.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
    background-color: white;
    /* Set background color to white */
    color: #000000;
    /* Set icon color */
    /* padding: 5px; */
    /* Adjust padding to ensure icon is centered */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the icon */
    width: 50px;
    /* Set a fixed width for the button */
    height: 50px;
    /* Set a fixed height for the button */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.input-with-icon select.form-control {
    padding-bottom: 1px;
    /* Adjust the value as needed */
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
}

.add-listing a {
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

.d-flex {
    flex-wrap: nowrap;
}

.bg-danger {
    background-color: #d9534f;
    color: white;
}

a img {
    vertical-align: middle;
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
}

#main-wrapper {
    padding-top: 70px;
}

.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left: 40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 150px;
    margin-left: 50px;
}
</style>
