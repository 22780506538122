<template>
    <div :key="$i18n.locale" class="yellow-skin">

        <div id="main-wrapper">
            <div class="header header-light">
                <div class="container">
                    <nav id="navigation" class="navigation navigation-landscape">
                        <div class="nav-header">
                            <a class="nav-brand" href="/">
                                <img :src="globalHomeLogo" class="logo" alt="Global Home" />
                            </a>
                            <div class="nav-toggle"></div>
                            <div class="mobile_nav">
                                <ul>
                                    <li class="add-listing">
                                        <a v-if="!userLoggedIn" href="submit-property"
                                            class="bg-danger d-flex align-items-center" data-bs-toggle="modal"
                                            data-bs-target="#login">
                                            <img :src="loginLogo" class="logo" alt="Global Home Sell Property"
                                                style="width: 20px; height: 20px; margin-right: 8px;" />
                                            Login
                                        </a>
                                        <a v-else @click.prevent="handleLogout" href="#"
                                            class="bg-danger d-flex align-items-center">
                                            <img :src="loginLogo" class="logo" alt="Global Home Logout"
                                                style="width: 20px; height: 20px; margin-right: 8px;" />
                                            Logout
                                        </a>
                                    </li>
                                    <li class="add-listing">
                                        <a href="#scan-wechat" class="d-flex align-items-center" data-bs-toggle="modal"
                                            data-bs-target="#scan-wechat">
                                            <img :src="wechatLogo" class="logo" alt="Global Home Wechat"
                                                style="width: 20px; height: 20px; margin-right: 8px;">
                                            Wechat
                                        </a>
                                    </li>
                                    <li class="add-listing">
                                        <a href="https://wa.link/fw20eo" class="d-flex align-items-center">
                                            <img :src="whatsappLogo" class="logo" alt="Global Home Whatsapp"
                                                style="width: 20px; height: 20px; margin-right: 8px;">
                                            Whatsapp
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="nav-menus-wrapper" style="transition-property: none;">
                            <ul class="nav-menu">
                                <li @click="toggleSubmenu('country')">
                                    <a href="#">Country<span class="submenu-indicator"></span></a>
                                    <ul v-if="activeSubmenu === 'country'" class="nav-dropdown nav-submenu">
                                        <li><router-link to="/property-listing?location=Greece">Greece</router-link></li>
                                        <li><router-link to="/property-listing?location=Malaysia">Malaysia</router-link></li>
                                    </ul>
                                </li>
                                <li @click="toggleSubmenu('residential')">
                                    <a href="#">Residential<span class="submenu-indicator"></span></a>
                                    <ul v-if="activeSubmenu === 'residential'" class="nav-dropdown nav-submenu">
                                        <li><a href="listing">Buy New Property</a></li>
                                        <li><a href="listing">Buy Second-Hand Property</a></li>
                                        <li><a href="listing">Rent Property</a></li>
                                    </ul>
                                </li>
                                <li @click="toggleSubmenu('commercial')">
                                    <a href="#">Commercial<span class="submenu-indicator"></span></a>
                                    <ul v-if="activeSubmenu === 'commercial'" class="nav-dropdown nav-submenu">
                                        <li><a href="listing">Buy New Property</a></li>
                                        <li><a href="listing">Buy Second-Hand Property</a></li>
                                        <li><a href="listing">Rent Property</a></li>
                                    </ul>
                                </li>
                                <li @click="toggleSubmenu('land')">
                                    <a href="#">Land<span class="submenu-indicator"></span></a>
                                    <ul v-if="activeSubmenu === 'land'" class="nav-dropdown nav-submenu">
                                        <li><a href="listing">Buy New Property</a></li>
                                        <li><a href="listing">Buy Second-Hand Property</a></li>
                                        <li><a href="listing">Rent Property</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="not-found">Study Overseas</a>
                                </li>
                                <li>
                                    <a href="global-visa">Global Visa</a>
                                </li>
                                <li>
                                    <a href="not-found">Join us</a>
                                </li>
                            </ul>
                            <ul class="nav-menu nav-menu-social align-to-right">
                                <li class="nav-item dropdown">
                                    <a href="#" role="button" data-bs-toggle="dropdown">
                                        <img :src="settingsLogo" class="logo" alt="Global Home Settings"
                                            style="width: 30px; height: 30px; margin-right: 8px;">
                                    </a>
                                    <div class="dropdown-menu p-3" aria-labelledby="settingsDropdown">
                                        <div class="mb-2">
                                            <label for="languageSelect" class="form-label">Language</label>
                                            <select class="form-select" id="languageSelect">
                                                <option value="en">English</option>
                                                <option value="zh">中文 (简体)</option>
                                            </select>
                                        </div>
                                        <div class="mb-2">
                                            <label for="currencySelect" class="form-label">Currency</label>
                                            <select class="form-select" id="currencySelect">
                                                <option value="usd">USD</option>
                                                <option value="eur">EUR</option>
                                            </select>
                                        </div>
                                        <div class="mb-2">
                                            <label for="measurementSelect" class="form-label">Measurement</label>
                                            <select class="form-select" id="measurementSelect">
                                                <option value="m2">m²</option>
                                                <option value="ft2">ft²</option>
                                            </select>
                                        </div>
                                        <button class="btn btn-dark w-100">Confirm</button>
                                    </div>
                                </li>
                                <li class="add-listing">
                                    <a v-if="!userLoggedIn" href="submit-property"
                                        class="bg-danger d-flex align-items-center" data-bs-toggle="modal"
                                        data-bs-target="#login">
                                        <img :src="loginLogo" class="logo" alt="Global Home Sell Property"
                                            style="width: 20px; height: 20px; margin-right: 8px;" />
                                        Login
                                    </a>
                                    <a v-else @click.prevent="handleLogout" href="#"
                                        class="bg-danger d-flex align-items-center">
                                        <img :src="loginLogo" class="logo" alt="Global Home Logout"
                                            style="width: 20px; height: 20px; margin-right: 8px;" />
                                        Logout
                                    </a>
                                </li>
                                <li class="add-listing">
                                    <a href="#scan-wechat" class="bg-danger d-flex align-items-center"
                                        data-bs-toggle="modal" data-bs-target="#scan-wechat">
                                        <img :src="wechatLogo" class="logo" alt="Global Home Wechat"
                                            style="width: 20px; height: 20px; margin-right: 8px;">
                                        Wechat
                                    </a>
                                </li>
                                <li class="add-listing">
                                    <a href="https://wa.link/fw20eo" class="bg-danger d-flex align-items-center">
                                        <img :src="whatsappLogo" class="logo" alt="Global Home Whatsapp"
                                            style="width: 20px; height: 20px; margin-right: 8px;">
                                        Whatsapp
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <div class="clearfix"></div>
            <!-- ============================ Page Title Start================================== -->
            <section class="image-cover faq-sec text-center"
                :style="{ backgroundImage: `url(${require('@/assets/img/bg.jpg')})` }" data-overlay="6">
                <div class="container">
                    <div class="row">

                        <div class="col-lg-12 col-md-12 position-relative z-1">
                            <h1 class="text-light">Citizenship by Investment</h1>
                        </div>

                    </div>
                </div>
            </section>
            <!-- ============================ Page Title End ================================== -->
            <!-- ============================ Top Agents Start ================================== -->

            <!-- Conditional Rendering of Carousel -->
            <section class="gray-simple min" v-if="isCarouselReady">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-7 col-md-8">
                            <div class="sec-heading center">
                                <h2>List of Countries</h2>
                                <p>The Citizenship by Investment Programme offers foreign individuals and their families
                                    the opportunity to obtain citizenship through a certificate of naturalization by
                                    direct investment.</p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <carousel class="slider-style-2" :items-to-show="1" :wrap-around="true"
                                :breakpoints="breakpoints">
                                <template #addons>
                                    <div class="carousel-navigation">
                                        <navigation />
                                    </div>
                                </template>
                                <slide v-for="(agent, index) in agents" :key="index">
                                    <div class="single-slide">
                                        <div class="inner">
                                            <div class="thumbnail">
                                                <router-link :to="agent.pageLink">
                                                    <img :src="agent.image" alt="Agent Portfolio"
                                                        class="img-fluid mx-auto">
                                                </router-link>
                                            </div>
                                            <div class="banner-read-thumb text-start">
                                                <h4>
                                                    <router-link :to="agent.pageLink">
                                                        {{ agent.name }}
                                                    </router-link>
                                                </h4>
                                                <span>{{ agent.location }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </slide>
                            </carousel>
                        </div>
                    </div>
                </div>
            </section>
            <!-- ============================ Top Agents End ================================== -->

            <!-- ============================ Call To Action ================================== -->
            <section class="bg-danger call_action_wrap-wrap">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="call_action_wrap">
                                <div class="call_action_wrap-head">
                                    <h3>Do You Have Questions ?</h3>
                                    <span>We'll help you to find your next home.</span>
                                </div>
                                <a href="#" class="btn btn-call_action_wrap">Contact Us Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- ============================ Call To Action End ================================== -->

            <!-- ============================ Footer Start ================================== -->
            <footer class="dark-footer skin-dark-footer style-2">
                <div class="footer-middle">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-5 col-md-5">
                                <div class="footer_widget">
                                    <img :src="globalHomeLogo" class="logo img-footer small mb-4" alt="Global Home" />
                                    <h4 class="extream mb-3">Do you need help with<br>anything?</h4>
                                    <p>Email: globalahome@gmail.com Hotline: +1 929-578-6566</p>
                                    <p>Offices: Singapore</p>
                                    <div class="foot-news-last mt-4">
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="Email Address">
                                            <div class="input-group-append">
                                                <button type="button"
                                                    class="btn btn-danger b-0 text-light">Subscribe</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-7 ms-auto">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <div class="footer_widget">
                                            <h4 class="widget_title">Services</h4>
                                            <ul class="footer-menu">
                                                <li><a href="#">Buy Property</a></li>
                                                <li><a href="#">Sell Property</a></li>
                                                <li><a href="#">Property for Rent</a></li>
                                                <li><a href="#">Global Partners</a></li>
                                                <li><a href="#">Study Overseas</a></li>
                                                <li><a href="global-visa">Global Visa</a></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-4">
                                        <div class="footer_widget">
                                            <h4 class="widget_title">Countries</h4>
                                            <ul class="footer-menu">
                                                <li><a href="#">Greece</a></li>
                                                <li><a href="#">Singapore</a></li>
                                                <li><a href="#">Malaysia</a></li>
                                                <li><a href="#">Indonesia</a></li>
                                                <li><a href="#">Japan</a></li>
                                                <li><a href="#">Germany</a></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-4">
                                        <div class="footer_widget">
                                            <h4 class="widget_title">Company</h4>
                                            <ul class="footer-menu">
                                                <li><a href="#">About</a></li>
                                                <li><a href="#">Services</a></li>
                                                <li><a href="#">Property Listings</a></li>
                                                <li><a href="#">Blog or News</a></li>
                                                <li><a href="#">Our team</a></li>
                                                <li><a href="#">Contact us</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="footer-bottom">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-12 col-md-12 text-center">
                                <p class="mb-0">© 2024 Global A Home Real Estate. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <!-- ============================ Footer End ================================== -->

            <!-- Scan QR code Modal-->
            <div class="modal fade" id="scan-wechat" tabindex="-1" role="dialog" aria-labelledby="authomessage"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered login-pop-form" role="document">
                    <div class="modal-content" id="scan-wechat">
                        <span class="mod-close" data-bs-dismiss="modal" aria-hidden="true"><i
                                class="ti-close"></i></span>
                        <div class="modal-body">
                            <h4 class="modal-header-title">Scan QR to Connect</h4>
                            <div class="qr-code-container" style="text-align: center;">
                                <img :src="qrCodeLogo" class="logo" alt="Global Home Wechat QR"
                                    style="max-width: 100%; height: auto;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Scan QR code Modal -->
            <!-- Log In Modal -->
            <div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="registermodal"
                aria-hidden="true">
                <div class="modal-dialog modal-xl login-pop-form" role="document">
                    <div class="modal-content overli" id="registermodal">
                        <div class="modal-body p-0">
                            <div class="resp_log_wrap">
                                <div class="resp_log_thumb"
                                    :style="{ backgroundImage: `url(${require('@/assets/img/global_home.png')})` }">
                                    <h6 class="tagline">Your Dream Home, Anywhere in the World.</h6>
                                </div>
                                <div class="resp_log_caption">
                                    <span class="mod-close" data-bs-dismiss="modal" aria-hidden="true" ref="closeBtn"><i
                                            class="ti-close"></i></span>
                                    <div class="edlio_152">
                                        <ul class="nav nav-pills tabs_system center" id="pills-tab" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" id="pills-login-tab" data-bs-toggle="pill"
                                                    href="#pills-login" role="tab" aria-controls="pills-login"
                                                    aria-selected="true"><i
                                                        class="fas fa-sign-in-alt me-2"></i>Login</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="pills-signup-tab" data-bs-toggle="pill"
                                                    href="#pills-signup" role="tab" aria-controls="pills-signup"
                                                    aria-selected="false"><i
                                                        class="fas fa-user-plus me-2"></i>Register</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="tab-content" id="pills-tabContent">
                                        <div class="tab-pane fade show active" id="pills-login" role="tabpanel"
                                            aria-labelledby="pills-login-tab">
                                            <div class="login-form">
                                                <form @submit.prevent="handleLogin">
                                                    <div v-if="loginError" class="alert alert-danger">
                                                        {{ loginError }}
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Username</label>
                                                        <div class="input-with-icon">
                                                            <input type="email" class="form-control"
                                                                v-model="loginEmail" required>
                                                            <i class="ti-user"></i>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <label>Password</label>
                                                        <div class="input-with-icon">
                                                            <input type="password" class="form-control"
                                                                v-model="loginPassword" required>
                                                            <i class="ti-unlock"></i>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <div class="eltio_ol9">
                                                            <div class="eltio_k1">
                                                                <input id="dd" class="form-check-input" name="dd"
                                                                    type="checkbox">
                                                                <label for="dd" class="form-check-label"> Remember
                                                                    Me</label>
                                                            </div>
                                                            <div class="eltio_k2">
                                                                <a href="#">Lost Your Password?</a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <button type="submit"
                                                            class="btn btn-danger fw-medium full-width">Login
                                                        </button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="pills-signup" role="tabpanel"
                                            aria-labelledby="pills-signup-tab">
                                            <div class="login-form">
                                                <form @submit.prevent="handleRegister">

                                                    <div class="form-group">
                                                        <label>Full Name</label>
                                                        <div class="input-with-icon">
                                                            <input type="text" class="form-control" v-model="fullName"
                                                                required>
                                                            <i class="ti-user"></i>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <label>Email</label>
                                                        <div class="input-with-icon">
                                                            <input type="email" class="form-control"
                                                                v-model="registerEmail" required>
                                                            <i class="ti-user"></i>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <label>Phone Number</label>
                                                        <div class="input-with-icon">
                                                            <input type="tel" class="form-control" v-model="phoneNumber"
                                                                required placeholder="Enter your phone number">>
                                                            <i class="ti-mobile"></i>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <label>Password</label>
                                                        <div class="input-with-icon">
                                                            <input type="password" class="form-control"
                                                                v-model="registerPassword" required>
                                                            <i class="ti-unlock"></i>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <div class="eltio_ol9">
                                                            <div class="eltio_k1">
                                                                <input id="dds" class="form-check-input" name="dds"
                                                                    type="checkbox">
                                                                <label for="dds" class="form-check-label">By using the
                                                                    website, you accept the terms and conditions</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <button type="submit"
                                                            class="btn btn-danger fw-medium full-width">Register
                                                        </button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Modal -->
        </div>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import { auth } from '../../firebase'; // Import the auth object
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
} from 'firebase/auth';

import 'magnific-popup';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import sampleImage1 from '@/assets/img/p-4.webp';


export default {
    name: 'GlobalVisa',
    components: {
        Carousel, Slide, Navigation,
    },
    props: ['glb_id'],
    data() {
        return {
            loginError: '', 
            globalHomeLogo,
            whatsappLogo,
            wechatLogo,
            loginLogo,
            settingsLogo,
            qrCodeLogo,
            sampleImage1,
            activeSubmenu: null,
            userLoggedIn: false,
            breakpoints: {
                576: {
                    itemsToShow: 2,
                    snapAlign: 'left'
                },
                768: {
                    itemsToShow: 2,
                    snapAlign: 'left'
                },
                992: {
                    itemsToShow: 3,
                    snapAlign: 'left'
                },
                1200: {
                    itemsToShow: 3,
                    snapAlign: 'left'
                },
                1399: {
                    itemsToShow: 3,
                    snapAlign: 'left'
                },
            },
            agents: [
                {
                    id: 1,
                    location: "Montreal, USA",
                    name: "Adam K. Jollio",
                    image: "assets/img/team-1.webp",
                    pageLink: "agent-page.html",
                },
                {
                    id: 2,
                    location: "Liverpool, Canada",
                    name: "Sargam S. Singh",
                    image: "assets/img/team-2.webp",
                    pageLink: "agent-page.html",
                },
            ],
            isCarouselReady: false,
        };
    },
    methods: {
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
        async handleLogin() {
            try {
                const userCredential = await signInWithEmailAndPassword(
                    auth,
                    this.loginEmail,
                    this.loginPassword
                );
                // Handle successful login
                console.log('User logged in:', userCredential.user);
                this.userLoggedIn = true;
				this.loginError = '';
                this.$refs.closeBtn.click();
            } catch (error) {
                console.error('Error logging in:', error.message);
				this.loginError = 'Invalid username or password. Please try again.';
            }
        },
        async handleRegister() {
            try {
                const userCredential = await createUserWithEmailAndPassword(
                    auth,
                    this.registerEmail,
                    this.registerPassword
                );
                // Handle successful registration
                console.log('User registered:', userCredential.user);
                this.userLoggedIn = true; // Set login state to true
                this.$refs.closeBtn.click();
            } catch (error) {
                console.error('Error registering:', error.message);
                // Handle errors (e.g., show an error message)
            }
        },
        async handleLogout() {
            try {
                await signOut(auth);
                this.userLoggedIn = false; // Set login state to false
                console.log('User logged out');
            } catch (error) {
                console.error('Error logging out:', error.message);
            }
        },

    },
    created() {
        // Check authentication state on component creation
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.userLoggedIn = true; // User is signed in
            } else {
                this.userLoggedIn = false; // User is signed out
            }
        });
    },
    mounted() {
        // Delay the initialization to ensure DOM elements are ready
        this.isCarouselReady = true;
    }
};
</script>

<style scoped>
.verify {
    width: 20px;
    height: 20px;
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
}

.add-listing a {
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

.d-flex {
    flex-wrap: nowrap;
}

.bg-danger {
    background-color: #d9534f;
    color: white;
}

a img {
    vertical-align: middle;
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
}

#main-wrapper {
    padding-top: 70px;
}

.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.resp_log_thumb {
    margin-top: 160px;
    width: 80px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 150px;
    margin-left: 50px;
}

.carousel-navigation {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.carousel-navigation .navigation {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px;
    color: white;
    cursor: pointer;
}

.slider-style-2 .slide {
    padding: 20px;
}

.slider-style-2 .thumbnail img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}
</style>
