import { createRouter, createWebHistory } from "vue-router";
import Home from "@/components/Home.vue";
import PropertyListing from "@/components/PropertyListing.vue";
import PropertyDetail from "@/components/PropertyDetail.vue";
import GlobalVisa from "@/components/GlobalVisa.vue";
import Carousel from "@/components/Carousel.vue";
import NotFound from "@/components/NotFound.vue";
import CitizenshipByInvestment from "@/components/CitizenshipByInvestment.vue";
import SubmitPropertyForm from "@/components/SubmitPropertyForm.vue";
import PropertySubmission from "@/components/PropertySubmission.vue";
import UserDashboard from "@/components/UserDashboard.vue";
import UserProfile from "@/components/UserProfile.vue";
import UserProperty from "@/components/UserProperty.vue";
import UserSubscription from "@/components/UserSubscription.vue";
// import UserSubmitProperty from "@/components/UserSubmitProperty.vue";
import UserChangePassword from "@/components/UserChangePassword.vue";
import ContactUs from "@/components/ContactUs.vue";
import AboutUs from "@/components/AboutUs.vue";
import OurServices from "@/components/OurServices.vue";
import CompareProperty from "@/components/CompareProperty.vue";
import AddBlogPost from "@/components/AddBlogPost.vue";
import WhatsappVerify from "@/components/WhatsappVerify.vue";
import ListingRenovation from "@/components/ListingRenovation.vue";
import RenovationDetails from "@/components/RenovationDetails.vue";
import HomeServicesListing from "@/components/HomeServicesListing.vue";
import { useListingStore } from '@/stores/listingStore';
import { useListingMalaysiaStore } from "@/stores/listingMalaysiaStore";
import { useListingSingaporeStore } from "@/stores/listingSingaporeStore";
import { useGreeceListingStore } from "@/stores/greeceListingStore";
import { useMalaysiaListingStore } from "@/stores/malaysiaListingStore";
import { useMalaysiaRentStore } from "@/stores/malaysiaRentStore";
import { useMalaysiaSubsaleStore } from "@/stores/malaysiaSubsaleStore";
import { useSingaporeSubsaleStore } from "@/stores/singaporeSubsaleStore";
import { useSingaporeListingStore } from "@/stores/singaporeListingStore";
import { useSingaporeRentStore } from "@/stores/singaporeRentStore";
import ListingGreeceDemo from "@/components/ListingGreeceDemo.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/renovation-details",
    name: "RenovationDetails",
    component: RenovationDetails,
  },
  {
    path: "/renovation",
    name: "ListingRenovation",
    component: ListingRenovation,
  },
  {
    path: "/globalahome-services",
    name: "OurServices",
    component: OurServices,
  },
  {
    path: "/whatsapp-verify",
    name: "WhatsappVerify",
    component: WhatsappVerify,
  },
  {
    path: "/compare-property",
    name: "CompareProperty",
    component: CompareProperty,
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/add-blog-post-story",
    name: "AddBlogPost",
    component: AddBlogPost,
  },
  {
    path: "/home-services",
    name: "HomeServicesListing",
    component: HomeServicesListing,
  },
  {
    path: "/demo-carousel",
    name: "ListingGreeceDemo",
    component: ListingGreeceDemo,
  },
  {
    path: '/greece-listing',
    name: 'ListingGreece',
    component: () => import('@/components/ListingGreece.vue'),
    beforeEnter: async (to, from, next) => {
      const listingStore = useListingStore();
      await listingStore.fetchListings();
      next();
    },
  },
  {
    path: '/malaysia-listing',
    name: 'ListingMalaysia',
    component: () => import('@/components/ListingMalaysia.vue'),
    beforeEnter: async (to, from, next) => {
      const listingMalaysiaStore = useListingMalaysiaStore();
      await listingMalaysiaStore.fetchListings();
      next();
    },
  },
  {
    path: '/singapore-listing',
    name: 'ListingSingapore',
    component: () => import('@/components/ListingSingapore.vue'),
    beforeEnter: async (to, from, next) => {
      const listingSingaporeStore = useListingSingaporeStore();
      await listingSingaporeStore.fetchListings();
      next();
    },
  },
  {
    path: "/property-listing",
    name: "PropertyListing",
    component: PropertyListing,
  },
  {
    path: "/submit-property-form",
    name: "PropertySubmission",
    component: PropertySubmission,
  },
  {
    path: "/property-detail/:glb_id",
    name: "PropertyDetail",
    component: PropertyDetail,
    props: true, 
  },
  {
    path: "/malaysia-property-detail/:glb_id",
    name: "MalaysiaListing",
    component: () => import('@/components/MalaysiaListing.vue'), // Dynamic import
    props: true, // Enables passing route params as props to the component
    beforeEnter: async (to, from, next) => {
        const malaysiaListingStore = useMalaysiaListingStore(); // Initialize the store
        await malaysiaListingStore.fetchProperty(to.params.glb_id); // Fetch property based on `glb_id`
        next(); // Proceed to the component
    },
},
{
  path: "/malaysia-rent-property/:glb_id",
  name: "MalaysiaRentListing",
  component: () => import('@/components/MalaysiaRentListing.vue'), // Dynamic import
  props: true, // Pass route params as props
  beforeEnter: async (to, from, next) => {
      const malaysiaRentStore = useMalaysiaRentStore(); // Initialize the store
      await malaysiaRentStore.fetchProperty(to.params.glb_id); // Fetch property based on `glb_id`
      next(); // Proceed to the component
  },
},
{
  path: "/malaysia-subsale-property/:glb_id",
  name: "MalaysiaSubsaleListing",
  component: () => import('@/components/MalaysiaSubsaleListing.vue'), // Dynamic import
  props: true, // Pass route params as props
  beforeEnter: async (to, from, next) => {
    const malaysiaSubsaleStore = useMalaysiaSubsaleStore(); // Initialize the store
    await malaysiaSubsaleStore.fetchProperty(to.params.glb_id); // Fetch property based on `glb_id`
    next(); // Proceed to the component
  },
},
{
  path: "/greece-property-detail/:glb_id",
  name: "GreeceListing",
  component: () => import('@/components/GreeceListing.vue'), // Import the component dynamically
  props: true, // Enables passing route params as props to the component
  beforeEnter: async (to, from, next) => {
      const greeceListingStore = useGreeceListingStore();
      await greeceListingStore.fetchProperty(to.params.glb_id); // Fetch property based on `glb_id`
      next(); // Continue to the component
  },
},
{
  path: "/singapore-property-detail/:glb_id",
  name: "SingaporeListing",
  component: () => import('@/components/SingaporeListing.vue'), // Dynamic import
  props: true, // Enables passing route params as props to the component
  beforeEnter: async (to, from, next) => {
      const singaporeListingStore = useSingaporeListingStore();
      await singaporeListingStore.fetchProperty(to.params.glb_id); // Fetch property based on `glb_id`
      next(); // Proceed to the component
  },
},
{
  path: "/singapore-rent-detail/:glb_id",
  name: "SingaporeRentListing",
  component: () => import('@/components/SingaporeRentListing.vue'), // Dynamic import
  props: true, // Enables passing route params as props to the component
  beforeEnter: async (to, from, next) => {
      const singaporeRentStore = useSingaporeRentStore();
      await singaporeRentStore.fetchProperty(to.params.glb_id); // Fetch property based on `glb_id`
      next(); // Proceed to the component
  },
},
  {
    path: "/singapore-subsale-property/:glb_id",
    name: "SingaporeSubsaleListing",
    component: () => import('@/components/SingaporeSubsaleListing.vue'), // Dynamic import for consistency
    props: true, // Pass route params as props
    beforeEnter: async (to, from, next) => {
        const singaporeSubsaleStore = useSingaporeSubsaleStore(); // Initialize the store
        await singaporeSubsaleStore.fetchProperty(to.params.glb_id); // Fetch property based on `glb_id`
        next(); // Proceed to the component
    },
},
  {
    path: "/property-submission",
    name: "SubmitPropertyForm",
    component: SubmitPropertyForm,
    props: true, 
  },
  {
    path: "/global-visa",
    name: "GlobalVisa",
    component: GlobalVisa,
  },
  {
    path: "/carousel",
    name: "Carousel",
    component: Carousel,
  },
  {
    path: "/citizenship-by-investment",
    name: "CitizenshipByInvestment",
    component: CitizenshipByInvestment,
  },
  {
    path: "/user-property",
    name: "UserProperty",
    component: UserProperty,
  },
  {
    path: "/user-dashboard",
    name: "UserDashboard",
    component: UserDashboard,
  },
  // {
  //   path: "/user-submit-property",
  //   name: "UserSubmitProperty",
  //   component: UserSubmitProperty,
  // },
  {
    path: "/user-change-password",
    name: "UserChangePassword",
    component: UserChangePassword,
  },
  {
    path: "/user-subscription",
    name: "UserSubscription",
    component: UserSubscription,
  },
  {
    path: "/user-saved-property",
    name: "UserSavedProperty",
    component: () => import('@/components/UserSavedProperty.vue'), // Dynamic component import
    beforeEnter: async (to, from, next) => {
        const { useUserSavedPropertyStore } = await import('@/stores/userSavedPropertyStore'); // Dynamic store import
        const userSavedPropertyStore = useUserSavedPropertyStore(); // Initialize the store
        await userSavedPropertyStore.fetchSavedProperties(to.params.userEmail); // Fetch user's saved properties
        next(); // Proceed to the component
    },
},
  {
    path: "/user-profile",
    name: "UserProfile",
    component: UserProfile,
  },
  {
    path: "/not-found",
    name: "NotFound",
    component: NotFound,
  },
  // Catch-all route for undefined paths
  {
    path: "/:catchAll(.*)", // This matches any route that is not defined above
    name: "NotFound",
    component: NotFound,
  },
  // Add more routes as needed
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
