<template>
    <div :key="$i18n.locale" class="yellow-skin">
        <div id="main-wrapper">
            <!-- Start Navigation -->
            <Navigation />
            <!-- ============================ End Navigation ================================== -->
            <div class="clearfix"></div>
            <!-- ============================ Page Title Start================================== -->
            <div class="page-title" :style="{ background: `#f4f4f4 url(${bgImage})` }" data-overlay="5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">

                            <div class="breadcrumbs-wrap position-relative z-1">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item active" aria-current="page">{{ $t('aboutUs.aboutUs') }}</li>
                                </ol>
                                <h2 class="breadcrumb-title">{{ $t('aboutUs.whoWeAre') }}</h2>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- ============================ Page Title End ================================== -->

            <!-- ============================ Our Story Start ================================== -->
            <section>

                <div class="container">

                    <!-- row Start -->
                    <div class="row align-items-center">

                        <div class="col-lg-6 col-md-6">
                            <div class="story-wrap explore-content">

                                <h2 class="mb-3 fw-bold">{{ $t('aboutUs.companyOverview') }}</h2>
                                <span class="text-muted fs-5">{{ $t('aboutUs.visionAndMission') }}</span>
                                <p class="mt-4">{{ $t('aboutUs.companyDescription') }}</p>
                                <p class="mb-3">{{ $t('aboutUs.globalPerspective') }}</p>
                                <a href="#" class="btn btn-danger">{{ $t('aboutUs.moreAboutServices') }}</a>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <img :src="companyOverview" class="img-fluid rounded" alt="" />
                        </div>

                    </div>
                    <!-- /row -->

                </div>

            </section>
            <!-- ============================ Our Story End ================================== -->

            <!-- ============================ Our Counter Start ================================== -->
            <section class="image-cover" style="background:#a70a29 url(assets/img/pattern.png) no-repeat;">
                <div class="container">

                    <div class="row justify-content-center">
                        <div class="col-xl-7 col-lg-10 col-md-12 col-sm-12">
                            <div class="text-center mb-5">
                                <span class="text-light">{{ $t('aboutUs.specialAboutUs') }}</span>
                                <h2 class="font-weight-normal text-light">{{ $t('aboutUs.scalableApplication') }}</h2>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="_morder_counter">
                                <div class="_morder_counter_thumb"><i class="ti-cup"></i></div>
                                <div class="_morder_counter_caption">
                                    <!-- <h5 class="text-light"><span>32</span> M</h5> -->
                                    <h5 class="text-light">{{ $t('aboutUs.propertyListings') }}</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="_morder_counter">
                                <div class="_morder_counter_thumb"><i class="ti-briefcase"></i></div>
                                <div class="_morder_counter_caption">
                                    <!-- <h6 class="text-light"><span>42</span> M</h6> -->
                                    <h5 class="text-light">{{ $t('aboutUs.homeServices') }}</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="_morder_counter">
                                <div class="_morder_counter_thumb"><i class="ti-light-bulb"></i></div>
                                <div class="_morder_counter_caption">
                                    <!-- <h6 class="text-light"><span>42</span> M</h6> -->
                                    <h5 class="text-light">{{ $t('aboutUs.userDashboard') }}</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="_morder_counter">
                                <div class="_morder_counter_thumb"><i class="ti-heart"></i></div>
                                <div class="_morder_counter_caption">
                                    <!-- <h6 class="text-light"><span>42</span> M</h6> -->
                                    <h5 class="text-light">{{ $t('aboutUs.customizedServices') }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <!-- ============================ Our Counter End ================================== -->

            <!-- ================= Our Team================= -->
            <section>
                <div class="container">

                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="sec-heading center">
                                <h2>{{ $t('aboutUs.meetOurTeam') }}</h2>
                                <p>{{ $t('aboutUs.professionalAndDedicatedTeam') }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">

                            <div class="team-slide item-slide">

                                <!-- Single Teamm -->
                                <div class="single-team">
                                    <div class="team-grid">

                                        <div class="teamgrid-user">
                                            <img src="assets/img/user-1.webp" alt="" class="img-fluid" />
                                        </div>

                                        <div class="teamgrid-content">
                                            <h4>Michael Meng</h4>
                                            <span>{{ $t('aboutUs.ceo') }}</span>
                                        </div>

                                        <div class="teamgrid-social">
                                            <ul>
                                                <li><a href="#" class="f-cl"><i class="ti-facebook"></i></a></li>
                                                <li><a href="#" class="t-cl"><i class="ti-twitter"></i></a></li>
                                                <li><a href="#" class="i-cl"><i class="ti-instagram"></i></a></li>
                                                <li><a href="#" class="l-cl"><i class="ti-linkedin"></i></a></li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>

                                <!-- Single Teamm -->
                                <div class="single-team">
                                    <div class="team-grid">

                                        <div class="teamgrid-user">
                                            <img src="assets/img/user-1.webp" alt="" class="img-fluid" />
                                        </div>

                                        <div class="teamgrid-content">
                                            <h4>Reca Seng</h4>
                                            <span>{{ $t('aboutUs.cto') }}</span>
                                        </div>

                                        <div class="teamgrid-social">
                                            <ul>
                                                <li><a href="#" class="f-cl"><i class="ti-facebook"></i></a></li>
                                                <li><a href="#" class="t-cl"><i class="ti-twitter"></i></a></li>
                                                <li><a href="#" class="i-cl"><i class="ti-instagram"></i></a></li>
                                                <li><a href="#" class="l-cl"><i class="ti-linkedin"></i></a></li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>

                                <!-- Single Teamm -->
                                <div class="single-team">
                                    <div class="team-grid">

                                        <div class="teamgrid-user">
                                            <img src="assets/img/user-2.webp" alt="" class="img-fluid" />
                                        </div>

                                        <div class="teamgrid-content">
                                            <h4>Nina</h4>
                                            <span>{{ $t('aboutUs.greecePropertyDirector') }}</span>
                                        </div>

                                        <div class="teamgrid-social">
                                            <ul>
                                                <li><a href="#" class="f-cl"><i class="ti-facebook"></i></a></li>
                                                <li><a href="#" class="t-cl"><i class="ti-twitter"></i></a></li>
                                                <li><a href="#" class="i-cl"><i class="ti-instagram"></i></a></li>
                                                <li><a href="#" class="l-cl"><i class="ti-linkedin"></i></a></li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>

                                <!-- Single Teamm -->
                                <div class="single-team">
                                    <div class="team-grid">

                                        <div class="teamgrid-user">
                                            <img src="assets/img/user-3.webp" alt="" class="img-fluid" />
                                        </div>

                                        <div class="teamgrid-content">
                                            <h4>Ng Jia Jin</h4>
                                            <span>{{ $t('aboutUs.graphicDesigner') }}</span>
                                        </div>

                                        <div class="teamgrid-social">
                                            <ul>
                                                <li><a href="#" class="f-cl"><i class="ti-facebook"></i></a></li>
                                                <li><a href="#" class="t-cl"><i class="ti-twitter"></i></a></li>
                                                <li><a href="#" class="i-cl"><i class="ti-instagram"></i></a></li>
                                                <li><a href="#" class="l-cl"><i class="ti-linkedin"></i></a></li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <!-- =============================== Our Team ================================== -->

            <!-- ============================ Agency List Start ================================== -->
            <section class="gray">

                <div class="container">

                    <!-- row Start -->
                    <div class="row align-items-center">

                        <div class="col-lg-6 col-md-6">
                            <div class="story-wrap explore-content">

                                <h2 class="mb-3 fw-bold">{{ $t('aboutUs.professionalAdvantages') }}</h2>
                                <span class="text fs-4">{{ $t('aboutUs.globalNetwork') }}</span>
                                <p class="mt-4">{{ $t('aboutUs.globalNetworkDescription') }}</p>

                                <span class="text fs-4">{{ $t('aboutUs.professionalTeam') }}</span>
                                <p class="mt-4">{{ $t('aboutUs.professionalTeamDescription') }}</p>

                                <span class="text fs-4">{{ $t('aboutUs.customizedService') }}</span>
                                <p class="mt-4">{{ $t('aboutUs.customizedServiceDescription') }}</p>

                                <span class="text fs-4">{{ $t('aboutUs.oneStopService') }}</span>
                                <p class="mt-4">{{ $t('aboutUs.oneStopServiceDescription') }}.</p>

                                <span class="text fs-4">{{ $t('aboutUs.lookingToTheFuture') }}</span>
                                <p class="mt-4">{{ $t('aboutUs.lookingToTheFutureDescription') }}</p>
                                <a href="#" class="btn btn-danger mt-5">{{ $t('aboutUs.aboutGlobalahome') }}</a>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-6">
                            <img :src="globalTrading" class="img-fluid rounded ms-3" alt="" />
                        </div>

                    </div>
                    <!-- /row -->

                </div>

            </section>
            <!-- ============================ Agency List End ================================== -->
            <!-- ============================ Property Tag Start ================================== -->
            <section class="image-cover" style="background:#122947 url(assets/img/slider-2.jpg) no-repeat;"
                data-overlay="2">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12">

                            <div class="tab_exclusive position-relative z-1">
                                <h2>{{ $t('aboutUs.dreamHouseSearch') }}</h2>
                                <p>{{ $t('aboutUs.dreamHouseDescription') }}</p>
                                <a href="#" class="btn exliou btn-danger">{{ $t('aboutUs.findProperties') }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- ============================ Property Tag End ================================== -->

            <!-- ============================ Call To Action ================================== -->
            <PageFooter />
            <!-- ============================ Call To Action End ================================== -->
            <!-- ============================ Footer Start ================================== -->
            <!-- ============================ Footer End ================================== -->
        </div>
    </div>
</template>

<script>
import 'magnific-popup';

import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';

import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import sampleImage1 from '@/assets/img/p-4.webp';
import oldIcon from '@/assets/img/old.png';
import grenadaFlag from '@/assets/img/grenada_flag.webp';
import north_macedonia from '@/assets/img/north-macedonia.webp';
import dominica_flag from '@/assets/img/dominica_flag.webp';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';
import bgImage from '@/assets/img/bg.jpg';
import internationalTrade from '@/assets/img/internationaltrade.webp';
import companyOverview from '@/assets/img/companyoverview.webp';
import globalTrading from '@/assets/img/globalTrading.webp';


export default {
    name: 'AboutUs',
    components: {
        Navigation,
        PageFooter,
    },
    props: ['glb_id'],
    data() {
        return {
            internationalTrade,
            companyOverview,
            globalTrading,
            bgImage,
            addProperty,
            menuWidget,
            profileLogo,
            globalHomeLogo,
            whatsappLogo,
            wechatLogo,
            loginLogo,
            settingsLogo,
            qrCodeLogo,
            sampleImage1,
            grenadaFlag,
            north_macedonia,
            dominica_flag,
            oldIcon,
            navMenuVisible: false,
            activeSubmenu: null,
            userLoggedIn: false,
            loginError: '',
        };
    },
    methods: {
        toggleNavMenu() {
            this.navMenuVisible = !this.navMenuVisible;
        },
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
    },
};
</script>

<style scoped>
.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
    background-color: white;
    /* Set background color to white */
    color: #000000;
    /* Set icon color */
    /* padding: 5px; */
    /* Adjust padding to ensure icon is centered */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the icon */
    width: 50px;
    /* Set a fixed width for the button */
    height: 50px;
    /* Set a fixed height for the button */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
}

.add-listing a {
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

.d-flex {
    flex-wrap: nowrap;
}

.bg-danger {
    background-color: #d9534f;
    color: white;
}

a img {
    vertical-align: middle;
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
}

#main-wrapper {
    padding-top: 70px;
}

.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.input-with-icon select.form-control {
    padding-bottom: 1px;
    /* Adjust the value as needed */
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left: 40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 70px;
    margin-left: 50px;
}
</style>
