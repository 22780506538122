<template>
    <div :key="$i18n.locale" class="yellow-skin">

        <!-- ============================================================== -->
        <!-- Preloader - style you can find in spinners.css -->
        <!-- ============================================================== -->
        <!-- <div class="preloader"></div> -->

        <!-- ============================================================== -->
        <!-- Main wrapper - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <div id="main-wrapper">

            <!-- ============================================================== -->
            <!-- Top header  -->
            <!-- ============================================================== -->
            <!-- Start Navigation -->
            <Navigation />
            <!-- ============================ End Navigation ================================== -->

            <div class="clearfix"></div>
        </div>
        <!-- ============================ Page Title Start================================== -->
        <section class="position-relative">
            <!-- ============================ Hero Banner  Start================================== -->
            <div class="container">

                <h1 class="big-header-capt mb-0">Find Your Renovation</h1>
                <p class="text-center mb-5">Discover affordable renovation solutions tailored to your style and budget.
                </p>

                <div class="full-search-2 eclip-search italian-search hero-search-radius shadow mt-5">
                    <div class="hero-search-content">

                        <div class="row">

                            <!-- <div class="col-lg-4 col-md-4 col-sm-12 b-r">
									<div class="form-group borders">
										<div class="position-relative">
											<input type="text" class="form-control border-0 ps-5" placeholder="Neighborhood">
											<div class="position-absolute top-50 start-0 translate-middle-y ms-2">
												<span class="svg-icon text-primary svg-icon-2hx">
													<svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"/>
														<path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"/>
													</svg>
												</span>
											</div>
										</div>
									</div>
								</div> -->

                            <div class="col-lg-2 col-md-2 col-sm-12">
                                <div class="form-group borders border-start">
                                    <div class="position-relative ps-5">
                                        <select id="location" class="form-control">
                                            <option value="" disabled selected>Choose Style</option>
                                            <!-- Placeholder option -->
                                            <option value="1">French</option>
                                            <option value="2">Asian</option>
                                            <option value="3">Bohemian</option>
                                            <option value="4">Brutalist</option>
                                            <option value="5">Classic</option>
                                            <option value="6">Minimalist</option>
                                        </select>
                                        <div class="position-absolute top-50 start-0 translate-middle-y ms-2">
                                            <span class="svg-icon svg-icon-2hx">
                                                <svg width="25" height="25" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12 2C7.029 2 3 6.029 3 11s4.029 9 9 9c.277 0 .555-.015.83-.045a2.845 2.845 0 1 0 3.161-4.683 1.569 1.569 0 1 1 2.389-1.957A3.62 3.62 0 1 0 19 11c0-.148-.011-.293-.03-.436A9.065 9.065 0 0 0 12 2zm-3.5 8.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0zm6 1a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm-5 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm6.5-2a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0z"
                                                        fill="#FFD700" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-2 col-sm-12">
                                <div class="form-group borders border-start">
                                    <div class="position-relative ps-5">
                                        <select id="location" class="form-control">
                                            <option value="" disabled selected>Property Type</option>
                                            <!-- Placeholder option -->
                                            <option value="1">Condominium</option>
                                            <option value="2">Landed House</option>
                                            <option value="3">Villa</option>
                                            <option value="4">Commercial</option>
                                        </select>
                                        <div class="position-absolute top-50 start-0 translate-middle-y ms-2">
                                            <span class="svg-icon svg-icon-2hx">
                                                <!-- House icon SVG -->
                                                <svg width="25" height="25" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 2L2 10h2v10h6v-6h4v6h6V10h2L12 2z" fill="#FFD700" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-2 col-sm-12">
                                <div class="form-group borders border-start">
                                    <div class="position-relative ps-5">
                                        <select id="location" class="form-control">
                                            <option value="" disabled selected>Budget</option>
                                            <option value="1">$10000 - $20000</option>
                                            <option value="2">$20000 - $30000</option>
                                            <option value="3">$30000 - $40000</option>
                                            <option value="4">$40000 - $50000</option>
                                            <option value="5">$50000 - $60000</option>
                                            <option value="6">$60000 - $70000</option>
                                            <option value="7">$70000 - $80000</option>
                                            <option value="8">$80000 - $90000</option>
                                            <option value="9">$90000 - $100,000</option>
                                            <option value="10">Above $100,000</option>
                                        </select>
                                        <div class="position-absolute top-50 start-0 translate-middle-y ms-2">
                                            <span class="svg-icon svg-icon-2hx">
                                                <!-- Dollar icon SVG -->
                                                <svg width="25" height="25" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12 2C7.589 2 4 5.589 4 10h2c0-3.309 2.691-6 6-6s6 2.691 6 6-2.691 6-6 6c-1.091 0-2.121-.297-3-.797V18c.879.5 1.909.797 3 .797 4.411 0 8-3.589 8-8s-3.589-8-8-8zm0 18c1.091 0 2.121-.297 3-.797V20H8v-1.797c.879.5 1.909.797 3 .797z"
                                                        fill="#FFD700" />
                                                    <path d="M13 14V8h-2v6h2zm-2 3v2h2v-2h-2z" fill="#FFD700" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-2 col-sm-12">
                                <div class="form-group borders border-start">
                                    <div class="position-relative ps-5">
                                        <select id="ptypes" class="form-control">
                                            <option value="" disabled selected>Property Size</option>
                                            <option value="1">30-50sqm</option>
                                            <option value="2">51-80sqm</option>
                                            <option value="3">81-120sqm</option>
                                            <option value="4">121-180sqm</option>
                                            <option value="5">181-250sqm</option>
                                            <option value="6">251-350sqm</option>
                                            <option value="7">351-500sqm</option>
                                            <option value="8">501-700sqm</option>
                                            <option value="9">701-900sqm</option>
                                            <option value="10">Above 900sqm</option>
                                        </select>
                                        <div class="position-absolute top-50 start-0 translate-middle-y ms-2">
                                            <span class="svg-icon svg-icon-2hx">
                                                <!-- Ruler icon SVG -->
                                                <svg width="25" height="25" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M2 3h20v2H2V3zm0 4h20v2H2V7zm0 4h20v2H2v-2zm0 4h20v2H2v-2zm0 4h20v2H2v-2z"
                                                        fill="#FFD700" />
                                                    <rect x="4" y="10" width="2" height="2" fill="#FFD700" />
                                                    <rect x="8" y="10" width="2" height="2" fill="#FFD700" />
                                                    <rect x="12" y="10" width="2" height="2" fill="#FFD700" />
                                                    <rect x="16" y="10" width="2" height="2" fill="#FFD700" />
                                                    <rect x="20" y="10" width="2" height="2" fill="#FFD700" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-2 col-sm-12">
                                <div class="form-group borders border-start">
                                    <div class="position-relative ps-5">
                                        <select id="location" class="form-control">
                                            <option value="" disabled selected>Areas</option>
                                            <option value="1">Featured</option>
                                            <option value="2">Living Room</option>
                                            <option value="3">Kitchen</option>
                                            <option value="4">Dining Room</option>
                                            <option value="5">Bedroom</option>
                                            <option value="6">Bathroom</option>
                                            <option value="7">Study Room</option>
                                            <option value="8">Balcony</option>
                                            <option value="9">Entrance</option>
                                            <option value="10">Walkway</option>
                                            <option value="11">Service Yard</option>
                                            <option value="12">Walk-in Wardrobe</option>
                                            <option value="13">Vanity</option>
                                            <option value="14">Garden/Yard</option>
                                            <option value="15">Others</option>
                                            <option value="16">Floor Plan</option>
                                        </select>
                                        <div class="position-absolute top-50 start-0 translate-middle-y ms-2">
                                            <span class="svg-icon svg-icon-2hx">
                                                <!-- Floor Plan icon SVG -->
                                                <svg width="25" height="25" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="3" y="3" width="18" height="18" fill="none"
                                                        stroke="#FFD700" stroke-width="2" />
                                                    <path d="M3 12h18" stroke="#FFD700" stroke-width="2" />
                                                    <path d="M12 3v18" stroke="#FFD700" stroke-width="2" />
                                                    <path d="M6 3v6h6" stroke="#FFD700" stroke-width="2" />
                                                    <path d="M18 12v6h-6" stroke="#FFD700" stroke-width="2" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-2 col-sm-12">
                                <div class="form-group">
                                    <button type="button" href="#" class="btn btn-danger full-width">Search</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <!-- ============================ Hero Banner End ================================== -->
        </section>
        <!-- ============================ Page Title End ================================== -->

        <!-- =========================== All Property =============================== -->
        <section class="gray-simple">
            <div class="container">

                <div class="row align-items-center">
                    <div class="col-lg-10 col-md-10">
                        <div class="d-flex align-items-center">
                            <h4 class="m-0 fs-6 me-3">Found 1-10 of 142 Results</h4>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 text-end">
                        <div class="shorting-by">
                            <select id="shorty" class="form-control">
                                <option value="" disabled selected>Sort By</option>
                                <option value="1">Low Price</option>
                                <option value="2">High Price</option>
                                <option value="3">Most Popular</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center g-4">

                    <!-- Single Property -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="property-listing card border-0 rounded-3">

                            <div class="listing-img-wrapper p-3">
                                <div class="list-img-slide position-relative">
                                    <div class="position-absolute top-0 left-0 ms-3 mt-3 z-1">
                                        <div class="label d-inline-flex align-items-center justify-content-center"
                                            style="background-color: #FFD700; color: #000;">
                                            <span class="svg-icon text-dark svg-icon-2hx me-1">
                                                <svg width="14" height="14" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3"
                                                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                                        fill="currentColor"></path>
                                                    <path
                                                        d="M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z"
                                                        fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            Verified
                                        </div>
                                    </div>

                                    <div class="list-img-slide">
                                        <div class="clior">
                                            <div>
                                                <a href="single-property-1.html">
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/globalhome-c6c4b.appspot.com/o/renovation%2F1103379411826446687_living_4.webp?alt=media&token=30b905bf-3906-4639-b579-b3b384a64462"
                                                        class="img-fluid" alt="Bathroom Renovation Image" />
                                                </a>
                                            </div>
                                            <div>
                                                <a href="single-property-1.html">
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/globalhome-c6c4b.appspot.com/o/renovation%2F1103379411826446687_bathroom_1.webp?alt=media&token=30b905bf-3906-4639-b579-b3b384a64462"
                                                        class="img-fluid" alt="Bathroom Renovation Image" />
                                                </a>
                                            </div>
                                            <div>
                                                <a href="single-property-1.html">
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/globalhome-c6c4b.appspot.com/o/renovation%2F1103379411826446687_bathroom_1.webp?alt=media&token=30b905bf-3906-4639-b579-b3b384a64462"
                                                        class="img-fluid" alt="Bathroom Renovation Image" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="listing-caption-wrapper px-3">
                                <div class="listing-detail-wrapper">
                                    <div class="listing-short-detail-wrap">
                                        <div class="listing-short-detail">
                                            <div class="d-flex align-items-center">
                                                <span class="label bg-light-danger text-success prt-type me-2">Soft
                                                    Colour</span><span
                                                    class="label bg-light-purple text-purple property-cats">Apartment</span>
                                            </div>
                                            <h4 class="listing-name fw-semibold fs-5 mb-1"><a
                                                    href="single-property-1.html">Minimalist with Modern Elegance</a>
                                            </h4>
                                            <div class="prt-location text-muted-2">
                                                <span class="svg-icon svg-icon-2hx">
                                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3"
                                                            d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                                            fill="currentColor" />
                                                        <path
                                                            d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                                            fill="currentColor" />
                                                    </svg>
                                                </span>
                                                Amazing Grace Renovation Johor Bahru
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="price-features-wrapper">
                                    <div class="list-fx-features d-flex align-items-center justify-content-between">
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-building-shield fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">3BHK</span>
                                        </div>
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-bed fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">3 Beds</span>
                                        </div>
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-clone fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">1800 SQFT</span>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="listing-detail-footer d-flex align-items-center justify-content-between py-4">
                                    <div class="listing-short-detail-flex">
                                        <h6 class="listing-card-info-price m-0" style="color: #000;">$80,000</h6>
                                        <!-- Price text in black -->
                                    </div>
                                    <div class="footer-flex">
                                        <a href="property-detail.html" class="prt-view">
                                            <span class="svg-icon text-gold svg-icon-2hx">
                                                <!-- Custom class for gold color -->
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
                                                        fill="currentColor" />
                                                    <path opacity="0.3"
                                                        d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                    <!-- End Single Property -->

                    <!-- Single Property -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="property-listing card border-0 rounded-3">

                            <div class="listing-img-wrapper p-3">
                                <div class="list-img-slide position-relative">
                                    <div class="position-absolute top-0 left-0 ms-3 mt-3 z-1">
                                        <div class="label d-inline-flex align-items-center justify-content-center"
                                            style="background-color: #FFD700; color: #000;">
                                            <span class="svg-icon text-dark svg-icon-2hx me-1">
                                                <svg width="14" height="14" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3"
                                                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                                        fill="currentColor" />
                                                    <path
                                                        d="M12.0006 11.1542C13.1434 11.1542 14.0777 10.22 14.0777 9.0771C14.0777 7.93424 13.1434 7 12.0006 7C10.8577 7 9.92348 7.93424 9.92348 9.0771C9.92348 10.22 10.8577 11.1542 12.0006 11.1542Z"
                                                        fill="currentColor" />
                                                    <path
                                                        d="M15.5652 13.814C15.5108 13.6779 15.4382 13.551 15.3566 13.4331C14.9393 12.8163 14.2954 12.4081 13.5697 12.3083C13.479 12.2993 13.3793 12.3174 13.3067 12.3718C12.9257 12.653 12.4722 12.7981 12.0006 12.7981C11.5289 12.7981 11.0754 12.653 10.6944 12.3718C10.6219 12.3174 10.5221 12.2902 10.4314 12.3083C9.70578 12.4081 9.05272 12.8163 8.64456 13.4331C8.56293 13.551 8.49036 13.687 8.43595 13.814C8.40875 13.8684 8.41781 13.9319 8.44502 13.9864C8.51759 14.1133 8.60828 14.2403 8.68991 14.3492C8.81689 14.5215 8.95295 14.6757 9.10715 14.8208C9.23413 14.9478 9.37925 15.0657 9.52439 15.1836C10.2409 15.7188 11.1026 15.9999 11.9915 15.9999C12.8804 15.9999 13.7421 15.7188 14.4586 15.1836C14.6038 15.0748 14.7489 14.9478 14.8759 14.8208C15.021 14.6757 15.1661 14.5215 15.2931 14.3492C15.3838 14.2312 15.4655 14.1133 15.538 13.9864C15.5833 13.9319 15.5924 13.8684 15.5652 13.814Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>Top Favourites
                                        </div>
                                    </div>

                                    <div class="click rounded-3 overflow-hidden mb-0">
                                        <div>
                                            <a href="single-property-1.html">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/globalhome-c6c4b.appspot.com/o/renovation%2F1103379411826446687_living_4.webp?alt=media&token=30b905bf-3906-4639-b579-b3b384a64462"
                                                    class="img-fluid" alt="Bathroom Renovation Image" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="listing-caption-wrapper px-3">
                                <div class="listing-detail-wrapper">
                                    <div class="listing-short-detail-wrap">
                                        <div class="listing-short-detail">
                                            <div class="d-flex align-items-center">
                                                <span class="label bg-light-danger text-success prt-type me-2">Soft
                                                    Colour</span><span
                                                    class="label bg-light-purple text-purple property-cats">House</span>
                                            </div>
                                            <h4 class="listing-name fw-semibold fs-5 mb-1"><a
                                                    href="single-property-1.html">Purple Flatiron House</a></h4>
                                            <div class="prt-location text-muted-2">
                                                <span class="svg-icon svg-icon-2hx">
                                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3"
                                                            d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                                            fill="currentColor" />
                                                        <path
                                                            d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                                            fill="currentColor" />
                                                    </svg>
                                                </span>
                                                210 Zirak Road, Canada
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="price-features-wrapper">
                                    <div class="list-fx-features d-flex align-items-center justify-content-between">
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-building-shield fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">3BHK</span>
                                        </div>
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-bed fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">3 Beds</span>
                                        </div>
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-clone fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">1800 SQFT</span>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="listing-detail-footer d-flex align-items-center justify-content-between py-4">
                                    <div class="listing-short-detail-flex">
                                        <h6 class="listing-card-info-price m-0" style="color: #000;">$80,000</h6>
                                        <!-- Price text in black -->
                                    </div>
                                    <div class="footer-flex">
                                        <a href="property-detail.html" class="prt-view">
                                            <span class="svg-icon text-gold svg-icon-2hx">
                                                <!-- Custom class for gold color -->
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
                                                        fill="currentColor" />
                                                    <path opacity="0.3"
                                                        d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <!-- End Single Property -->

                    <!-- Single Property -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="property-listing card border-0 rounded-3">

                            <div class="listing-img-wrapper p-3">
                                <div class="list-img-slide position-relative">
                                    <div class="position-absolute top-0 left-0 ms-3 mt-3 z-1">
                                        <div class="label d-inline-flex align-items-center justify-content-center"
                                            style="background-color: #FFD700; color: #000;">
                                            <span class="svg-icon text-dark svg-icon-2hx me-1">
                                                <svg width="14" height="14" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3"
                                                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                                        fill="currentColor"></path>
                                                    <path
                                                        d="M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z"
                                                        fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            Verified
                                        </div>
                                        <div
                                            class="label bg-danger text-light d-inline-flex align-items-center justify-content-center ms-1">
                                            <span class="svg-icon text-light svg-icon-2hx me-1">
                                                <svg width="14" height="14" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M19.0647 5.43757C19.3421 5.43757 19.567 5.21271 19.567 4.93534C19.567 4.65796 19.3421 4.43311 19.0647 4.43311C18.7874 4.43311 18.5625 4.65796 18.5625 4.93534C18.5625 5.21271 18.7874 5.43757 19.0647 5.43757Z"
                                                        fill="currentColor" />
                                                    <path
                                                        d="M20.0692 9.48884C20.3466 9.48884 20.5714 9.26398 20.5714 8.98661C20.5714 8.70923 20.3466 8.48438 20.0692 8.48438C19.7918 8.48438 19.567 8.70923 19.567 8.98661C19.567 9.26398 19.7918 9.48884 20.0692 9.48884Z"
                                                        fill="currentColor" />
                                                    <path
                                                        d="M12.0335 20.5714C15.6943 20.5714 18.9426 18.2053 20.1168 14.7338C20.1884 14.5225 20.1114 14.289 19.9284 14.161C19.746 14.034 19.5003 14.0418 19.3257 14.1821C18.2432 15.0546 16.9371 15.5156 15.5491 15.5156C12.2257 15.5156 9.48884 12.8122 9.48884 9.48886C9.48884 7.41079 10.5773 5.47137 12.3449 4.35752C12.5342 4.23832 12.6 4.00733 12.5377 3.79251C12.4759 3.57768 12.2571 3.42859 12.0335 3.42859C7.32556 3.42859 3.42857 7.29209 3.42857 12C3.42857 16.7079 7.32556 20.5714 12.0335 20.5714Z"
                                                        fill="currentColor" />
                                                    <path
                                                        d="M13.0379 7.47998C13.8688 7.47998 14.5446 8.15585 14.5446 8.98668C14.5446 9.26428 14.7693 9.48891 15.0469 9.48891C15.3245 9.48891 15.5491 9.26428 15.5491 8.98668C15.5491 8.15585 16.225 7.47998 17.0558 7.47998C17.3334 7.47998 17.558 7.25535 17.558 6.97775C17.558 6.70015 17.3334 6.47552 17.0558 6.47552C16.225 6.47552 15.5491 5.76616 15.5491 4.93534C15.5491 4.65774 15.3245 4.43311 15.0469 4.43311C14.7693 4.43311 14.5446 4.65774 14.5446 4.93534C14.5446 5.76616 13.8688 6.47552 13.0379 6.47552C12.7603 6.47552 12.5357 6.70015 12.5357 6.97775C12.5357 7.25535 12.7603 7.47998 13.0379 7.47998Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>New
                                        </div>
                                    </div>
                                    <div class="click rounded-3 overflow-hidden mb-0">
                                        <div>
                                            <a href="single-property-1.html">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/globalhome-c6c4b.appspot.com/o/renovation%2F1103379411826446687_living_4.webp?alt=media&token=30b905bf-3906-4639-b579-b3b384a64462"
                                                    class="img-fluid" alt="Bathroom Renovation Image" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="listing-caption-wrapper px-3">
                                <div class="listing-detail-wrapper">
                                    <div class="listing-short-detail-wrap">
                                        <div class="listing-short-detail">
                                            <div class="d-flex align-items-center">
                                                <span class="label bg-light-danger text-success prt-type me-2">Soft
                                                    Colour</span><span
                                                    class="label bg-light-purple text-purple property-cats">Building</span>
                                            </div>
                                            <h4 class="listing-name fw-semibold fs-5 mb-1"><a
                                                    href="single-property-1.html">Rustic Reunion Tower</a></h4>
                                            <div class="prt-location text-muted-2">
                                                <span class="svg-icon svg-icon-2hx">
                                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3"
                                                            d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                                            fill="currentColor" />
                                                        <path
                                                            d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                                            fill="currentColor" />
                                                    </svg>
                                                </span>
                                                210 Zirak Road, Canada
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="price-features-wrapper">
                                    <div class="list-fx-features d-flex align-items-center justify-content-between">
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-building-shield fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">3BHK</span>
                                        </div>
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-bed fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">3 Beds</span>
                                        </div>
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-clone fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">1800 SQFT</span>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="listing-detail-footer d-flex align-items-center justify-content-between py-4">
                                    <div class="listing-short-detail-flex">
                                        <h6 class="listing-card-info-price m-0" style="color: #000;">$80,000</h6>
                                        <!-- Price text in black -->
                                    </div>
                                    <div class="footer-flex">
                                        <a href="property-detail.html" class="prt-view">
                                            <span class="svg-icon text-gold svg-icon-2hx">
                                                <!-- Custom class for gold color -->
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
                                                        fill="currentColor" />
                                                    <path opacity="0.3"
                                                        d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <!-- End Single Property -->

                    <!-- Single Property -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="property-listing card border-0 rounded-3">

                            <div class="listing-img-wrapper p-3">
                                <div class="list-img-slide position-relative">
                                    <div class="position-absolute top-0 left-0 ms-3 mt-3 z-1">
                                        <div class="label d-inline-flex align-items-center justify-content-center"
                                            style="background-color: #FFD700; color: #000;">
                                            <span class="svg-icon text-dark svg-icon-2hx me-1">
                                                <svg width="14" height="14" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3"
                                                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                                        fill="currentColor" />
                                                    <path
                                                        d="M12.0006 11.1542C13.1434 11.1542 14.0777 10.22 14.0777 9.0771C14.0777 7.93424 13.1434 7 12.0006 7C10.8577 7 9.92348 7.93424 9.92348 9.0771C9.92348 10.22 10.8577 11.1542 12.0006 11.1542Z"
                                                        fill="currentColor" />
                                                    <path
                                                        d="M15.5652 13.814C15.5108 13.6779 15.4382 13.551 15.3566 13.4331C14.9393 12.8163 14.2954 12.4081 13.5697 12.3083C13.479 12.2993 13.3793 12.3174 13.3067 12.3718C12.9257 12.653 12.4722 12.7981 12.0006 12.7981C11.5289 12.7981 11.0754 12.653 10.6944 12.3718C10.6219 12.3174 10.5221 12.2902 10.4314 12.3083C9.70578 12.4081 9.05272 12.8163 8.64456 13.4331C8.56293 13.551 8.49036 13.687 8.43595 13.814C8.40875 13.8684 8.41781 13.9319 8.44502 13.9864C8.51759 14.1133 8.60828 14.2403 8.68991 14.3492C8.81689 14.5215 8.95295 14.6757 9.10715 14.8208C9.23413 14.9478 9.37925 15.0657 9.52439 15.1836C10.2409 15.7188 11.1026 15.9999 11.9915 15.9999C12.8804 15.9999 13.7421 15.7188 14.4586 15.1836C14.6038 15.0748 14.7489 14.9478 14.8759 14.8208C15.021 14.6757 15.1661 14.5215 15.2931 14.3492C15.3838 14.2312 15.4655 14.1133 15.538 13.9864C15.5833 13.9319 15.5924 13.8684 15.5652 13.814Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>Top Favourites
                                        </div>
                                    </div>
                                    <div class="click rounded-3 overflow-hidden mb-0">
                                        <div>
                                            <a href="single-property-1.html">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/globalhome-c6c4b.appspot.com/o/renovation%2F1103379411826446687_living_4.webp?alt=media&token=30b905bf-3906-4639-b579-b3b384a64462"
                                                    class="img-fluid" alt="Bathroom Renovation Image" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="listing-caption-wrapper px-3">
                                <div class="listing-detail-wrapper">
                                    <div class="listing-short-detail-wrap">
                                        <div class="listing-short-detail">
                                            <div class="d-flex align-items-center">
                                                <span class="label bg-light-danger text-success prt-type me-2">Soft
                                                    Colour</span><span
                                                    class="label bg-light-purple text-purple property-cats">Condos</span>
                                            </div>
                                            <h4 class="listing-name fw-semibold fs-5 mb-1"><a
                                                    href="single-property-1.html">The Red Freedom Tower</a></h4>
                                            <div class="prt-location text-muted-2">
                                                <span class="svg-icon svg-icon-2hx">
                                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3"
                                                            d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                                            fill="currentColor" />
                                                        <path
                                                            d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                                            fill="currentColor" />
                                                    </svg>
                                                </span>
                                                210 Zirak Road, Canada
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="price-features-wrapper">
                                    <div class="list-fx-features d-flex align-items-center justify-content-between">
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-building-shield fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">3BHK</span>
                                        </div>
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-bed fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">3 Beds</span>
                                        </div>
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-clone fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">1800 SQFT</span>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="listing-detail-footer d-flex align-items-center justify-content-between py-4">
                                    <div class="listing-short-detail-flex">
                                        <h6 class="listing-card-info-price m-0" style="color: #000;">$80,000</h6>
                                        <!-- Price text in black -->
                                    </div>
                                    <div class="footer-flex">
                                        <a href="property-detail.html" class="prt-view">
                                            <span class="svg-icon text-gold svg-icon-2hx">
                                                <!-- Custom class for gold color -->
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
                                                        fill="currentColor" />
                                                    <path opacity="0.3"
                                                        d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <!-- End Single Property -->

                    <!-- Single Property -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="property-listing card border-0 rounded-3">

                            <div class="listing-img-wrapper p-3">
                                <div class="list-img-slide position-relative">
                                    <div class="position-absolute top-0 left-0 ms-3 mt-3 z-1">
                                        <div class="label d-inline-flex align-items-center justify-content-center"
                                            style="background-color: #FFD700; color: #000;">
                                            <span class="svg-icon text-dark svg-icon-2hx me-1">
                                                <svg width="14" height="14" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3"
                                                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                                        fill="currentColor"></path>
                                                    <path
                                                        d="M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z"
                                                        fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            Verified
                                        </div>
                                        <div
                                            class="label bg-danger text-light d-inline-flex align-items-center justify-content-center ms-1">
                                            <span class="svg-icon text-light svg-icon-2hx me-1">
                                                <svg width="14" height="14" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M19.0647 5.43757C19.3421 5.43757 19.567 5.21271 19.567 4.93534C19.567 4.65796 19.3421 4.43311 19.0647 4.43311C18.7874 4.43311 18.5625 4.65796 18.5625 4.93534C18.5625 5.21271 18.7874 5.43757 19.0647 5.43757Z"
                                                        fill="currentColor" />
                                                    <path
                                                        d="M20.0692 9.48884C20.3466 9.48884 20.5714 9.26398 20.5714 8.98661C20.5714 8.70923 20.3466 8.48438 20.0692 8.48438C19.7918 8.48438 19.567 8.70923 19.567 8.98661C19.567 9.26398 19.7918 9.48884 20.0692 9.48884Z"
                                                        fill="currentColor" />
                                                    <path
                                                        d="M12.0335 20.5714C15.6943 20.5714 18.9426 18.2053 20.1168 14.7338C20.1884 14.5225 20.1114 14.289 19.9284 14.161C19.746 14.034 19.5003 14.0418 19.3257 14.1821C18.2432 15.0546 16.9371 15.5156 15.5491 15.5156C12.2257 15.5156 9.48884 12.8122 9.48884 9.48886C9.48884 7.41079 10.5773 5.47137 12.3449 4.35752C12.5342 4.23832 12.6 4.00733 12.5377 3.79251C12.4759 3.57768 12.2571 3.42859 12.0335 3.42859C7.32556 3.42859 3.42857 7.29209 3.42857 12C3.42857 16.7079 7.32556 20.5714 12.0335 20.5714Z"
                                                        fill="currentColor" />
                                                    <path
                                                        d="M13.0379 7.47998C13.8688 7.47998 14.5446 8.15585 14.5446 8.98668C14.5446 9.26428 14.7693 9.48891 15.0469 9.48891C15.3245 9.48891 15.5491 9.26428 15.5491 8.98668C15.5491 8.15585 16.225 7.47998 17.0558 7.47998C17.3334 7.47998 17.558 7.25535 17.558 6.97775C17.558 6.70015 17.3334 6.47552 17.0558 6.47552C16.225 6.47552 15.5491 5.76616 15.5491 4.93534C15.5491 4.65774 15.3245 4.43311 15.0469 4.43311C14.7693 4.43311 14.5446 4.65774 14.5446 4.93534C14.5446 5.76616 13.8688 6.47552 13.0379 6.47552C12.7603 6.47552 12.5357 6.70015 12.5357 6.97775C12.5357 7.25535 12.7603 7.47998 13.0379 7.47998Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>New
                                        </div>
                                    </div>
                                    <div class="click rounded-3 overflow-hidden mb-0">
                                        <div>
                                            <a href="single-property-1.html">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/globalhome-c6c4b.appspot.com/o/renovation%2F1103379411826446687_living_4.webp?alt=media&token=30b905bf-3906-4639-b579-b3b384a64462"
                                                    class="img-fluid" alt="Bathroom Renovation Image" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="listing-caption-wrapper px-3">
                                <div class="listing-detail-wrapper">
                                    <div class="listing-short-detail-wrap">
                                        <div class="listing-short-detail">
                                            <div class="d-flex align-items-center">
                                                <span class="label bg-light-danger text-success prt-type me-2">Soft
                                                    Colour</span><span
                                                    class="label bg-light-purple text-purple property-cats">Villa</span>
                                            </div>
                                            <h4 class="listing-name fw-semibold fs-5 mb-1"><a
                                                    href="single-property-1.html">The Donald Dwelling</a></h4>
                                            <div class="prt-location text-muted-2">
                                                <span class="svg-icon svg-icon-2hx">
                                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3"
                                                            d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                                            fill="currentColor" />
                                                        <path
                                                            d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                                            fill="currentColor" />
                                                    </svg>
                                                </span>
                                                210 Zirak Road, Canada
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="price-features-wrapper">
                                    <div class="list-fx-features d-flex align-items-center justify-content-between">
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-building-shield fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">3BHK</span>
                                        </div>
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-bed fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">3 Beds</span>
                                        </div>
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-clone fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">1800 SQFT</span>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="listing-detail-footer d-flex align-items-center justify-content-between py-4">
                                    <div class="listing-short-detail-flex">
                                        <h6 class="listing-card-info-price m-0" style="color: #000;">$80,000</h6>
                                        <!-- Price text in black -->
                                    </div>
                                    <div class="footer-flex">
                                        <a href="property-detail.html" class="prt-view">
                                            <span class="svg-icon text-gold svg-icon-2hx">
                                                <!-- Custom class for gold color -->
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
                                                        fill="currentColor" />
                                                    <path opacity="0.3"
                                                        d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <!-- End Single Property -->

                    <!-- Single Property -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="property-listing card border-0 rounded-3">

                            <div class="listing-img-wrapper p-3">
                                <div class="list-img-slide position-relative">
                                    <div class="position-absolute top-0 left-0 ms-3 mt-3 z-1">
                                        <div class="label d-inline-flex align-items-center justify-content-center"
                                            style="background-color: #FFD700; color: #000;">
                                            <span class="svg-icon text-dark svg-icon-2hx me-1">
                                                <svg width="14" height="14" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3"
                                                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                                        fill="currentColor" />
                                                    <path
                                                        d="M12.0006 11.1542C13.1434 11.1542 14.0777 10.22 14.0777 9.0771C14.0777 7.93424 13.1434 7 12.0006 7C10.8577 7 9.92348 7.93424 9.92348 9.0771C9.92348 10.22 10.8577 11.1542 12.0006 11.1542Z"
                                                        fill="currentColor" />
                                                    <path
                                                        d="M15.5652 13.814C15.5108 13.6779 15.4382 13.551 15.3566 13.4331C14.9393 12.8163 14.2954 12.4081 13.5697 12.3083C13.479 12.2993 13.3793 12.3174 13.3067 12.3718C12.9257 12.653 12.4722 12.7981 12.0006 12.7981C11.5289 12.7981 11.0754 12.653 10.6944 12.3718C10.6219 12.3174 10.5221 12.2902 10.4314 12.3083C9.70578 12.4081 9.05272 12.8163 8.64456 13.4331C8.56293 13.551 8.49036 13.687 8.43595 13.814C8.40875 13.8684 8.41781 13.9319 8.44502 13.9864C8.51759 14.1133 8.60828 14.2403 8.68991 14.3492C8.81689 14.5215 8.95295 14.6757 9.10715 14.8208C9.23413 14.9478 9.37925 15.0657 9.52439 15.1836C10.2409 15.7188 11.1026 15.9999 11.9915 15.9999C12.8804 15.9999 13.7421 15.7188 14.4586 15.1836C14.6038 15.0748 14.7489 14.9478 14.8759 14.8208C15.021 14.6757 15.1661 14.5215 15.2931 14.3492C15.3838 14.2312 15.4655 14.1133 15.538 13.9864C15.5833 13.9319 15.5924 13.8684 15.5652 13.814Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>Top Favourites
                                        </div>
                                    </div>
                                    <div class="click rounded-3 overflow-hidden mb-0">
                                        <div>
                                            <a href="single-property-1.html">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/globalhome-c6c4b.appspot.com/o/renovation%2F1103379411826446687_living_4.webp?alt=media&token=30b905bf-3906-4639-b579-b3b384a64462"
                                                    class="img-fluid" alt="Bathroom Renovation Image" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="listing-caption-wrapper px-3">
                                <div class="listing-detail-wrapper">
                                    <div class="listing-short-detail-wrap">
                                        <div class="listing-short-detail">
                                            <div class="d-flex align-items-center">
                                                <span class="label bg-light-danger text-success prt-type me-2">Soft
                                                    Colour</span><span
                                                    class="label bg-light-purple text-purple property-cats">Building</span>
                                            </div>
                                            <h4 class="listing-name fw-semibold fs-5 mb-1"><a
                                                    href="single-property-1.html">Red Tiny Hearst Castle</a></h4>
                                            <div class="prt-location text-muted-2">
                                                <span class="svg-icon svg-icon-2hx">
                                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3"
                                                            d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                                            fill="currentColor" />
                                                        <path
                                                            d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                                            fill="currentColor" />
                                                    </svg>
                                                </span>
                                                210 Zirak Road, Canada
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="price-features-wrapper">
                                    <div class="list-fx-features d-flex align-items-center justify-content-between">
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-building-shield fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">3BHK</span>
                                        </div>
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-bed fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">3 Beds</span>
                                        </div>
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-clone fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">1800 SQFT</span>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="listing-detail-footer d-flex align-items-center justify-content-between py-4">
                                    <div class="listing-short-detail-flex">
                                        <h6 class="listing-card-info-price m-0" style="color: #000;">$80,000</h6>
                                        <!-- Price text in black -->
                                    </div>
                                    <div class="footer-flex">
                                        <a href="property-detail.html" class="prt-view">
                                            <span class="svg-icon text-gold svg-icon-2hx">
                                                <!-- Custom class for gold color -->
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
                                                        fill="currentColor" />
                                                    <path opacity="0.3"
                                                        d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <!-- End Single Property -->

                    <!-- Single Property -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="property-listing card border-0 rounded-3">

                            <div class="listing-img-wrapper p-3">
                                <div class="list-img-slide position-relative">
                                    <div class="position-absolute top-0 left-0 ms-3 mt-3 z-1">
                                        <div class="label d-inline-flex align-items-center justify-content-center"
                                            style="background-color: #FFD700; color: #000;">
                                            <span class="svg-icon text-dark svg-icon-2hx me-1">
                                                <svg width="14" height="14" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3"
                                                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                                        fill="currentColor" />
                                                    <path
                                                        d="M12.0006 11.1542C13.1434 11.1542 14.0777 10.22 14.0777 9.0771C14.0777 7.93424 13.1434 7 12.0006 7C10.8577 7 9.92348 7.93424 9.92348 9.0771C9.92348 10.22 10.8577 11.1542 12.0006 11.1542Z"
                                                        fill="currentColor" />
                                                    <path
                                                        d="M15.5652 13.814C15.5108 13.6779 15.4382 13.551 15.3566 13.4331C14.9393 12.8163 14.2954 12.4081 13.5697 12.3083C13.479 12.2993 13.3793 12.3174 13.3067 12.3718C12.9257 12.653 12.4722 12.7981 12.0006 12.7981C11.5289 12.7981 11.0754 12.653 10.6944 12.3718C10.6219 12.3174 10.5221 12.2902 10.4314 12.3083C9.70578 12.4081 9.05272 12.8163 8.64456 13.4331C8.56293 13.551 8.49036 13.687 8.43595 13.814C8.40875 13.8684 8.41781 13.9319 8.44502 13.9864C8.51759 14.1133 8.60828 14.2403 8.68991 14.3492C8.81689 14.5215 8.95295 14.6757 9.10715 14.8208C9.23413 14.9478 9.37925 15.0657 9.52439 15.1836C10.2409 15.7188 11.1026 15.9999 11.9915 15.9999C12.8804 15.9999 13.7421 15.7188 14.4586 15.1836C14.6038 15.0748 14.7489 14.9478 14.8759 14.8208C15.021 14.6757 15.1661 14.5215 15.2931 14.3492C15.3838 14.2312 15.4655 14.1133 15.538 13.9864C15.5833 13.9319 15.5924 13.8684 15.5652 13.814Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>Top Favourites
                                        </div>
                                    </div>
                                    <div class="click rounded-3 overflow-hidden mb-0">
                                        <div>
                                            <a href="single-property-1.html">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/globalhome-c6c4b.appspot.com/o/renovation%2F1103379411826446687_living_4.webp?alt=media&token=30b905bf-3906-4639-b579-b3b384a64462"
                                                    class="img-fluid" alt="Bathroom Renovation Image" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="listing-caption-wrapper px-3">
                                <div class="listing-detail-wrapper">
                                    <div class="listing-short-detail-wrap">
                                        <div class="listing-short-detail">
                                            <div class="d-flex align-items-center">
                                                <span class="label bg-light-danger text-success prt-type me-2">Soft
                                                    Colour</span><span
                                                    class="label bg-light-purple text-purple property-cats">Condos</span>
                                            </div>
                                            <h4 class="listing-name fw-semibold fs-5 mb-1"><a
                                                    href="single-property-1.html">The Red Freedom Tower</a></h4>
                                            <div class="prt-location text-muted-2">
                                                <span class="svg-icon svg-icon-2hx">
                                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3"
                                                            d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                                            fill="currentColor" />
                                                        <path
                                                            d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                                            fill="currentColor" />
                                                    </svg>
                                                </span>
                                                210 Zirak Road, Canada
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="price-features-wrapper">
                                    <div class="list-fx-features d-flex align-items-center justify-content-between">
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-building-shield fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">3BHK</span>
                                        </div>
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-bed fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">3 Beds</span>
                                        </div>
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-clone fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">1800 SQFT</span>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="listing-detail-footer d-flex align-items-center justify-content-between py-4">
                                    <div class="listing-short-detail-flex">
                                        <h6 class="listing-card-info-price m-0" style="color: #000;">$80,000</h6>
                                        <!-- Price text in black -->
                                    </div>
                                    <div class="footer-flex">
                                        <a href="property-detail.html" class="prt-view">
                                            <span class="svg-icon text-gold svg-icon-2hx">
                                                <!-- Custom class for gold color -->
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
                                                        fill="currentColor" />
                                                    <path opacity="0.3"
                                                        d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <!-- End Single Property -->

                    <!-- Single Property -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="property-listing card border-0 rounded-3">

                            <div class="listing-img-wrapper p-3">
                                <div class="list-img-slide position-relative">
                                    <div class="position-absolute top-0 left-0 ms-3 mt-3 z-1">
                                        <div class="label d-inline-flex align-items-center justify-content-center"
                                            style="background-color: #FFD700; color: #000;">
                                            <span class="svg-icon text-dark svg-icon-2hx me-1">
                                                <svg width="14" height="14" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3"
                                                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                                        fill="currentColor"></path>
                                                    <path
                                                        d="M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z"
                                                        fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            Verified
                                        </div>
                                        <div
                                            class="label bg-danger text-light d-inline-flex align-items-center justify-content-center ms-1">
                                            <span class="svg-icon text-light svg-icon-2hx me-1">
                                                <svg width="14" height="14" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M19.0647 5.43757C19.3421 5.43757 19.567 5.21271 19.567 4.93534C19.567 4.65796 19.3421 4.43311 19.0647 4.43311C18.7874 4.43311 18.5625 4.65796 18.5625 4.93534C18.5625 5.21271 18.7874 5.43757 19.0647 5.43757Z"
                                                        fill="currentColor" />
                                                    <path
                                                        d="M20.0692 9.48884C20.3466 9.48884 20.5714 9.26398 20.5714 8.98661C20.5714 8.70923 20.3466 8.48438 20.0692 8.48438C19.7918 8.48438 19.567 8.70923 19.567 8.98661C19.567 9.26398 19.7918 9.48884 20.0692 9.48884Z"
                                                        fill="currentColor" />
                                                    <path
                                                        d="M12.0335 20.5714C15.6943 20.5714 18.9426 18.2053 20.1168 14.7338C20.1884 14.5225 20.1114 14.289 19.9284 14.161C19.746 14.034 19.5003 14.0418 19.3257 14.1821C18.2432 15.0546 16.9371 15.5156 15.5491 15.5156C12.2257 15.5156 9.48884 12.8122 9.48884 9.48886C9.48884 7.41079 10.5773 5.47137 12.3449 4.35752C12.5342 4.23832 12.6 4.00733 12.5377 3.79251C12.4759 3.57768 12.2571 3.42859 12.0335 3.42859C7.32556 3.42859 3.42857 7.29209 3.42857 12C3.42857 16.7079 7.32556 20.5714 12.0335 20.5714Z"
                                                        fill="currentColor" />
                                                    <path
                                                        d="M13.0379 7.47998C13.8688 7.47998 14.5446 8.15585 14.5446 8.98668C14.5446 9.26428 14.7693 9.48891 15.0469 9.48891C15.3245 9.48891 15.5491 9.26428 15.5491 8.98668C15.5491 8.15585 16.225 7.47998 17.0558 7.47998C17.3334 7.47998 17.558 7.25535 17.558 6.97775C17.558 6.70015 17.3334 6.47552 17.0558 6.47552C16.225 6.47552 15.5491 5.76616 15.5491 4.93534C15.5491 4.65774 15.3245 4.43311 15.0469 4.43311C14.7693 4.43311 14.5446 4.65774 14.5446 4.93534C14.5446 5.76616 13.8688 6.47552 13.0379 6.47552C12.7603 6.47552 12.5357 6.70015 12.5357 6.97775C12.5357 7.25535 12.7603 7.47998 13.0379 7.47998Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>New
                                        </div>
                                    </div>
                                    <div class="click rounded-3 overflow-hidden mb-0">
                                        <div>
                                            <a href="single-property-1.html">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/globalhome-c6c4b.appspot.com/o/renovation%2F1103379411826446687_living_4.webp?alt=media&token=30b905bf-3906-4639-b579-b3b384a64462"
                                                    class="img-fluid" alt="Bathroom Renovation Image" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="listing-caption-wrapper px-3">
                                <div class="listing-detail-wrapper">
                                    <div class="listing-short-detail-wrap">
                                        <div class="listing-short-detail">
                                            <div class="d-flex align-items-center">
                                                <span class="label bg-light-danger text-success prt-type me-2">Soft
                                                    Colour</span><span
                                                    class="label bg-light-purple text-purple property-cats">Villa</span>
                                            </div>
                                            <h4 class="listing-name fw-semibold fs-5 mb-1"><a
                                                    href="single-property-1.html">The Donald Dwelling</a></h4>
                                            <div class="prt-location text-muted-2">
                                                <span class="svg-icon svg-icon-2hx">
                                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3"
                                                            d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                                            fill="currentColor" />
                                                        <path
                                                            d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                                            fill="currentColor" />
                                                    </svg>
                                                </span>
                                                210 Zirak Road, Canada
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="price-features-wrapper">
                                    <div class="list-fx-features d-flex align-items-center justify-content-between">
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-building-shield fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">3BHK</span>
                                        </div>
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-bed fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">3 Beds</span>
                                        </div>
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-clone fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">1800 SQFT</span>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="listing-detail-footer d-flex align-items-center justify-content-between py-4">
                                    <div class="listing-short-detail-flex">
                                        <h6 class="listing-card-info-price m-0" style="color: #000;">$80,000</h6>
                                        <!-- Price text in black -->
                                    </div>
                                    <div class="footer-flex">
                                        <a href="property-detail.html" class="prt-view">
                                            <span class="svg-icon text-gold svg-icon-2hx">
                                                <!-- Custom class for gold color -->
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
                                                        fill="currentColor" />
                                                    <path opacity="0.3"
                                                        d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <!-- End Single Property -->

                    <!-- Single Property -->
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="property-listing card border-0 rounded-3">

                            <div class="listing-img-wrapper p-3">
                                <div class="list-img-slide position-relative">
                                    <div class="position-absolute top-0 left-0 ms-3 mt-3 z-1">
                                        <div class="label d-inline-flex align-items-center justify-content-center"
                                            style="background-color: #FFD700; color: #000;">
                                            <span class="svg-icon text-dark svg-icon-2hx me-1">
                                                <svg width="14" height="14" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3"
                                                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                                        fill="currentColor" />
                                                    <path
                                                        d="M12.0006 11.1542C13.1434 11.1542 14.0777 10.22 14.0777 9.0771C14.0777 7.93424 13.1434 7 12.0006 7C10.8577 7 9.92348 7.93424 9.92348 9.0771C9.92348 10.22 10.8577 11.1542 12.0006 11.1542Z"
                                                        fill="currentColor" />
                                                    <path
                                                        d="M15.5652 13.814C15.5108 13.6779 15.4382 13.551 15.3566 13.4331C14.9393 12.8163 14.2954 12.4081 13.5697 12.3083C13.479 12.2993 13.3793 12.3174 13.3067 12.3718C12.9257 12.653 12.4722 12.7981 12.0006 12.7981C11.5289 12.7981 11.0754 12.653 10.6944 12.3718C10.6219 12.3174 10.5221 12.2902 10.4314 12.3083C9.70578 12.4081 9.05272 12.8163 8.64456 13.4331C8.56293 13.551 8.49036 13.687 8.43595 13.814C8.40875 13.8684 8.41781 13.9319 8.44502 13.9864C8.51759 14.1133 8.60828 14.2403 8.68991 14.3492C8.81689 14.5215 8.95295 14.6757 9.10715 14.8208C9.23413 14.9478 9.37925 15.0657 9.52439 15.1836C10.2409 15.7188 11.1026 15.9999 11.9915 15.9999C12.8804 15.9999 13.7421 15.7188 14.4586 15.1836C14.6038 15.0748 14.7489 14.9478 14.8759 14.8208C15.021 14.6757 15.1661 14.5215 15.2931 14.3492C15.3838 14.2312 15.4655 14.1133 15.538 13.9864C15.5833 13.9319 15.5924 13.8684 15.5652 13.814Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>Top Favourites
                                        </div>
                                    </div>
                                    <div class="click rounded-3 overflow-hidden mb-0">
                                        <div>
                                            <a href="single-property-1.html">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/globalhome-c6c4b.appspot.com/o/renovation%2F1103379411826446687_living_4.webp?alt=media&token=30b905bf-3906-4639-b579-b3b384a64462"
                                                    class="img-fluid" alt="Bathroom Renovation Image" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="listing-caption-wrapper px-3">
                                <div class="listing-detail-wrapper">
                                    <div class="listing-short-detail-wrap">
                                        <div class="listing-short-detail">
                                            <div class="d-flex align-items-center">
                                                <span class="label bg-light-danger text-success prt-type me-2">Soft
                                                    Colour</span><span
                                                    class="label bg-light-purple text-purple property-cats">Building</span>
                                            </div>
                                            <h4 class="listing-name fw-semibold fs-5 mb-1"><a
                                                    href="single-property-1.html">Red Tiny Hearst Castle</a></h4>
                                            <div class="prt-location text-muted-2">
                                                <span class="svg-icon svg-icon-2hx">
                                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3"
                                                            d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                                            fill="currentColor" />
                                                        <path
                                                            d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                                            fill="currentColor" />
                                                    </svg>
                                                </span>
                                                210 Zirak Road, Canada
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="price-features-wrapper">
                                    <div class="list-fx-features d-flex align-items-center justify-content-between">
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-building-shield fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">3BHK</span>
                                        </div>
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-bed fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">3 Beds</span>
                                        </div>
                                        <div class="listing-card d-flex align-items-center">
                                            <div class="square--25 text-muted-2 fs-sm circle gray-simple me-1"><i
                                                    class="fa-solid fa-clone fs-xs"></i></div><span
                                                class="text-muted-2 fs-sm">1800 SQFT</span>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="listing-detail-footer d-flex align-items-center justify-content-between py-4">
                                    <div class="listing-short-detail-flex">
                                        <h6 class="listing-card-info-price m-0" style="color: #000;">$80,000</h6>
                                        <!-- Price text in black -->
                                    </div>
                                    <div class="footer-flex">
                                        <a href="property-detail.html" class="prt-view">
                                            <span class="svg-icon text-gold svg-icon-2hx">
                                                <!-- Custom class for gold color -->
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
                                                        fill="currentColor" />
                                                    <path opacity="0.3"
                                                        d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <!-- End Single Property -->

                </div>

                <!-- Pagination -->
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <ul class="pagination p-center">
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Previous">
                                    <i class="fa-solid fa-arrow-left-long"></i>
                                    <span class="sr-only">Previous</span>
                                </a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item active"><a class="page-link" href="#">3</a></li>
                            <li class="page-item"><a class="page-link" href="#">...</a></li>
                            <li class="page-item"><a class="page-link" href="#">18</a></li>
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                    <i class="fa-solid fa-arrow-right-long"></i>
                                    <span class="sr-only">Next</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </section>
        <!-- =========================== All Property =============================== -->
        <!-- ============================ Call To Action ================================== -->
        <PageFooter />
        <!-- ============================ Call To Action End ================================== -->
        <!-- ============================ Footer Start ================================== -->
        <!-- ============================ Footer End ================================== -->

    </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';
import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import notfoundImage from '@/assets/img/404.png';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';

export default {
    name: 'NotFound',
    components: {
        Navigation,
        PageFooter,
    },
    data() {
        return {
            addProperty,
            menuWidget,
            profileLogo,
            globalHomeLogo,
            whatsappLogo,
            wechatLogo,
            loginLogo,
            settingsLogo,
            qrCodeLogo,
            notfoundImage,
            navMenuVisible: false,
            activeSubmenu: null,
            properties: [],
            message: 'Hello, World!',
            userLoggedIn: false, // Add a property to track login state
            loginError: '',
        };
    },
    methods: {
        toggleNavMenu() {
            this.navMenuVisible = !this.navMenuVisible;
        },
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
    },
};
</script>

<style scoped>
.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
    background-color: white;
    /* Set background color to white */
    color: #000000;
    /* Set icon color */
    /* padding: 5px; */
    /* Adjust padding to ensure icon is centered */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the icon */
    width: 50px;
    /* Set a fixed width for the button */
    height: 50px;
    /* Set a fixed height for the button */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
    /* Add space between list items */
}

.add-listing a {
    border-radius: 8px;
    /* Optional: Add some border radius for better visual spacing */
    text-align: center;
    /* Center align the text and image */
    white-space: nowrap;
    /* Ensure text doesn't wrap to the next line */
}

.d-flex {
    flex-wrap: nowrap;
    /* Prevent items from wrapping onto the next line */
}

.bg-danger {
    background-color: #d9534f;
    /* Ensure consistent background color */
    color: white;
    /* Text color for contrast */
}

a img {
    vertical-align: middle;
    /* Align the images with text */
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    /* Ensures it stays on top of other elements */
    background-color: white;
    /* Keep the background color to avoid transparency issues */
}

#main-wrapper {
    padding-top: 70px;
    /* Adjust this based on your header's height */
}

/* Hide mobile navigation by default */
.mobile_nav {
    display: none;
}

/* Show mobile navigation and hide main navigation on smaller screens */
@media (max-width: 767px) {
    .nav-menus-wrapper {
        display: none;
    }

    .mobile_nav {
        display: block;
    }
}

/* Optional: Add a box-shadow to the fixed navigation for better visual appeal */
.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left: 40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 150px;
    margin-left: 50px;
}
</style>
