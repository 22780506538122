<template>
    <div :key="$i18n.locale" class="yellow-skin">
        <div id="main-wrapper">
            <!-- Start Navigation -->
            <Navigation />
            <!-- ============================ End Navigation ================================== -->
            <div class="clearfix"></div>
            <!-- ============================ Page Title Start================================== -->
            <div class="page-title" :style="{ background: `#f4f4f4 url(${bgImage})` }" data-overlay="5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">

                            <div class="breadcrumbs-wrap position-relative z-1">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item active" aria-current="page">{{ $t('services.our_services') }}</li>
                                </ol>
                                <h2 class="breadcrumb-title">{{ $t('services.title') }}</h2>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- ============================ Page Title End ================================== -->
            <!-- ============================ Our Awards Start ================================== -->
            <section class="p-0">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-12 col-lg-12 col-md-12">

                            <div class="_awards_group">
                                <ul class="_awards_lists four">
                                    <!-- single list -->
                                    <li>
                                        <div class="_awards_list_wrap">
                                            <div class="_awards_list_caption">
                                                <h5>{{ $t('services.greece_property_management') }}</h5>
                                                <span>{{ $t('services.existing_clients') }}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <!-- single list -->
                                    <li>
                                        <div class="_awards_list_wrap">
                                            <div class="_awards_list_caption">
                                                <h5>{{ $t('services.malaysia_property_investment') }}</h5>
                                                <span>{{ $t('services.more_listings') }}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <!-- single list -->
                                    <li>
                                        <div class="_awards_list_wrap">
                                            <!-- <div class="_awards_list_thumb op-1"><img :src="singaporeFlag"
                                                    class="img-fluid" alt="" /></div> -->
                                            <div class="_awards_list_caption">
                                                <h5>{{ $t('services.singapore_property_investment') }}</h5>
                                                <span>{{ $t('services.more_listings') }}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <!-- single list -->
                                    <li>
                                        <div class="_awards_list_wrap">
                                            <!-- <div class="_awards_list_thumb op-1"><img :src="mortgageIcon"
                                                    class="img-fluid" alt="" /></div> -->
                                            <div class="_awards_list_caption">
                                                <h5>{{ $t('services.global_visa_services') }}</h5>
                                                <span>{{ $t('services.successful_visa_cases') }}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <!-- ============================ Our Awards End ================================== -->
            <!-- ============================ List Tag Start ================================== -->
            <section>
                <div class="container">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="eplios_tags">
                                <div class="tags-1">01</div>
                                <h2 class="mb-4">{{ $t('services.global_real_estate_investment') }}</h2>
                                <p>{{ $t('services.real_estate_description') }}</p>
                                <a href="#" class="btn exliou btn-danger mt-5">{{ $t('services.find_properties') }}</a>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12">
                            <div class="text-center">
                                <img :src="globalRealEstate" class="img-fluid" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <!-- ============================ Property Tag End ================================== -->

            <!-- ============================ List Tag Start ================================== -->
            <section class="pt-0">
                <div class="container">
                    <div class="row align-items-center justify-content-between">

                        <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12">
                            <div class="text-center">
                                <img :src="globalVisa" class="img-fluid" alt="" />
                            </div>
                        </div>

                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="eplios_tags right">
                                <div class="tags-2">02</div>
                                <h2 class="mb-4">{{ $t('services.immigration_planning_services') }}</h2>
                                <p>{{ $t('services.immigration_description') }}</p>
                                <ul class="eplios_list mt-5">
                                    <li>{{ $t('services.global_visa_services') }}</li>
                                    <li>{{ $t('services.citizenship_by_investment') }}</li>
                                    <li>{{ $t('services.high_net_worth_investor') }}</li>
                                    <li>{{ $t('services.dual_citizenship') }}</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <!-- ============================ Property Tag End ================================== -->
            <!-- ============================ List Tag Start ================================== -->
            <section>
                <div class="container">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="eplios_tags">
                                <div class="tags-1">03</div>
                                <h2 class="mb-4">{{ $t('services.asset_allocation') }}</h2>
                                <p>{{ $t('services.asset_allocation_description') }}</p>
                                <a href="#" class="btn exliou btn-danger mt-5">{{ $t('services.contact_us_now') }}</a>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12">
                            <div class="text-center">
                                <img :src="wealthAsset" class="img-fluid" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <!-- ============================ Property Tag End ================================== -->

            <!-- ============================ Our Partner Start ================================== -->
            <section class="bg-cover" style="background:#a70a29 url(assets/img/curve.svg)no-repeat">
                <div class="container">

                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-10 col-sm-12">
                            <div class="reio_o9i text-center mb-5">
                                <h2 class="text-light">{{ $t('services.connect_through_apps') }}</h2>
                                <p class="text-light">{{ $t('services.app_description') }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-lg-9 col-md-10 col-sm-12 flex-wrap justify-content-center text-center">
                            <div class="pertner_flexio">
                                <img src="assets/img/c-1.png" class="img-fluid" alt="" />
                                <h5>{{ $t('services.official_website') }}</h5>
                            </div>
                            <div class="pertner_flexio">
                                <img src="assets/img/c-2.png" class="img-fluid" alt="" />
                                <h5>{{ $t('services.google_play_store') }}</h5>
                            </div>
                            <div class="pertner_flexio">
                                <img src="assets/img/c-3.png" class="img-fluid" alt="" />
                                <h5>{{ $t('services.ios_app') }}</h5>
                            </div>
                            <div class="pertner_flexio">
                                <img src="assets/img/c-4.png" class="img-fluid" alt="" />
                                <h5>{{ $t('services.wechat_mini_program') }}</h5>
                            </div>

                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <p></p>
                        <p></p>
                        <p></p>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-10 col-sm-12">
                            <div class="reio_o9i text-center mb-5">
                                <h2 class="text-light">{{ $t('services.connect_through_social_media') }}</h2>
                                <p class="text-light">{{ $t('services.social_media_description') }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-lg-9 col-md-10 col-sm-12 flex-wrap justify-content-center text-center">
                            <div class="pertner_flexio">
                                <img src="assets/img/c-1.png" class="img-fluid" alt="" />
                                <h5>Facebook</h5>
                            </div>
                            <div class="pertner_flexio">
                                <img src="assets/img/c-2.png" class="img-fluid" alt="" />
                                <h5>Tiktok</h5>
                            </div>
                            <div class="pertner_flexio">
                                <img src="assets/img/c-3.png" class="img-fluid" alt="" />
                                <h5>{{ $t('services.xiaohongshu') }}</h5>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="ht-110"></div>
            </section>
            <!-- ============================ Our Partner End ================================== -->

            <!-- ============================ Property Location ================================== -->
            <section>
                <div class="container">

                    <div class="row justify-content-center">
                        <div class="col-lg-7 col-md-8">
                            <div class="sec-heading center">
                                <h2>{{ $t('services.explore_by_countries') }}</h2>
                                <p>{{ $t('services.countries_description') }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center g-4">

                        <!-- Single Location -->
                        <div class="col-lg-4 col-md-4 col-sm-6">
                            <a href="grid-layout-with-sidebar.html" class="img-wrap style-2">
                                <div class="location_wrap_content visible">
                                    <div class="location_wrap_content_first">
                                        <h4>{{ $t('services.athens_greece') }}</h4>
                                        <!-- <ul>
                                            <li><span>12 Villas</span></li>
                                            <li><span>10 Apartments</span></li>
                                            <li><span>07 Offices</span></li>
                                        </ul> -->
                                    </div>
                                </div>
                                <div class="img-wrap-background" :style="{ backgroundImage: `url(${greeceScenery})` }">
                                </div>
                            </a>
                        </div>

                        <!-- Single Location -->
                        <div class="col-lg-4 col-md-4 col-sm-6">
                            <a href="grid-layout-with-sidebar.html" class="img-wrap style-2">
                                <div class="location_wrap_content visible">
                                    <div class="location_wrap_content_first">
                                        <h4>{{ $t('services.johor_bahru_malaysia') }}</h4>
                                        <!-- <ul>
                                            <li><span>12 Villas</span></li>
                                            <li><span>10 Apartments</span></li>
                                            <li><span>07 Offices</span></li>
                                        </ul> -->
                                    </div>
                                </div>
                                <div class="img-wrap-background" :style="{ backgroundImage: `url(${johorScenery})` }">
                                </div>
                            </a>
                        </div>

                        <!-- Single Location -->
                        <div class="col-lg-4 col-md-4 col-sm-6">
                            <a href="grid-layout-with-sidebar.html" class="img-wrap style-2">
                                <div class="location_wrap_content visible">
                                    <div class="location_wrap_content_first">
                                        <h4>{{ $t('services.singapore') }}</h4>
                                        <!-- <ul>
                                            <li><span>12 Villas</span></li>
                                            <li><span>10 Apartments</span></li>
                                            <li><span>07 Offices</span></li>
                                        </ul> -->
                                    </div>
                                </div>
                                <div class="img-wrap-background"
                                    :style="{ backgroundImage: `url(${singaporeScenery})` }">
                                </div>
                            </a>
                        </div>

                    </div>

                </div>
            </section>
            <!-- ============================ Property Location End ================================== -->
            <!-- ============================ How It Works Start ================================== -->
            <section>
                <div class="container">

                    <div class="row justify-content-center">
                        <div class="col-lg-7 col-md-8">
                            <div class="sec-heading center">
                                <h2>{{ $t('services.how_it_works') }}</h2>
                                <p>{{ $t('services.how_it_works_description') }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center g-4">

                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="wpk_process">
                                <div class="wpk_thumb">
                                    <div class="wpk_thumb_figure">
                                        <!-- Simple user icon from FontAwesome -->
                                        <i class="fas fa-user-plus fa-3x"></i>
                                    </div>
                                </div>
                                <div class="wpk_caption">
                                    <h4>{{ $t('services.create_an_account') }}</h4>
                                    <p>{{ $t('services.account_description') }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="wpk_process active">
                                <div class="wpk_thumb">
                                    <div class="wpk_thumb_figure">
                                        <!-- Simple search icon from FontAwesome -->
                                        <i class="fas fa-search fa-3x"></i>
                                    </div>
                                </div>
                                <div class="wpk_caption">
                                    <h4>{{ $t('services.find_and_search_property') }}</h4>
                                    <p>{{ $t('services.search_property_description') }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="wpk_process">
                                <div class="wpk_thumb">
                                    <div class="wpk_thumb_figure">
                                        <!-- Simple home icon from FontAwesome -->
                                        <i class="fas fa-home fa-3x"></i>
                                    </div>
                                </div>
                                <div class="wpk_caption">
                                    <h4>{{ $t('services.book_your_property') }}</h4>
                                    <p>{{ $t('services.book_property_description') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="clearfix"></div>
            <!-- ============================ How It Works End ================================== -->

            <!-- ============================ Property Tag Start ================================== -->
            <section class="image-cover" style="background:#122947 url(assets/img/slider-2.jpg) no-repeat;"
                data-overlay="2">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12">

                            <div class="tab_exclusive position-relative z-1">
                                <h2>{{ $t('services.searching_perfect_place') }}</h2>
                                <p>{{ $t('services.searching_description') }}</p>
                                <a href="#" class="btn exliou btn-danger">{{ $t('services.find_properties') }}</a>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <!-- ============================ Property Tag End ================================== -->

            <!-- ============================ Call To Action ================================== -->
            <PageFooter />
            <!-- ============================ Call To Action End ================================== -->
            <!-- ============================ Footer Start ================================== -->
            <!-- ============================ Footer End ================================== -->
        </div>
    </div>
</template>

<script>
import 'magnific-popup';

import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';

import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import sampleImage1 from '@/assets/img/p-4.webp';
import oldIcon from '@/assets/img/old.png';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';
import bgImage from '@/assets/img/bg.jpg';
import globalRealEstate from '@/assets/img/global_real_estate.webp';
import globalVisa from '@/assets/img/globalvisa.webp';
import wealthAsset from '@/assets/img/wealthasset.webp';
import greeceScenery from '@/assets/img/greece_scenery.webp';
import johorScenery from '@/assets/img/johor_scenery.webp';
import singaporeScenery from '@/assets/img/singapore_scenery.webp';

export default {
    name: 'GlobalahomeServices',
    components: {
        Navigation,
        PageFooter,
    },
    props: ['glb_id'],
    data() {
        return {
            greeceScenery,
            johorScenery,
            singaporeScenery,
            globalRealEstate,
            globalVisa,
            wealthAsset,
            bgImage,
            addProperty,
            menuWidget,
            profileLogo,
            globalHomeLogo,
            whatsappLogo,
            wechatLogo,
            loginLogo,
            settingsLogo,
            qrCodeLogo,
            sampleImage1,
            oldIcon,
            navMenuVisible: false,
            activeSubmenu: null,
            userLoggedIn: false,
            loginError: '',
        };
    },
    methods: {
        toggleNavMenu() {
            this.navMenuVisible = !this.navMenuVisible;
        },
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
    },
};
</script>

<style scoped>
.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
    background-color: white;
    /* Set background color to white */
    color: #000000;
    /* Set icon color */
    /* padding: 5px; */
    /* Adjust padding to ensure icon is centered */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the icon */
    width: 50px;
    /* Set a fixed width for the button */
    height: 50px;
    /* Set a fixed height for the button */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
}

.add-listing a {
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

.d-flex {
    flex-wrap: nowrap;
}

.bg-danger {
    background-color: #d9534f;
    color: white;
}

a img {
    vertical-align: middle;
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
}

#main-wrapper {
    padding-top: 70px;
}

.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.input-with-icon select.form-control {
    padding-bottom: 1px;
    /* Adjust the value as needed */
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left: 40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 70px;
    margin-left: 50px;
}
</style>
