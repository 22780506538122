<template>
	<div :key="$i18n.locale" class="yellow-skin">


		<!-- ============================================================== -->
		<!-- Preloader - style you can find in spinners.css -->
		<!-- ============================================================== -->
		<!-- <div class="preloader"></div> -->

		<!-- ============================================================== -->
		<!-- Main wrapper - style you can find in pages.scss -->
		<!-- ============================================================== -->
		<div id="main-wrapper">

			<!-- ============================================================== -->
			<!-- Top header  -->
			<!-- ============================================================== -->
			<!-- Start Navigation -->
			<Navigation />
			<!-- ============================ End Navigation ================================== -->

			<div class="clearfix"></div>
		</div>
		<!-- ============================ User Dashboard ================================== -->
		<section class="error-wrap">
			<div class="container">
				<div class="row justify-content-center">

					<div class="col-lg-6 col-md-10">
						<div class="text-center">

							<img :src="notfoundImage" class="img-fluid" alt="Globalahome Not Found">
							<p>{{ $t('not_found.not_found_text') }}
							</p>
							<a class="btn btn-danger" href="/">{{ $t('not_found.back_to_home') }}</a>

						</div>
					</div>

				</div>
			</div>
		</section>
		<!-- ============================ User Dashboard End ================================== -->
		<!-- ============================ Call To Action ================================== -->
		<PageFooter />
		<!-- ============================ Call To Action End ================================== -->
		<!-- ============================ Footer Start ================================== -->
		<!-- ============================ Footer End ================================== -->

	</div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';
import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import notfoundImage from '@/assets/img/404.png';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';

export default {
	name: 'NotFound',
	components: {
		Navigation,
		PageFooter,
	},
	data() {
		return {
			addProperty,
			menuWidget,
			profileLogo,
			globalHomeLogo,
			whatsappLogo,
			wechatLogo,
			loginLogo,
			settingsLogo,
			qrCodeLogo,
			notfoundImage,
			navMenuVisible: false,
			activeSubmenu: null,
			properties: [],
			message: 'Hello, World!',
			userLoggedIn: false, // Add a property to track login state
			loginError: '',
		};
	},
	methods: {
		toggleNavMenu() {
			this.navMenuVisible = !this.navMenuVisible;
		},
		toggleSubmenu(menu) {
			this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
		},
	}, 
};
</script>

<style scoped>
.nav-container {
	margin-bottom: 0px;
	display: flex;
	justify-content: flex-end;
	/* Aligns the button to the right */
}

.nav-dropdown-toggle {
	background-color: white;
	/* Set background color to white */
	color: #000000;
	/* Set icon color */
	/* padding: 5px; */
	/* Adjust padding to ensure icon is centered */
	border: none;
	cursor: pointer;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	/* Center the icon */
	width: 50px;
	/* Set a fixed width for the button */
	height: 50px;
	/* Set a fixed height for the button */
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	/* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
	margin-left: 0;
	/* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
	transition: max-height 0.3s ease-out;
}

.hello {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	text-align: center;
	color: #2c3e50;
	margin-top: 60px;
}

.add-listing {
	margin-right: 10px;
	/* Add space between list items */
}

.add-listing a {
	border-radius: 8px;
	/* Optional: Add some border radius for better visual spacing */
	text-align: center;
	/* Center align the text and image */
	white-space: nowrap;
	/* Ensure text doesn't wrap to the next line */
}

.d-flex {
	flex-wrap: nowrap;
	/* Prevent items from wrapping onto the next line */
}

.bg-danger {
	background-color: #d9534f;
	/* Ensure consistent background color */
	color: white;
	/* Text color for contrast */
}

a img {
	vertical-align: middle;
	/* Align the images with text */
}

.header.header-light {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;
	/* Ensures it stays on top of other elements */
	background-color: white;
	/* Keep the background color to avoid transparency issues */
}

#main-wrapper {
	padding-top: 70px;
	/* Adjust this based on your header's height */
}

/* Hide mobile navigation by default */
.mobile_nav {
	display: none;
}

/* Show mobile navigation and hide main navigation on smaller screens */
@media (max-width: 767px) {
	.nav-menus-wrapper {
		display: none;
	}

	.mobile_nav {
		display: block;
	}
}

/* Optional: Add a box-shadow to the fixed navigation for better visual appeal */
.header.header-light {
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
	display: inline-block;
	margin-left: 5px;
	transition: transform 0.3s;
}

.nav-menu li ul {
	display: none;
}

.nav-menu li ul.nav-submenu {
	display: block;
}

.nav-menu li.active>.submenu-indicator {
	transform: rotate(90deg);
}

.resp_log_thumb {
	margin-top: 100px;
	margin-left: 40px;
	width: 250px;
	height: 110px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.form-check-label {
	margin-left: 10px;
}

.tagline {
	margin-top: 150px;
	margin-left: 50px;
}
</style>
