// greeceListingStore.js
import { defineStore } from 'pinia';
import { db } from '../../firebase';
import { doc, getDoc, query, collection, where, getDocs } from 'firebase/firestore';

export const useGreeceListingStore = defineStore('greeceListingStore', {
    state: () => ({
        property: {},               // Single property details
        propertyImages: [],         // Images for the property
        similarProperties: [],      // List of similar properties
        isLoading: false,
    }),
    actions: {
        async fetchProperty(glb_id) {
            this.isLoading = true;
            try {
                const docRef = doc(db, "property_listings", glb_id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    this.property = docSnap.data();
                    this.propertyImages = this.property.image_link || [];
                } else {
                    console.log("No such property found!");
                }
            } catch (error) {
                console.error("Error fetching property:", error);
            } finally {
                this.isLoading = false;
            }
        },
        async fetchSimilarProperties(glb_id) {
            this.isLoading = true;
            try {
                const q = query(
                    collection(db, "property_listings"),
                    where("glb_id", ">=", "GLB_GR_"),
                    where("glb_id", "<", "GLB_GR_~")
                );
                const querySnapshot = await getDocs(q);
                this.similarProperties = querySnapshot.docs
                    .map(doc => doc.data())
                    .filter(property => property.glb_id !== glb_id);
            } catch (error) {
                console.error("Error fetching similar properties:", error);
            } finally {
                this.isLoading = false;
            }
        },
    },
});
