<template>
	<div :key="$i18n.locale" class="yellow-skin">

		<!-- This is the preloader -->
		<!-- <div class="preloader"></div> -->
		<div id="main-wrapper">
			<!-- Start Navigation -->
			<Navigation />
			<!-- ============================ End Navigation ================================== -->

			<div class="clearfix"></div>
			<!-- ============================ Page Title Start================================== -->
			<div class="page-title" :style="{ background: `#f4f4f4 url(${bgImage})` }" data-overlay="5">
				<div class="container">
					<div class="row">
						<div class="col-lg-12 col-md-12">

							<div class="breadcrumbs-wrap position-relative z-1">
								<ol class="breadcrumb">
									<li class="breadcrumb-item active" aria-current="page">{{
                                        $t('propertySubmit.advertiseYourProperty') }}</li>
								</ol>
								<h2 class="breadcrumb-title">{{
                                        $t('propertySubmit.submitYourProperty') }}</h2>
							</div>

						</div>
					</div>
				</div>
			</div>
			<!-- ============================ Page Title End ================================== -->

			<!-- ============================ Submit Property Start ================================== -->
			<section>

				<div class="container">
					<div class="row">
						<div class="col-lg-12 col-md-12">

							<div class="alert alert-danger" role="alert">
								<p class="m-0">If you don't have an account you can create one by <a href="#">Click
										Here</a>
								</p>
							</div>

						</div>

						<!-- Submit Form -->
						<div class="col-lg-12 col-md-12">

							<div class="submit-page p-0">

								<!-- Basic Information -->
								<div class="frm_submit_block">
									<h3>Basic Information</h3>
									<div class="frm_submit_wrap">
										<div class="form-row row">

											<div class="form-group col-md-12">
												<label>Property Title<a href="#" class="tip-topdata"
														data-tip="Property Title"><i class="ti-help"></i></a></label>
												<input type="text" class="form-control">
											</div>

											<div class="form-group col-md-6">
												<label>Status</label>
												<select id="status" class="form-control">
													<option value="">&nbsp;</option>
													<option value="1">For Rent</option>
													<option value="2">For Sale</option>
												</select>
											</div>

											<div class="form-group col-md-6">
												<label>Property Type</label>
												<select id="ptypes" class="form-control">
													<option value="">&nbsp;</option>
													<option value="1">Houses</option>
													<option value="2">Apartment</option>
													<option value="3">Villas</option>
													<option value="4">Commercial</option>
													<option value="5">Offices</option>
													<option value="6">Garage</option>
												</select>
											</div>

											<div class="form-group col-md-6">
												<label>Price</label>
												<input type="text" class="form-control" placeholder="USD">
											</div>

											<div class="form-group col-md-6">
												<label>Area</label>
												<input type="text" class="form-control">
											</div>

											<div class="form-group col-md-6">
												<label>Bedrooms</label>
												<select id="bedrooms" class="form-control">
													<option value="">&nbsp;</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
												</select>
											</div>

											<div class="form-group col-md-6">
												<label>Bathrooms</label>
												<select id="bathrooms" class="form-control">
													<option value="">&nbsp;</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
												</select>
											</div>

										</div>
									</div>
								</div>

								<!-- Gallery -->
								<div class="frm_submit_block">
									<h3>Gallery</h3>
									<div class="frm_submit_wrap">
										<div class="form-row row">

											<div class="form-group col-md-12">
												<label>Upload Gallery</label>
												<form action="https://shreethemes.net/upload-target"
													class="dropzone dz-clickable primary-dropzone">
													<div class="dz-default dz-message">
														<i class="ti-gallery"></i>
														<span>Drag & Drop To Change Logo</span>
													</div>
												</form>
											</div>

										</div>
									</div>
								</div>

								<!-- Property Video -->
								<div class="frm_submit_block">
									<h3>Property Video</h3>
									<div class="frm_submit_wrap">
										<div class="form-row row">

											<div class="form-group col-md-12">
												<label>Youtube Embed link</label>
												<input type="text" class="form-control">
											</div>
										</div>
									</div>
								</div>

								<!-- Location -->
								<div class="frm_submit_block">
									<h3>Location</h3>
									<div class="frm_submit_wrap">
										<div class="form-row row">

											<div class="form-group col-md-6">
												<label>Address</label>
												<input type="text" class="form-control">
											</div>

											<div class="form-group col-md-6">
												<label>City</label>
												<input type="text" class="form-control">
											</div>

											<div class="form-group col-md-6">
												<label>State</label>
												<input type="text" class="form-control">
											</div>

											<div class="form-group col-md-6">
												<label>Zip Code</label>
												<input type="text" class="form-control">
											</div>

										</div>
									</div>
								</div>

								<!-- Detailed Information -->
								<div class="frm_submit_block">
									<h3>Detailed Information</h3>
									<div class="frm_submit_wrap">
										<div class="form-row row">

											<div class="form-group col-md-12">
												<label>Description</label>
												<textarea class="form-control h-120"></textarea>
											</div>

											<div class="form-group col-md-4">
												<label>Building Age (optional)</label>
												<select id="bage" class="form-control">
													<option value="">&nbsp;</option>
													<option value="1">0 - 5 Years</option>
													<option value="2">0 - 10Years</option>
													<option value="3">0 - 15 Years</option>
													<option value="4">0 - 20 Years</option>
													<option value="5">20+ Years</option>
												</select>
											</div>

											<div class="form-group col-md-4">
												<label>Garage (optional)</label>
												<select id="garage" class="form-control">
													<option value="">&nbsp;</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
												</select>
											</div>

											<div class="form-group col-md-4">
												<label>Rooms (optional)</label>
												<select id="rooms" class="form-control">
													<option value="">&nbsp;</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
												</select>
											</div>

											<div class="form-group col-md-12">
												<label>Other Features (optional)</label>
												<div class="o-features">
													<ul class="no-ul-list third-row">
														<li>
															<input id="a-1" class="form-check-input" name="a-1"
																type="checkbox">
															<label for="a-1" class="form-check-label">Air
																Condition</label>
														</li>
														<li>
															<input id="a-2" class="form-check-input" name="a-2"
																type="checkbox">
															<label for="a-2" class="form-check-label">Bedding</label>
														</li>
														<li>
															<input id="a-3" class="form-check-input" name="a-3"
																type="checkbox">
															<label for="a-3" class="form-check-label">Heating</label>
														</li>
														<li>
															<input id="a-4" class="form-check-input" name="a-4"
																type="checkbox">
															<label for="a-4" class="form-check-label">Internet</label>
														</li>
														<li>
															<input id="a-5" class="form-check-input" name="a-5"
																type="checkbox">
															<label for="a-5" class="form-check-label">Microwave</label>
														</li>
														<li>
															<input id="a-6" class="form-check-input" name="a-6"
																type="checkbox">
															<label for="a-6" class="form-check-label">Smoking
																Allow</label>
														</li>
														<li>
															<input id="a-7" class="form-check-input" name="a-7"
																type="checkbox">
															<label for="a-7" class="form-check-label">Terrace</label>
														</li>
														<li>
															<input id="a-8" class="form-check-input" name="a-8"
																type="checkbox">
															<label for="a-8" class="form-check-label">Balcony</label>
														</li>
														<li>
															<input id="a-9" class="form-check-input" name="a-9"
																type="checkbox">
															<label for="a-9" class="form-check-label">Icon</label>
														</li>
														<li>
															<input id="a-10" class="form-check-input" name="a-10"
																type="checkbox">
															<label for="a-10" class="form-check-label">Wi-Fi</label>
														</li>
														<li>
															<input id="a-11" class="form-check-input" name="a-11"
																type="checkbox">
															<label for="a-11" class="form-check-label">Beach</label>
														</li>
														<li>
															<input id="a-12" class="form-check-input" name="a-12"
																type="checkbox">
															<label for="a-12" class="form-check-label">Parking</label>
														</li>
													</ul>
												</div>
											</div>

										</div>
									</div>
								</div>

								<!-- Contact Information -->
								<div class="frm_submit_block">
									<h3>Contact Information</h3>
									<div class="frm_submit_wrap">
										<div class="form-row row">

											<div class="form-group col-md-4">
												<label>Name</label>
												<input type="text" class="form-control">
											</div>

											<div class="form-group col-md-4">
												<label>Email</label>
												<input type="text" class="form-control">
											</div>

											<div class="form-group col-md-4">
												<label>Phone (optional)</label>
												<input type="text" class="form-control">
											</div>

										</div>
									</div>
								</div>

								<div class="form-group">
									<div class="col-lg-12 col-md-12">
										<button class="btn btn-danger" type="submit">Submit & Preview</button>
									</div>
								</div>

							</div>
						</div>

					</div>
				</div>

			</section>
			<!-- ============================ Submit Property End ================================== -->


			<!-- ============================ Call To Action ================================== -->
			<PageFooter />
			<!-- ============================ Call To Action End ================================== -->
			<!-- ============================ Footer Start ================================== -->
			<!-- ============================ Footer End ================================== -->
		</div>
	</div>
</template>

<script>
import 'magnific-popup';
import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';
import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import sampleImage1 from '@/assets/img/p-4.webp';
import oldIcon from '@/assets/img/old.png';
import greeceFlag from '@/assets/img/greece_flag.webp';
import spanyolFlag from '@/assets/img/spanyol_flag.webp';
import portugalFlag from '@/assets/img/portugal_flag.webp';
import ukFlag from '@/assets/img/united-kingdom.webp';
import italyFlag from '@/assets/img/italy.webp';
import usFlag from '@/assets/img/united-states.webp';
import cyprusFlag from '@/assets/img/cyprus_flag.webp';
import profileLogo from '@/assets/img/profile.png';
import bgImage from '@/assets/img/bg.jpg';
import menuWidget from '@/assets/img/grid-lines.png';

export default {
	name: 'SubmitPropertyForm',
	props: ['glb_id'],
	components: {
		Navigation,
		PageFooter,
	},
	data() {
		return {
			menuWidget,
			bgImage,
			profileLogo,
			globalHomeLogo,
			whatsappLogo,
			wechatLogo,
			loginLogo,
			settingsLogo,
			qrCodeLogo,
			sampleImage1,
			greeceFlag,
			spanyolFlag,
			portugalFlag,
			ukFlag,
			italyFlag,
			usFlag,
			cyprusFlag,
			oldIcon,
			activeSubmenu: null,
			navMenuVisible: false,
			userLoggedIn: false,
			loginError: '',
		};
	},
	methods: {
		toggleNavMenu() {
			this.navMenuVisible = !this.navMenuVisible;
		},
		toggleSubmenu(menu) {
			this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
		},
	},
};
</script>

<style scoped>
.nav-container {
	margin-bottom: 0px;
	display: flex;
	justify-content: flex-end;
	/* Aligns the button to the right */
}

.nav-dropdown-toggle {
	background-color: white;
	/* Set background color to white */
	color: #000000;
	/* Set icon color */
	/* padding: 5px; */
	/* Adjust padding to ensure icon is centered */
	border: none;
	cursor: pointer;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	/* Center the icon */
	width: 50px;
	/* Set a fixed width for the button */
	height: 50px;
	/* Set a fixed height for the button */
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	/* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
	margin-left: 0;
	/* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
	transition: max-height 0.3s ease-out;
}

.input-with-icon select.form-control {
	padding-bottom: 1px;
	/* Adjust the value as needed */
}

.hello {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	text-align: center;
	color: #2c3e50;
	margin-top: 60px;
}

.add-listing {
	margin-right: 10px;
}

.add-listing a {
	border-radius: 8px;
	text-align: center;
	white-space: nowrap;
}

.d-flex {
	flex-wrap: nowrap;
}

.bg-danger {
	background-color: #d9534f;
	color: white;
}

a img {
	vertical-align: middle;
}

.header.header-light {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;
	background-color: white;
}

#main-wrapper {
	padding-top: 70px;
}

.header.header-light {
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
	display: inline-block;
	margin-left: 5px;
	transition: transform 0.3s;
}

.nav-menu li ul {
	display: none;
}

.nav-menu li ul.nav-submenu {
	display: block;
}

.nav-menu li.active>.submenu-indicator {
	transform: rotate(90deg);
}

.resp_log_thumb {
	margin-top: 100px;
	margin-left: 40px;
	width: 250px;
	height: 110px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.form-check-label {
	margin-left: 10px;
}

.tagline {
	margin-top: 150px;
	margin-left: 50px;
}
</style>
