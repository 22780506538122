<template>
	<div :key="$i18n.locale" class="yellow-skin">

		<!-- ============================================================== -->
		<!-- Preloader - style you can find in spinners.css -->
		<!-- ============================================================== -->
		<!-- <div class="preloader"></div> -->

		<!-- ============================================================== -->
		<!-- Main wrapper - style you can find in pages.scss -->
		<!-- ============================================================== -->
		<div id="main-wrapper">

			<!-- ============================================================== -->
			<!-- Top header  -->
			<!-- ============================================================== -->
			<!-- Start Navigation -->
			<Navigation />
			<!-- ============================ End Navigation ================================== -->


			<div class="clearfix"></div>
		</div>
		<!-- ============================ Hero Banner  Start================================== -->
		<div class="image-cover hero_banner"
			:style="{ backgroundImage: `url(${require('@/assets/img/banner-home.webp')})` }" data-overlay="4">
			<div class="container">

				<h1 class="big-header-capt mb-0">{{ $t('banner.home_banner_header') }}</h1>
				<p class="text-center">{{ $t('banner.home_banner_text') }}</p>
				<!-- Type -->
				<div class="row justify-content-center mt-5">
					<div class="col-xl-12 col-lg-12 col-md-12">
						<div class="simple_tab_search center">
							<ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
								<li class="nav-item">
									<a href="#" class="nav-link" :class="{ active: selectedCategory === 'Buy' }"
										id="buy-tab" role="tab" aria-controls="buy" aria-selected="true"
										@click.prevent="updateCategory('Buy')">
										{{ $t('banner.buy') }}
									</a>
								</li>
								<li class="nav-item">
									<a href="#" class="nav-link" :class="{ active: selectedCategory === 'Rent' }"
										id="rent-tab" role="tab" aria-controls="rent" aria-selected="false"
										@click.prevent="updateCategory('Rent')">
										{{ $t('banner.rent') }}
									</a>
								</li>
							</ul>

						</div>
					</div>
					<div class="col-xl-10 col-lg-11 col-md-12">
						<div class="full_search_box nexio_search lightanic_search hero_search-radius modern">
							<div class="search_hero_wrapping">

								<div class="row">
									<div class="col-lg-4 col-md-3 col-sm-12">
										<div class="form-group">
											<label>{{ $t('banner.country') }}</label>
											<div class="input-with-icon">
												<select v-model="selectedLocation" class="form-control">
													<option value="">&nbsp;</option>
													<option value="Greece">{{ $t('banner.greece') }}</option>
													<option value="Malaysia">{{ $t('banner.malaysia') }}</option>
													<option value="Singapore">{{ $t('banner.singapore') }}</option>
												</select>
											</div>
										</div>
									</div>

									<div class="col-lg-3 col-md-3 col-sm-12">
										<div class="form-group">
											<label>{{ $t('banner.property_type') }}</label>
											<div class="input-with-icon">
												<select v-model="selectedPropertyType" class="form-control">
													<option value="">&nbsp;</option>
													<option value="Apartment">{{ $t('banner.apartment') }}</option>
													<option value="Condominium">{{ $t('banner.condominium') }}</option>
													<option value="Landed House">{{ $t('banner.landed_house') }}
													</option>
												</select>
											</div>
										</div>
									</div>

									<div class="col-lg-4 col-md-4 col-sm-12">
										<div class="form-group">
											<label>{{ $t('banner.price_range') }}</label>
											<div class="input-with-icon">
												<select v-model="selectedPriceRange" class="form-control">
													<option value="">&nbsp;</option>
													<option value="1">{{ $t('banner.less_than') }} 100,000</option>
													<option value="2">{{ $t('banner.less_than') }} 100,000 {{
														$t('banner.to') }} 200,000</option>
													<option value="3">{{ $t('banner.less_than') }} 200,000 {{
														$t('banner.to') }} 300,000</option>
													<option value="4">{{ $t('banner.less_than') }} 300,000 {{
														$t('banner.to') }} 400,000</option>
													<option value="5">{{ $t('banner.less_than') }} 400,000 {{
														$t('banner.to') }} 500,000</option>
													<option value="6">{{ $t('banner.less_than') }} 500,000 {{
														$t('banner.to') }} 600,000</option>
													<option value="7">{{ $t('banner.less_than') }} 600,000 {{
														$t('banner.to') }} 700,000</option>
													<option value="8">{{ $t('banner.less_than') }} 700,000 {{
														$t('banner.to') }} 800,000</option>
													<option value="9">{{ $t('banner.less_than') }} 800,000 {{
														$t('banner.to') }} 900,000</option>
													<option value="10">{{ $t('banner.less_than') }} 900,000 {{
														$t('banner.to') }} 1,000,000</option>
													<option value="11">{{ $t('banner.above') }} 1,000,000</option>
												</select>
											</div>
										</div>
									</div>

									<div class="col-lg-1 col-md-2 col-sm-12 small-padd">
										<div class="form-group none">
											<a href="#" @click.prevent="searchProperties"
												class="btn btn-danger full-width"><i class="fa fa-search"></i></a>
										</div>
									</div>
								</div>

							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		<!-- ============================ Hero Banner End ================================== -->
		<!-- ============================ Citizenship by Investment Start ================================== -->
		<section class="p-0">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-xl-11 col-lg-11 col-md-12">

						<div class="_awards_group">
							<ul class="_awards_lists">
								<!-- single list -->
								<li>
									<div class="_awards_list_wrap">
										<div class="d-flex align-items-center">
											<div class="rats-thumb">
												<a href="citizenship-by-investment"><img :src="citizenshipIcon"
														class="img-fluid" width="70"
														alt="Globalahome Citizenship by Investment">
												</a>
											</div>
											<div class="ploi ps-3">
												<a href="citizenship-by-investment">
													<h4>{{ $t('home.home_banner_citizenship') }}</h4>
												</a>
												<div class="d-flex align-items-center">
													<i class="fas fa-star text-warning me-1"></i>
													<i class="fas fa-star text-warning me-1"></i>
													<i class="fas fa-star text-warning me-1"></i>
													<i class="fas fa-star text-warning me-1"></i>
													<i class="fas fa-star text-warning"></i>
												</div>
												<div class="explio mt-1">
													<p class="m-0 text-muted">100 {{
														$t('home.home_banner_successful_apps') }}</p>
												</div>
											</div>
										</div>
									</div>
								</li>
								<!-- single list -->
								<li>
									<div class="_awards_list_wrap">
										<div class="d-flex align-items-center">
											<div class="rats-thumb">
												<a href="global-visa"><img :src="globalVisaIcon" class="img-fluid"
														width="70" alt="Globalahome Global Visa Services">
												</a>
											</div>
											<div class="ploi ps-3">
												<a href="global-visa">
													<h4>{{ $t('home.home_banner_global_visa') }}</h4>
												</a>
												<div class="d-flex align-items-center">
													<i class="fas fa-star text-warning me-1"></i>
													<i class="fas fa-star text-warning me-1"></i>
													<i class="fas fa-star text-warning me-1"></i>
													<i class="fas fa-star text-warning me-1"></i>
													<i class="fas fa-star text-warning"></i>
												</div>
												<div class="explio mt-1">
													<p class="m-0 text-muted">100 {{
														$t('home.home_banner_successful_apps') }}</p>
												</div>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>

					</div>
				</div>
			</div>
		</section>
		<!-- ============================ Our Awards End ================================== -->
		<!-- ============================ Property Type Start ================================== -->
		<section class="white-simple">
			<div class="container">

				<div class="row">
					<div class="col-lg-12 col-md-12">
						<div class="sec-heading center">
							<h2>{{ $t('home.featured_property') }}</h2>
							<p>{{ $t('home.featured_property_title') }}</p>
						</div>
					</div>
				</div>

				<div class="row justify-content-center g-4">

					<div class="col-lg col-md-4">
						<!-- Single Category -->
						<div class="property_cats_boxs">
							<a href="grid-layout-with-sidebar.html" class="category-box">
								<div class="property_category_short">
									<div class="category-icon clip-1">
										<i class="flaticon-beach-house-2"></i>
									</div>

									<div class="property_category_expand property_category_short-text">
										<h4>{{ $t('home.family_house') }}</h4>
										<p>122 {{ $t('home.property') }}</p>
									</div>
								</div>
							</a>
						</div>
					</div>

					<div class="col-lg col-md-4">
						<!-- Single Category -->
						<div class="property_cats_boxs">
							<a href="grid-layout-with-sidebar.html" class="category-box">
								<div class="property_category_short">
									<div class="category-icon clip-2">
										<i class="flaticon-cabin"></i>
									</div>

									<div class="property_category_expand property_category_short-text">
										<h4>{{ $t('home.house_villa') }}</h4>
										<p>155 {{ $t('home.property') }}</p>
									</div>
								</div>
							</a>
						</div>
					</div>

					<div class="col-lg col-md-4">
						<!-- Single Category -->
						<div class="property_cats_boxs">
							<a href="grid-layout-with-sidebar.html" class="category-box">
								<div class="property_category_short">
									<div class="category-icon clip-3">
										<i class="flaticon-apartments"></i>
									</div>

									<div class="property_category_expand property_category_short-text">
										<h4>{{ $t('home.apartment') }}</h4>
										<p>300 {{ $t('home.property') }}</p>
									</div>
								</div>
							</a>
						</div>
					</div>

					<div class="col-lg col-md-4">
						<!-- Single Category -->
						<div class="property_cats_boxs">
							<a href="grid-layout-with-sidebar.html" class="category-box">
								<div class="property_category_short">
									<div class="category-icon clip-4">
										<i class="flaticon-student-housing"></i>
									</div>

									<div class="property_category_expand property_category_short-text">
										<h4>{{ $t('home.office_studio') }}</h4>
										<p>80 {{ $t('home.property') }}</p>
									</div>
								</div>
							</a>
						</div>
					</div>

					<div class="col-lg col-md-4">
						<!-- Single Category -->
						<div class="property_cats_boxs">
							<a href="grid-layout-with-sidebar.html" class="category-box">
								<div class="property_category_short">
									<div class="category-icon clip-5">
										<i class="flaticon-modern-house-4"></i>
									</div>

									<div class="property_category_expand property_category_short-text">
										<h4>{{ $t('home.villa_condo') }}</h4>
										<p>80 {{ $t('home.property') }}</p>
									</div>
								</div>
							</a>
						</div>
					</div>

				</div>
			</div>
		</section>
		<!-- ============================ Property Type End ================================== -->
		<!-- ============================ Greece Properties Start ================================== -->
		<section class="pt-0">
			<div class="container">

				<div class="row justify-content-center">
					<div class="col-lg-7 col-md-8">
						<div class="sec-heading center">
							<h2>{{ $t('home.greece_featured') }}</h2>
							<p>{{ $t('home.greece_featured_text') }}</p>
						</div>
					</div>
				</div>

				<div class="row g-4 justify-content-center">
					<div v-for="(property, index) in properties" :key="index" class="col-lg-4 col-md-6 col-sm-12">
						<div class="property-listing property-2 h-100">
							<div class="listing-img-wrapper">
								<div class="_exlio_125">{{ property.price ? 'For Sale' : 'For Rent' }}</div>
								<div class="list-img-slide">
									<div class="click">
										<div v-for="(image, imgIndex) in property.image_link.slice(0, 3)"
											:key="imgIndex">
											<router-link
												:to="{ name: getRouteNameByGlbId(property.glb_id), params: { glb_id: property.glb_id } }">
												<img :src="image" class="img-fluid mx-auto" alt="" />
											</router-link>
										</div>
									</div>
								</div>
							</div>

							<div class="listing-detail-wrapper">
								<div class="listing-short-detail-wrap">
									<div class="_card_list_flex mb-2">
										<div class="_card_flex_01">
											<span class="_list_blickes _netork" style="margin-right: 10px;">{{
												property.property_type }}</span>
											<span class="_list_blickes types">{{ property.city }} {{ property.country
												}}</span>
										</div>
										<div class="_card_flex_last">
											<h6 class="listing-card-info-price mb-0">{{ formatPriceEuro(property.price) }}</h6>
										</div>
									</div>
									<div class="_card_list_flex">
										<div class="_card_flex_01">
											<h4 class="listing-name verified">
												<router-link
													:to="{ name: getRouteNameByGlbId(property.glb_id), params: { glb_id: property.glb_id } }"
													class="prt-link-detail">
													{{ property.property_name }} {{ property.area_address }}
												</router-link>
											</h4>
										</div>
									</div>
								</div>
							</div>

							<div class="price-features-wrapper">
								<div class="list-fx-features">
									<div class="listing-card-info-icon">
										<div class="inc-fleat-icon"><img :src="bedSvg" width="15"
												alt="Globalahome Bedroom" /></div>
										{{ property.bedroom }} {{ $t('home.beds') }}
									</div>
									<div class="listing-card-info-icon">
										<div class="inc-fleat-icon"><img :src="bathtubSvg" width="15"
												alt="Globalahome Bathroom" /></div>
										{{ property.bathroom }} {{ $t('home.bath') }}
									</div>
									<div class="listing-card-info-icon">
										<div class="inc-fleat-icon"><img :src="moveSvg" width="15"
												alt="Globalahome Property Surface Area" /></div>
										{{convertSurfaceAreaSqm(property.surface_area) }}
									</div>
								</div>
							</div>

							<div class="listing-detail-footer">
								<div class="footer-first">
									<div class="foot-location">
										<img :src="pinSvg" width="18" alt="Globalahome Property Location" />
										{{ property.area_address }} {{ property.country }}
									</div>
								</div>
								<div class="footer-flex">
									<ul class="selio_style">
										<li>
											<div class="prt_saveed_12lk">
												<label class="toggler toggler-danger" data-bs-toggle="tooltip" data-bs-placement="top" 
													:class="{ 'active': isSaved(property.glb_id) }"
													data-bs-title="Save property">
													<input type="checkbox" :checked="isSaved(property.glb_id)" @change="toggleSaveProperty(property)">
													<i class="fa-solid fa-heart"></i>
                                                </label>
                                            </div>
										</li>
										<li>
											<div class="prt_saveed_12lk">
												<a href="/compare-property" data-bs-toggle="tooltip"
													data-bs-placement="top" data-bs-title="Compare property">
													<i class="fa-solid fa-share"></i>
												</a>
											</div>
										</li>
										<li>
											<div class="prt_saveed_12lk">
												<router-link
													:to="{ name: getRouteNameByGlbId(property.glb_id), params: { glb_id: property.glb_id } }"
													data-bs-toggle="tooltip" data-bs-placement="top"
													data-bs-title="View Property">
													<i class="fa-regular fa-circle-right"></i>
												</router-link>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- ============================ Greece Properties End ================================== -->

		<!-- ============================ Malaysia Properties Start ================================== -->
		<section class="pt-0">
			<div class="container">

				<div class="row justify-content-center">
					<div class="col-lg-7 col-md-8">
						<div class="sec-heading center">
							<h2>{{ $t('home.malaysia_featured') }}</h2>
							<p>{{ $t('home.malaysia_featured_text') }}</p>
						</div>
					</div>
				</div>

				<div class="row g-4 justify-content-center">
					<div v-for="(property, index) in my_properties" :key="index" class="col-lg-4 col-md-6 col-sm-12">
						<div class="property-listing property-2 h-100">
							<div class="listing-img-wrapper">
								<div class="_exlio_125">{{ property.price ? 'For Sale' : 'For Rent' }}</div>
								<div class="list-img-slide">
									<div class="click">
										<div v-for="(image, imgIndex) in property.image_link.slice(0, 3)"
											:key="imgIndex">
											<router-link
												:to="{ name: getRouteNameByGlbId(property.glb_id), params: { glb_id: property.glb_id } }">
												<img :src="image" class="img-fluid mx-auto" alt="" />
											</router-link>
										</div>
									</div>
								</div>
							</div>

							<div class="listing-detail-wrapper">
								<div class="listing-short-detail-wrap">
									<div class="_card_list_flex mb-2">
										<div class="_card_flex_01">
											<span class="_list_blickes _netork" style="margin-right: 10px;">{{
												property.property_type }}</span>
											<span class="_list_blickes types">{{ property.city }}</span>
										</div>
										<div class="_card_flex_last">
											<h6 class="listing-card-info-price mb-0">{{ formatPriceMyr(property.price) }}</h6>
										</div>
									</div>
									<div class="_card_list_flex">
										<div class="_card_flex_01">
											<h4 class="listing-name verified">
												<router-link
													:to="{ name: getRouteNameByGlbId(property.glb_id), params: { glb_id: property.glb_id } }"
													class="prt-link-detail">
													{{ property.property_name }} {{ property.area_address }}
												</router-link>
											</h4>
										</div>
									</div>
								</div>
							</div>

							<div class="price-features-wrapper">
								<div class="list-fx-features">
									<div class="listing-card-info-icon">
										<div class="inc-fleat-icon"><img :src="bedSvg" width="15"
												alt="Globalahome Bedroom" /></div>
										1 {{ $t('home.beds') }}
									</div>
									<div class="listing-card-info-icon">
										<div class="inc-fleat-icon"><img :src="bedSvg" width="15"
												alt="Globalahome Bedroom" /></div>
										2 {{ $t('home.beds') }}
									</div>
									<div class="listing-card-info-icon">
										<div class="inc-fleat-icon"><img :src="bedSvg" width="15"
												alt="Globalahome Bedroom" /></div>
										3 {{ $t('home.beds') }}
									</div>
								</div>
							</div>

							<div class="listing-detail-footer">
								<div class="footer-first">
									<div class="foot-location">
										<img :src="pinSvg" width="18" alt="Globalahome Property Location" />
										{{ property.area_address }}
									</div>
								</div>
								<div class="footer-flex">
									<ul class="selio_style">
										<li>
											<div class="prt_saveed_12lk">
												<label class="toggler toggler-danger" data-bs-toggle="tooltip" data-bs-placement="top" 
													:class="{ 'active': isSaved(property.glb_id) }"
													data-bs-title="Save property">
													<input type="checkbox" :checked="isSaved(property.glb_id)" @change="toggleSaveProperty(property)">
													<i class="fa-solid fa-heart"></i>
                                                </label>
                                            </div>
										</li>
										<li>
											<div class="prt_saveed_12lk">
												<a href="/compare-property" data-bs-toggle="tooltip"
													data-bs-placement="top" data-bs-title="Compare property">
													<i class="fa-solid fa-share"></i>
												</a>
											</div>
										</li>
										<li>
											<div class="prt_saveed_12lk">
												<router-link
													:to="{ name: getRouteNameByGlbId(property.glb_id), params: { glb_id: property.glb_id } }"
													data-bs-toggle="tooltip" data-bs-placement="top"
													data-bs-title="View Property">
													<i class="fa-regular fa-circle-right"></i>
												</router-link>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- ============================ Malaysia Properties End ================================== -->
		<!-- ============================ Citizenship by Investment Start ================================== -->
		<section class="gray-simple min">
			<div class="container">

				<div class="row justify-content-center">
					<div class="col-lg-7 col-md-8">
						<div class="sec-heading center">
							<h2>{{ $t('home.citizenship_investment') }}</h2>
							<p>{{ $t('home.citizenship_investment_text') }}</p>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-lg-12 col-md-12">
						<div class="item-slide space">

							<!-- Single Item -->
							<div class="single_items">
								<div class="grid_agents">
									<div class="elio_mx_list theme-bg-2">102 {{ $t('home.listings') }}</div>
									<div class="grid_agents-wrap">

										<div class="fr-grid-thumb">
											<a href="#">
												<span class="verified"><img :src="verifiedSvg" class="verify mx-auto"
														alt="Globalahome Citizenship by Investment"></span>
												<img :src="dominica_flag" class="img-fluid mx-auto"
													alt="Globalahome Dominica Citizenship by Investment">
											</a>
										</div>

										<div class="fr-grid-deatil">
											<span><i class="ti-location-pin me-1"></i>{{ $t('home.dominica') }}</span>
											<h5 class="fr-can-name"><a href="#">{{ $t('home.dominica_title') }}</a>
											</h5>
											<p>{{ $t('home.dominica_description') }}</p>
										</div>

										<div class="fr-infos-deatil">
											<a href="citizenship-by-investment" data-bs-toggle="modal"
												data-bs-target="#autho-message" class="btn btn-danger"><i
													class="fa fa-search me-2"></i>{{ $t('home.find_out_more') }}</a>
											<a href="citizenship-by-investment" class="btn btn-dark"><i
													class="fa fa-phone"></i></a>
										</div>

									</div>

								</div>
							</div>

							<!-- Single Item -->
							<div class="single_items">
								<div class="grid_agents">
									<div class="elio_mx_list theme-bg-2">72 {{ $t('home.listings') }}</div>
									<div class="grid_agents-wrap">

										<div class="fr-grid-thumb">
											<a href="#">
												<span class="verified"><img :src="verifiedSvg" class="verify mx-auto"
														alt="Globalahome Citizenship by Investment"></span>
												<img :src="north_macedonia" class="img-fluid mx-auto"
													alt="Globalahome North Macedonia Citizenship"> </a>
										</div>

										<div class="fr-grid-deatil">
											<span><i class="ti-location-pin me-1"></i>{{ $t('home.north_macedonia')
												}}</span>
											<h5 class="fr-can-name"><a href="#">{{ $t('home.north_macedonia_title')
													}}</a></h5>
											<p>{{ $t('home.north_macedonia_description') }}</p>
										</div>

										<div class="fr-infos-deatil">
											<a href="citizenship-by-investment" data-bs-toggle="modal"
												data-bs-target="#autho-message" class="btn btn-danger"><i
													class="fa fa-search me-2"></i>{{ $t('home.find_out_more') }}</a>
											<a href="citizenship-by-investment" class="btn btn-dark"><i
													class="fa fa-phone"></i></a>
										</div>

									</div>

								</div>
							</div>

							<!-- Single Item -->
							<div class="single_items">
								<div class="grid_agents">
									<div class="elio_mx_list theme-bg-2">22 {{ $t('home.listings') }}</div>
									<div class="grid_agents-wrap">

										<div class="fr-grid-thumb">
											<a href="#">
												<span class="verified"><img :src="verifiedSvg" class="verify mx-auto"
														alt="Globalahome Citizenship by Investment"></span>
												<img :src="grenadaFlag" class="img-fluid mx-auto"
													alt="Globalahome Grenada Citizeship">
											</a>
										</div>

										<div class="fr-grid-deatil">
											<span><i class="ti-location-pin me-1"></i>{{ $t('home.grenada') }}</span>
											<h5 class="fr-can-name"><a href="#">{{ $t('home.grenada_title') }}</a></h5>
											<p>{{ $t('home.grenada_description') }}
											</p>
										</div>

										<div class="fr-infos-deatil">
											<a href="citizenship-by-investment" data-bs-toggle="modal"
												data-bs-target="#autho-message" class="btn btn-danger"><i
													class="fa fa-search me-2"></i>{{ $t('home.find_out_more') }}</a>
											<a href="citizenship-by-investment" class="btn btn-dark"><i
													class="fa fa-phone"></i></a>
										</div>

									</div>

								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</section>
		<!-- ============================ Citizenship by Investment End ================================== -->
		<!-- ============================ Singapore Top Viewed Properties Start ================================== -->
		<section class="image-cover" style="background:#0f161a no-repeat;">
			<div class="container">

				<div class="row justify-content-center">
					<div class="col-lg-7 col-md-8">
						<div class="sec-heading center light">
							<h2>{{ $t('home.singapore_top_viewed') }}</h2>
							<p>{{ $t('home.singapore_top_viewed_label') }}
							</p>
						</div>
					</div>
				</div>

				<div class="row g-4 justify-content-center">
					<div v-for="(property, index) in sg_properties" :key="index" class="col-lg-4 col-md-6 col-sm-12">
						<div class="property-listing property-2 h-100">
							<div class="listing-img-wrapper">
								<div class="_exlio_125">{{ property.price ? 'For Sale' : 'For Rent' }}</div>
								<div class="list-img-slide">
									<div class="click">
										<div v-for="(image, imgIndex) in property.image_link.slice(0, 3)"
											:key="imgIndex">
											<router-link
												:to="{ name: getRouteNameByGlbId(property.glb_id), params: { glb_id: property.glb_id } }">
												<img :src="image" class="img-fluid mx-auto" alt="" />
											</router-link>
										</div>
									</div>
								</div>
							</div>

							<div class="listing-detail-wrapper">
								<div class="listing-short-detail-wrap">
									<div class="_card_list_flex mb-2">
										<div class="_card_flex_01">
											<span class="_list_blickes _netork" style="margin-right: 8px;">{{
												property.property_type }}</span>
											<span class="_list_blickes types">{{ property.housing }}</span>
										</div>
										<div class="_card_flex_last">
											<h6 class="listing-card-info-price mb-0"> {{ formatPriceSgd(property.price)}}</h6>
										</div>
									</div>
									<div class="_card_list_flex">
										<div class="_card_flex_01">
											<h4 class="listing-name verified">
												<router-link
													:to="{ name: getRouteNameByGlbId(property.glb_id), params: { glb_id: property.glb_id } }"
													class="prt-link-detail">
													{{ property.property_name }} {{ property.area_address }}
												</router-link>
											</h4>
										</div>
									</div>
								</div>
							</div>

							<div class="price-features-wrapper">
								<div class="list-fx-features">
									<div class="listing-card-info-icon">
										<div class="inc-fleat-icon"><img :src="bedSvg" width="15"
												alt="Globalahome Bedroom" /></div>
										{{ property.bedroom }} {{ $t('home.beds') }}
									</div>
									<div class="listing-card-info-icon">
										<div class="inc-fleat-icon"><img :src="bathtubSvg" width="15"
												alt="Globalahome Bathroom" /></div>
										{{ property.bathroom }} {{ $t('home.bath') }}
									</div>
									<div class="listing-card-info-icon">
										<div class="inc-fleat-icon"><img :src="moveSvg" width="15"
												alt="Globalahome Nearest MRT" /></div>
										{{ property.nearest_mrt }}
									</div>
								</div>
							</div>

							<div class="listing-detail-footer">
								<div class="footer-first">
									<div class="foot-location">
										<img :src="pinSvg" width="18" alt="Globalahome Property Location" />
										{{ property.area_address }}
									</div>
								</div>
								<div class="footer-flex">
									<ul class="selio_style">
										<li>
											<div class="prt_saveed_12lk">
												<label class="toggler toggler-danger" data-bs-toggle="tooltip" data-bs-placement="top" 
													:class="{ 'active': isSaved(property.glb_id) }"
													data-bs-title="Save property">
													<input type="checkbox" :checked="isSaved(property.glb_id)" @change="toggleSaveProperty(property)">
													<i class="fa-solid fa-heart"></i>
                                                </label>
                                            </div>
										</li>
										<li>
											<div class="prt_saveed_12lk">
												<a href="/compare-property" data-bs-toggle="tooltip"
													data-bs-placement="top" data-bs-title="Compare property">
													<i class="fa-solid fa-share"></i>
												</a>
											</div>
										</li>
										<li>
											<div class="prt_saveed_12lk">
												<router-link
													:to="{ name: getRouteNameByGlbId(property.glb_id), params: { glb_id: property.glb_id } }"
													data-bs-toggle="tooltip" data-bs-placement="top"
													data-bs-title="View Property">
													<i class="fa-regular fa-circle-right"></i>
												</router-link>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- ============================ Singapore Top Viewed Properties End ================================== -->
		<!-- ============================ Property By Location ================================== -->
		<section>
			<div class="container">

				<div class="row justify-content-center">
					<div class="col-lg-7 col-md-8">
						<div class="sec-heading center">
							<h2>{{ $t('home.top_property_places') }}</h2>
							<p>{{ $t('home.top_property_description') }}</p>
						</div>
					</div>
				</div>

				<div class="row justify-content-center g-4">
					<div class="col-lg-4 col-md-4 col-sm-6">
						<a href="grid-layout-with-sidebar.html" class="img-wrap">
							<div class="location_wrap_content visible">
								<div class="location_wrap_content_first">
									<h4>{{ $t('home.greece') }}</h4>
									<span>13 {{ $t('home.properties') }}</span>
								</div>
								<div class="location_btn"><i class="fa fa-arrow-right"></i></div>
							</div>
							<div class="img-wrap-background"
								:style="{ backgroundImage: `url(${require('@/assets/img/greece_image.jpeg')})` }"></div>
						</a>
					</div>

					<div class="col-lg-4 col-md-4 col-sm-6">
						<a href="grid-layout-with-sidebar.html" class="img-wrap">
							<div class="location_wrap_content visible">
								<div class="location_wrap_content_first">
									<h4>{{ $t('home.malaysia') }}</h4>
									<span>30 {{ $t('home.properties') }}</span>
								</div>
								<div class="location_btn"><i class="fa fa-arrow-right"></i></div>
							</div>
							<div class="img-wrap-background"
								:style="{ backgroundImage: `url(${require('@/assets/img/kuala_lumpur.webp')})` }"></div>
						</a>
					</div>

					<div class="col-lg-4 col-md-4 col-sm-6">
						<a href="grid-layout-with-sidebar.html" class="img-wrap">
							<div class="location_wrap_content visible">
								<div class="location_wrap_content_first">
									<h4>{{ $t('home.singapore') }}</h4>
									<span>40 {{ $t('home.properties') }}</span>
								</div>
								<div class="location_btn"><i class="fa fa-arrow-right"></i></div>
							</div>
							<div class="img-wrap-background"
								:style="{ backgroundImage: `url(${require('@/assets/img/singapore.webp')})` }"></div>
						</a>
					</div>
				</div>
			</div>
		</section>
		<!-- ============================ Property By Location End ================================== -->
		<!-- ============================ Global Visa Services Start ================================== -->
		<section class="gray-simple min">
			<div class="container">

				<div class="row justify-content-center">
					<div class="col-lg-7 col-md-8">
						<div class="sec-heading center">
							<h2>{{ $t('home.global_visa_services') }}</h2>
							<p>{{ $t('home.global_visa_text') }}</p>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-lg-12 col-md-12">
						<div class="item-slide space">

							<!-- Single Item -->
							<div class="single_items">
								<div class="grid_agents">
									<div class="elio_mx_list theme-bg-2">102 {{ $t('home.listings') }}</div>
									<div class="grid_agents-wrap">

										<div class="fr-grid-thumb">
											<a href="#">
												<span class="verified"><img :src="verifiedSvg" class="verify mx-auto"
														alt="Globalahome Global Visa"></span>
												<img :src="greeceFlag" class="img-fluid mx-auto"
													alt="Globalahome Greece Golden Visa">
											</a>
										</div>

										<div class="fr-grid-deatil">
											<span><i class="ti-location-pin me-1"></i>{{ $t('home.greece') }}</span>
											<h5 class="fr-can-name"><a href="#">{{ $t('home.greece_visa_text') }}</a>
											</h5>
											<p>{{ $t('home.greece_visa_description') }}</p>
										</div>

										<div class="fr-infos-deatil">
											<a href="global-visa" data-bs-toggle="modal" data-bs-target="#autho-message"
												class="btn btn-danger"><i class="fa fa-search me-2"></i>{{
													$t('home.find_out_more') }}</a>
											<a href="global-visa" class="btn btn-dark"><i class="fa fa-phone"></i></a>
										</div>

									</div>

								</div>
							</div>

							<!-- Single Item -->
							<div class="single_items">
								<div class="grid_agents">
									<div class="elio_mx_list theme-bg-2">72 {{ $t('home.listings') }}</div>
									<div class="grid_agents-wrap">

										<div class="fr-grid-thumb">
											<a href="#">
												<span class="verified"><img :src="verifiedSvg" class="verify mx-auto"
														alt="Globalahome Global Visa"></span>
												<img :src="spanyolFlag" class="img-fluid mx-auto"
													alt="Globalahome Spain Golden Visa"> </a>
										</div>

										<div class="fr-grid-deatil">
											<span><i class="ti-location-pin me-1"></i>{{ $t('home.spain') }}</span>
											<h5 class="fr-can-name"><a href="#">{{ $t('home.spain_visa_text') }}</a>
											</h5>
											<p>{{ $t('home.spain_visa_description') }}</p>
										</div>

										<div class="fr-infos-deatil">
											<a href="global-visa" data-bs-toggle="modal" data-bs-target="#autho-message"
												class="btn btn-danger"><i class="fa fa-search me-2"></i>{{
													$t('home.find_out_more') }}</a>
											<a href="global-visa" class="btn btn-dark"><i class="fa fa-phone"></i></a>
										</div>

									</div>

								</div>
							</div>

							<!-- Single Item -->
							<div class="single_items">
								<div class="grid_agents">
									<div class="elio_mx_list theme-bg-2">22 {{ $t('home.listings') }}</div>
									<div class="grid_agents-wrap">

										<div class="fr-grid-thumb">
											<a href="#">
												<span class="verified"><img :src="verifiedSvg" class="verify mx-auto"
														alt="Globalahome Global Visa"></span>
												<img :src="portugalFlag" class="img-fluid mx-auto"
													alt="Globalahome Portugal Golden Visa">
											</a>
										</div>

										<div class="fr-grid-deatil">
											<span><i class="ti-location-pin me-1"></i>{{ $t('home.portugal') }}</span>
											<h5 class="fr-can-name"><a href="#">{{ $t('home.portugal_visa_text') }}</a>
											</h5>
											<p>{{ $t('home.portugal_visa_description') }}
											</p>
										</div>

										<div class="fr-infos-deatil">
											<a href="global-visa" data-bs-toggle="modal" data-bs-target="#autho-message"
												class="btn btn-danger"><i class="fa fa-search me-2"></i>{{
													$t('home.find_out_more') }}</a>
											<a href="global-visa" class="btn btn-dark"><i class="fa fa-phone"></i></a>
										</div>

									</div>

								</div>
							</div>

							<!-- Single Item -->
							<div class="single_items">
								<div class="grid_agents">
									<div class="elio_mx_list theme-bg-2">50 {{ $t('home.listings') }}</div>
									<div class="grid_agents-wrap">

										<div class="fr-grid-thumb">
											<a href="#">
												<span class="verified"><img :src="verifiedSvg" class="verify mx-auto"
														alt="Globalahome Global Visa"></span>
												<img :src="ukFlag" class="img-fluid mx-auto"
													alt="Globalahome UK Golden Visa">
											</a>
										</div>

										<div class="fr-grid-deatil">
											<span><i class="ti-location-pin me-1"></i>{{ $t('home.uk') }}</span>
											<h5 class="fr-can-name"><a href="#">{{ $t('home.uk_visa_text') }}</a></h5>
											<p>{{ $t('home.uk_visa_description') }}</p>
										</div>

										<div class="fr-infos-deatil">
											<a href="global-visa" data-bs-toggle="modal" data-bs-target="#autho-message"
												class="btn btn-danger"><i class="fa fa-search me-2"></i>{{
													$t('home.find_out_more') }}</a>
											<a href="global-visa" class="btn btn-dark"><i class="fa fa-phone"></i></a>
										</div>

									</div>

								</div>
							</div>

							<!-- Single Item -->
							<div class="single_items">
								<div class="grid_agents">
									<div class="elio_mx_list theme-bg-2">50 {{ $t('home.listings') }}</div>
									<div class="grid_agents-wrap">

										<div class="fr-grid-thumb">
											<a href="#">
												<span class="verified"><img :src="verifiedSvg" class="verify mx-auto"
														alt="Globalahome Global Visa"></span>
												<img :src="italyFlag" class="img-fluid mx-auto"
													alt="Globalahome Italy Golden Visa">
											</a>
										</div>

										<div class="fr-grid-deatil">
											<span><i class="ti-location-pin me-1"></i>{{ $t('home.italy') }}</span>
											<h5 class="fr-can-name"><a href="#">{{ $t('home.italy_visa_text') }}</a>
											</h5>
											<p>{{ $t('home.italy_visa_description') }}</p>
										</div>

										<div class="fr-infos-deatil">
											<a href="global-visa" data-bs-toggle="modal" data-bs-target="#autho-message"
												class="btn btn-danger"><i class="fa fa-search me-2"></i>{{
													$t('home.find_out_more') }}</a>
											<a href="global-visa" class="btn btn-dark"><i class="fa fa-phone"></i></a>
										</div>

									</div>

								</div>
							</div>


							<!-- Single Item -->
							<div class="single_items">
								<div class="grid_agents">
									<div class="elio_mx_list theme-bg-2">50 {{ $t('home.listings') }}</div>
									<div class="grid_agents-wrap">

										<div class="fr-grid-thumb">
											<a href="#">
												<span class="verified"><img :src="verifiedSvg" class="verify mx-auto"
														alt="Globalahome Global Visa"></span>
												<img :src="usFlag" class="img-fluid mx-auto"
													alt="Globalahome US Golden Visa">
											</a>
										</div>

										<div class="fr-grid-deatil">
											<span><i class="ti-location-pin me-1"></i>{{ $t('home.us') }}</span>
											<h5 class="fr-can-name"><a href="#">{{ $t('home.us_visa_text') }}</a>
											</h5>
											<p>{{ $t('home.us_visa_description') }}</p>
										</div>

										<div class="fr-infos-deatil">
											<a href="global-visa" data-bs-toggle="modal" data-bs-target="#autho-message"
												class="btn btn-danger"><i class="fa fa-search me-2"></i>{{
													$t('home.find_out_more') }}</a>
											<a href="global-visa" class="btn btn-dark"><i class="fa fa-phone"></i></a>
										</div>

									</div>

								</div>
							</div>

							<!-- Single Item -->
							<div class="single_items">
								<div class="grid_agents">
									<div class="elio_mx_list theme-bg-2">50 {{ $t('home.listings') }}</div>
									<div class="grid_agents-wrap">

										<div class="fr-grid-thumb">
											<a href="#">
												<span class="verified"><img :src="verifiedSvg" class="verify mx-auto"
														alt="Globalahome Global Visa"></span>
												<img :src="cyprusFlag" class="img-fluid mx-auto"
													alt="Globalahome Cyprus Golden Visa">
											</a>
										</div>

										<div class="fr-grid-deatil">
											<span><i class="ti-location-pin me-1"></i>{{ $t('home.cyprus') }}</span>
											<h5 class="fr-can-name"><a href="#">{{ $t('home.cyprus_visa_text') }}</a>
											</h5>
											<p>{{ $t('home.cyprus_visa_description') }}</p>
										</div>

										<div class="fr-infos-deatil">
											<a href="global-visa" data-bs-toggle="modal" data-bs-target="#autho-message"
												class="btn btn-danger"><i class="fa fa-search me-2"></i>{{
													$t('home.find_out_more') }}</a>
											<a href="global-visa" class="btn btn-dark"><i class="fa fa-phone"></i></a>
										</div>

									</div>

								</div>
							</div>

						</div>
					</div>
				</div>

			</div>
		</section>
		<!-- ============================ Global Visa Services End ================================== -->
		<!-- ============================ Call To Action ================================== -->
		<PageFooter />
		<!-- ============================ Call To Action End ================================== -->
		<!-- ============================ Footer Start ================================== -->
		
		<!-- ============================ Footer End ================================== -->
	</div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';

import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import bannerHome from '@/assets/img/banner-home.webp';
import citizenshipIcon from '@/assets/img/united-nations.png';
import studyOverseasIcon from '@/assets/img/study.png';
import globalVisaIcon from '@/assets/img/passport.png';
import sampleImage1 from '@/assets/img/p-4.webp';
import sampleImage2 from '@/assets/img/p-5.webp';
import sampleImage3 from '@/assets/img/p-6.webp';
import bedSvg from '@/assets/img/bed.svg';
import bathtubSvg from '@/assets/img/bathtub.svg';
import moveSvg from '@/assets/img/move.svg';
import pinSvg from '@/assets/img/pin.svg';
import greeceImage from '@/assets/img/greece_image.jpeg';
import malaysiaImage from '@/assets/img/kuala_lumpur.webp';
import singaporeImage from '@/assets/img/singapore.webp';
import verifiedSvg from '@/assets/img/verified.svg';
import greeceFlag from '@/assets/img/greece_flag.webp';
import spanyolFlag from '@/assets/img/spanyol_flag.webp';
import portugalFlag from '@/assets/img/portugal_flag.webp';
import ukFlag from '@/assets/img/united-kingdom.webp';
import italyFlag from '@/assets/img/italy.webp';
import usFlag from '@/assets/img/united-states.webp';
import cyprusFlag from '@/assets/img/cyprus_flag.webp';
import grenadaFlag from '@/assets/img/grenada_flag.webp';
import north_macedonia from '@/assets/img/north-macedonia.webp';
import dominica_flag from '@/assets/img/dominica_flag.webp';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';

import { db } from '../../firebase';
import { doc, setDoc, getDocs, query, where, updateDoc, collection, limit } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";


export default {
	name: 'HomePage',
	components: {
		Navigation,
		PageFooter,
	},
	data() {
		return {
			addProperty,
			menuWidget,
			profileLogo,
			globalHomeLogo,
			whatsappLogo,
			wechatLogo,
			loginLogo,
			settingsLogo,
			qrCodeLogo,
			bannerHome,
			citizenshipIcon,
			studyOverseasIcon,
			globalVisaIcon,
			sampleImage1,
			sampleImage2,
			sampleImage3,
			bedSvg,
			bathtubSvg,
			moveSvg,
			pinSvg,
			greeceImage,
			malaysiaImage,
			singaporeImage,
			verifiedSvg,
			greeceFlag,
			spanyolFlag,
			portugalFlag,
			ukFlag,
			italyFlag,
			usFlag,
			cyprusFlag,
			grenadaFlag,
			north_macedonia,
			dominica_flag,
			navMenuVisible: false,
			activeSubmenu: null,
			selectedCategory: 'Buy',
			selectedLocation: '',
			selectedPropertyType: '',
			selectedPriceRange: '',
			properties: [],
			my_properties: [],
			sg_properties: [],
			message: 'Hello, World!',
			userLoggedIn: false,
			loginError: '',
			userSavedProperties: [],
            userEmail: '',
		};
	},
	async mounted() {
		await this.fetchProperties();
	},
	methods: {
		loadUserPreferences() {
            // Load the user preferences from localStorage or use default values
            const savedCurrency = localStorage.getItem('userCurrency') || 'eur'; // Default EUR
            const savedMeasurement = localStorage.getItem('userMeasurement') || 'm2'; // Default square meters
            this.userCurrency = savedCurrency;
            this.userMeasurement = savedMeasurement;
        },
		formatPriceSgd(price) {
            const currencySymbols = {
                'eur': '€',
                'usd': 'US$',
                'sgd': 'S$',
                'myr': 'RM',
            };
            const conversionRates = {
                'usd': 0.74,
                'eur': 0.67, 
                'myr': 3.0, 
                'sgd': 1,
            };

            const convertedPrice = (price * conversionRates[this.userCurrency]).toFixed(0);
            return `${currencySymbols[this.userCurrency]}${convertedPrice}`;
        },
		formatPriceMyr(price) {
            // Logic for converting price based on currency
            const currencySymbols = {
                'eur': '€',
                'usd': 'US$',
                'sgd': 'S$',
                'myr': 'RM',
            };
            const conversionRates = {
                'usd': 0.2,  // Example: MYR to USD rate
                'sgd': 0.3,
                'eur': 0.2,
                'myr': 1,  // Default is MYR
            };

            const convertedPrice = (price * conversionRates[this.userCurrency]).toFixed(0);
            return `${currencySymbols[this.userCurrency]}${convertedPrice}`;
        },
		convertSurfaceAreaSqft(area) {
            // Logic for converting surface area based on measurement units
            if (this.userMeasurement === 'm2') {
                const convertedArea = (area / 10.7639).toFixed(0);  // Convert ft² to m²
                return `${convertedArea} m²`;
            } else {
                // Default is ft²
                return `${area} ft²`;
            }
        },
		convertSurfaceAreaSqm(area) {
            // Logic for converting surface area based on measurement units
            if (this.userMeasurement === 'ft2') {
                const convertedArea = (area * 10.7639).toFixed(0);  // Convert m² to ft²
                return `${convertedArea} ft²`;
            } else {
                return `${area} m²`;  // Default is square meters
            }
        },
		formatPriceEuro(price) {
            // Logic for converting price based on currency
            const currencySymbols = {
                'eur': '€',
                'usd': 'US$',
                'sgd': 'S$',
                'myr': 'RM',
            };
            const conversionRates = {
                'usd': 1.1,  // Example: EUR to USD rate
                'sgd': 1.5,
                'myr': 5.0,
                'eur': 1,  // Default is EUR
            };

            const convertedPrice = (price * conversionRates[this.userCurrency]).toFixed(0);
            return `${currencySymbols[this.userCurrency]}${convertedPrice}`;
        },
		toggleNavMenu() {
			console.log("Toggling navMenuVisible:", this.navMenuVisible);
			this.navMenuVisible = !this.navMenuVisible;
			console.log("New navMenuVisible state:", this.navMenuVisible);
		},
		toggleSubmenu(menu) {
			this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
		},
		async fetchProperties() {
			const q = query(
				collection(db, 'property_listings'),
				where('glb_id', '>=', 'GLB_GR_'),
				where('glb_id', '<', 'GLB_GR_\uf8ff'),
				limit(3)
			);
			const querySnapshot = await getDocs(q);

			// Map the querySnapshot to extract the data
			this.properties = querySnapshot.docs.map(doc => doc.data());

			const s = query(
				collection(db, 'property_listings'),
				where('glb_id', '>=', 'GLB_MY_SALE_'),
				where('glb_id', '<', 'GLB_MY_SALE_\uf8ff'),
				limit(3)
			);
			const querySnapshot_Malaysia = await getDocs(s);

			// Map the querySnapshot to extract the data
			this.my_properties = querySnapshot_Malaysia.docs.map(doc => doc.data());

			const r = query(
				collection(db, 'property_listings'),
				where('glb_id', '>=', 'GLB_SG_SALE_'),
				where('glb_id', '<', 'GLB_SG_SALE_\uf8ff'),
				limit(3)
			);
			const querySnapshot_Singapore = await getDocs(r);

			// Map the querySnapshot to extract the data
			this.sg_properties = querySnapshot_Singapore.docs.map(doc => doc.data());
			// const propertiesCollection = collection(db, "property_listings");
			// const propertySnapshot = await getDocs(propertiesCollection);
			// this.properties = propertySnapshot.docs.map(doc => doc.data());
		},
		getRouteNameByGlbId(glb_id) {
			if (glb_id.startsWith('GLB_GR_')) {
				return 'GreeceListing';
			} else if (glb_id.startsWith('GLB_MY_SALE_NEW_')) {
				return 'MalaysiaListing';
			} else if (glb_id.startsWith('GLB_MY_SALE_SECOND_')) {
				return 'MalaysiaSubsaleListing';
			} else if (glb_id.startsWith('GLB_MY_RENT_')) {
				return 'MalaysiaRentListing';
			} else if (glb_id.startsWith('GLB_SG_SALE_SECOND_')) {
				return 'SingaporeSubsaleListing';
			} else if (glb_id.startsWith('GLB_SG_RENT_')) {
				return 'SingaporeRentListing';
			} else if (glb_id.startsWith('GLB_SG_SALE_NEW_')) {
				return 'SingaporeListing';
			} else {
				return 'NotFound';
			}
		},
		updateCategory(category) {
			this.selectedCategory = category;
			// this.searchProperties(); // Trigger the search when the category changes
		},
		searchProperties() {
			const queryParams = {
				category: this.selectedCategory,
				location: this.selectedLocation || undefined,  // Avoid empty params
				propertyType: this.selectedPropertyType || undefined,
				priceRange: this.selectedPriceRange || undefined,
			};

			// Remove undefined properties from queryParams
			Object.keys(queryParams).forEach(key => queryParams[key] === undefined && delete queryParams[key]);

			this.$router.push({ path: '/property-listing', query: queryParams });
		},
		// Get user email from Firebase Authentication
        getUserEmail() {
            const auth = getAuth();
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    this.userEmail = user.email;
                    // Load saved properties after getting the user email
                    this.loadUserSavedProperties();
                } else {
                    // User is not logged in, handle accordingly
                    console.error("No user is logged in.");
                }
            });
        },

        // Load user's saved properties from Firebase
        async loadUserSavedProperties() {
            if (!this.userEmail) return;  // Make sure the user email is available
            try {
                const savedPropertiesQuery = query(
                    collection(db, 'userSavedProperty'),
                    where('email', '==', this.userEmail),
                    where('active', '==', 1)  // Only active saved properties
                );
                const querySnapshot = await getDocs(savedPropertiesQuery);
                this.userSavedProperties = querySnapshot.docs.map(doc => doc.data().propertyId);
            } catch (error) {
                console.error('Error loading saved properties:', error);
            }
        },

        // Check if the property is saved
        isSaved(propertyId) {
            return this.userSavedProperties.includes(propertyId);
        },

        // Toggle save/unsave property
        async toggleSaveProperty(property) {
            const propertyId = property.glb_id;

            // Check if property is already saved
            const isAlreadySaved = this.isSaved(propertyId);

            // Update the database
            try {
                const docRef = doc(db, 'userSavedProperty', `${this.userEmail}_${propertyId}`);
                if (isAlreadySaved) {
                    // If already saved, deactivate the saved record
                    await updateDoc(docRef, {
                        active: 0
                    });
                    // Update local saved state
                    this.userSavedProperties = this.userSavedProperties.filter(id => id !== propertyId);
                } else {
                    // If not saved, save the property
                    await setDoc(docRef, {
                        email: this.userEmail,
                        propertyId: propertyId,
                        active: 1,
                        savedAt: new Date()
                    });
                    // Add the property to the local saved list
                    this.userSavedProperties.push(propertyId);
                }
            } catch (error) {
                console.error('Error saving property:', error);
            }
        },
	},
	created() {
		this.getUserEmail();
        this.loadUserPreferences();  // Load user preferences for currency and measurement
    },
};

</script>

<style scoped>
.nav-container {
	margin-bottom: 0px;
	display: flex;
	justify-content: flex-end;
	/* Aligns the button to the right */
}

.nav-dropdown-toggle {
	background-color: white;
	/* Set background color to white */
	color: #000000;
	/* Set icon color */
	padding: 5px;
	/* Adjust padding to ensure icon is centered */
	border: none;
	cursor: pointer;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	/* Center the icon */
	width: 30px;
	/* Set a fixed width for the button */
	height: 30px;
	/* Set a fixed height for the button */
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	/* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
	margin-left: 0;
	/* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
	transition: max-height 0.3s ease-out;
}

.hello {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	text-align: center;
	color: #2c3e50;
	margin-top: 60px;
}

.add-listing {
	margin-right: 10px;
	/* Add space between list items */
}

.add-listing a {
	border-radius: 8px;
	/* Optional: Add some border radius for better visual spacing */
	text-align: center;
	/* Center align the text and image */
	white-space: nowrap;
	/* Ensure text doesn't wrap to the next line */
}

.d-flex {
	flex-wrap: nowrap;
	/* Prevent items from wrapping onto the next line */
}

.bg-danger {
	background-color: #d9534f;
	/* Ensure consistent background color */
	color: white;
	/* Text color for contrast */
}

a img {
	vertical-align: middle;
	/* Align the images with text */
}

.input-with-icon select.form-control {
	padding-bottom: 1px;
	/* Adjust the value as needed */
}

.header.header-light {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;
	/* Ensures it stays on top of other elements */
	background-color: white;
	/* Keep the background color to avoid transparency issues */
}

#main-wrapper {
	padding-top: 70px;
	/* Adjust this based on your header's height */
}

/* Optional: Add a box-shadow to the fixed navigation for better visual appeal */
.header.header-light {
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
	display: inline-block;
	margin-left: 5px;
	transition: transform 0.3s;
}

.nav-menu li ul {
	display: none;
}

.nav-menu li ul.nav-submenu {
	display: block;
}

.nav-menu li.active>.submenu-indicator {
	transform: rotate(90deg);
}

.resp_log_thumb {
	margin-top: 100px;
	margin-left: 40px;
	width: 250px;
	height: 110px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.form-check-label {
	margin-left: 10px;
}

.tagline {
	margin-top: 150px;
	margin-left: 50px;
}
</style>
