<template>
    <div :key="$i18n.locale" class="yellow-skin">
        <div id="main-wrapper">
            <!-- Start Navigation -->
            <Navigation />
            <!-- ============================ End Navigation ================================== -->
            <div class="clearfix"></div>
            <!-- ============================ Page Title Start================================== -->
            <div class="page-title" :style="{ background: `#f4f4f4 url(${bgImage})` }" data-overlay="5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">

                            <div class="breadcrumbs-wrap position-relative z-1">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item active" aria-current="page">Compare</li>
                                </ol>
                                <h2 class="breadcrumb-title">Compare - Compare property</h2>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- ============================ Page Title End ================================== -->

            <!-- ============================ Compare Property Start ================================== -->
            <section>
                <div class="container">
                    <div class="pricing packages_style_5">

                        <div class="row">
                            <div class="col-lg-3 text-center d-lg-block d-md-none d-sm-none d-none">
                                <div class="compare_property_blank">
                                </div>
                                <ul>
                                    <li>
                                        <span>Area</span>
                                    </li>
                                    <li>
                                        <span>Bedrooms</span>
                                    </li>
                                    <li>
                                        <span>Bathrooms</span>
                                    </li>
                                    <li>
                                        <span>Price per sqm</span>
                                    </li>
                                    <li>
                                        <span>Floor</span>
                                    </li>
                                    <li>
                                        <span>Total floors</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-12 text-center" v-for="(property, index) in properties"
                                :key="index">
                                <div class="comp_properties">
                                    <a href="#">
                                        <div class="clp-img">
                                            <img :src="property.image_link[0]" class="img-fluid" alt="">
                                            <span class="remove-from-compare"><i class="ti-close"></i></span>
                                        </div>

                                        <div class="clp-title">
                                            <h4>{{ property.property_name }}</h4>
                                            <span>{{ property.currency }}{{ property.price }}</span>
                                        </div>
                                    </a>
                                </div>
                                <ul>
                                    <li>
                                        {{ property.surface_area }}
                                        <span class="show-mb">Area</span>
                                    </li>
                                    <li>
                                        {{ property.bedroom }}
                                        <span class="show-mb">Bedrooms</span>
                                    </li>
                                    <li>
                                        {{ property.bathroom }}
                                        <span class="show-mb">Bathrooms</span>
                                    </li>
                                    <li>
                                        {{ property.currency }}{{ property.price_per_sqm }}
                                        <span class="show-mb">Price per sqm</span>
                                    </li>
                                    <li>
                                        {{ property.floor }}
                                        <span class="show-mb"> Floor</span>
                                    </li>
                                    <li>
                                        {{ property.total_floors }}
                                        <span class="show-mb">Total floors</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <!-- ============================ Compare Property End ================================== -->
            <!-- ============================ Agency List End ================================== -->

            <!-- ============================ Call To Action ================================== -->
            <PageFooter />
            <!-- ============================ Call To Action End ================================== -->
            <!-- ============================ Footer Start ================================== -->
            <!-- ============================ Footer End ================================== -->
        </div>
    </div>
</template>

<script>
import 'magnific-popup';

import { db } from '../../firebase';
import { collection, getDocs, query, where, limit } from 'firebase/firestore';

import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';

import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import sampleImage1 from '@/assets/img/p-4.webp';
import oldIcon from '@/assets/img/old.png';
import grenadaFlag from '@/assets/img/grenada_flag.webp';
import north_macedonia from '@/assets/img/north-macedonia.webp';
import dominica_flag from '@/assets/img/dominica_flag.webp';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';
import bgImage from '@/assets/img/bg.jpg';


export default {
    name: 'CompareProperty',
    components: {
        Navigation,
        PageFooter,
    },
    props: ['glb_id'],
    data() {
        return {
            bgImage,
            addProperty,
            menuWidget,
            profileLogo,
            forgotPasswordEmail: '',
            resetPasswordError: '',
            resetPasswordSuccess: '',
            selectedLanguage: this.$i18n.locale,
            phoneNumber: '',
            loginEmail: '',
            loginPassword: '',
            fullName: '',
            registerEmail: '',
            registerPassword: '',
            globalHomeLogo,
            whatsappLogo,
            wechatLogo,
            loginLogo,
            settingsLogo,
            qrCodeLogo,
            sampleImage1,
            grenadaFlag,
            north_macedonia,
            dominica_flag,
            oldIcon,
            navMenuVisible: false,
            activeSubmenu: null,
            userLoggedIn: false,
            loginError: '',
            properties: [],
            my_properties: [],
            sg_properties: [],
        };
    },
    async mounted() {
        await this.fetchProperties();
    },
    methods: {
        async fetchProperties() {
            try {
                // Fetch 3 properties from Greece
                const greeceQuery = query(
                    collection(db, 'property_listings'),
                    where('glb_id', '>=', 'GLB_GR_'),
                    where('glb_id', '<', 'GLB_GR_\uf8ff'),
                    limit(3)
                );
                const greeceQuerySnapshot = await getDocs(greeceQuery);
                this.properties = greeceQuerySnapshot.docs.map(doc => doc.data());
                console.log('Greece Properties:', this.properties);

                // Fetch 3 properties from Malaysia
                const malaysiaQuery = query(
                    collection(db, 'property_listings'),
                    where('glb_id', '>=', 'GLB_MY_SALE_'),
                    where('glb_id', '<', 'GLB_MY_SALE_\uf8ff'),
                    limit(3)
                );
                const malaysiaQuerySnapshot = await getDocs(malaysiaQuery);
                this.my_properties = malaysiaQuerySnapshot.docs.map(doc => doc.data());
                console.log('Malaysia Properties:', this.my_properties);

                // Fetch 3 properties from Singapore
                const singaporeQuery = query(
                    collection(db, 'property_listings'),
                    where('glb_id', '>=', 'GLB_SG_SALE_'),
                    where('glb_id', '<', 'GLB_SG_SALE_\uf8ff'),
                    limit(3)
                );
                const singaporeQuerySnapshot = await getDocs(singaporeQuery);
                this.sg_properties = singaporeQuerySnapshot.docs.map(doc => doc.data());
                console.log('Singapore Properties:', this.sg_properties);
            } catch (error) {
                console.error('Error fetching properties:', error);
            }
        },
        toggleNavMenu() {
            this.navMenuVisible = !this.navMenuVisible;
        },
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
    },
};
</script>

<style scoped>
.pricing ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.pricing ul li {
    display: flex;
    justify-content: flex-start; /* Align items to start */
    align-items: flex-start;
    padding: 10px 0;
    background: #f4f4f4;
    min-height: 50px;
    flex-wrap: wrap; /* Allow wrapping of content inside */
    white-space: normal;
    word-wrap: break-word;
    overflow: hidden; /* Ensure no overflow */
}


.pricing ul li span {
    white-space: normal; /* Allow text to wrap */
    word-wrap: break-word; /* Break long words to avoid overflow */
}

.col-lg-3 ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
}

.col-lg-3, .col-md-4, .col-sm-12 {
    width: 25%; /* Adjust the percentage based on your layout needs */
}

.col-lg-3 ul li span {
    width: 100%; /* Ensure spans inside li take full width */
    text-align: center; /* Ensure text aligns properly */
}

.col-lg-3 ul li {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 0;
    background: #f4f4f4;
    min-height: 50px;
    white-space: normal; /* Allow text to wrap */
    word-wrap: break-word; /* Break long words to avoid overflow */
    width: 100%; /* Ensure the li element takes full width */
}

.col-lg-3 ul li span {
    white-space: normal; /* Allow text to wrap */
    word-wrap: break-word; /* Break long words to avoid overflow */
}

.compare_property_blank {
    width: 100%;
    text-align: center; /* Ensure text is aligned to the left */
}

.comp_properties {
    width: 100%;
    text-align: center; /* Ensure text is aligned to the left */
}

.clp-title {
    padding: 10px 0;
    text-align: center;
}

.clp-img img {
    width: 100%;
    height: auto;
}

.clp-img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px; /* Adjust height as needed */
}

.show-mb {
    display: none;
}

@media (max-width: 768px) {
    .show-mb {
        display: block;
    }

    .compare_property_blank,
    .comp_properties {
        margin-bottom: 20px;
    }
}



.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
    background-color: white;
    /* Set background color to white */
    color: #000000;
    /* Set icon color */
    /* padding: 5px; */
    /* Adjust padding to ensure icon is centered */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the icon */
    width: 50px;
    /* Set a fixed width for the button */
    height: 50px;
    /* Set a fixed height for the button */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
}

.add-listing a {
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

.d-flex {
    flex-wrap: nowrap;
}

.bg-danger {
    background-color: #d9534f;
    color: white;
}

a img {
    vertical-align: middle;
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
}

#main-wrapper {
    padding-top: 70px;
}

.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.input-with-icon select.form-control {
    padding-bottom: 1px;
    /* Adjust the value as needed */
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left: 40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 70px;
    margin-left: 50px;
}
</style>
