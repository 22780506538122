<template>
    <div :key="$i18n.locale" class="yellow-skin">
        <div id="main-wrapper">
            <!-- Start Navigation -->
            <Navigation />
            <!-- ============================ End Navigation ================================== -->
            <div class="clearfix"></div>
            <!-- ============================ Page Title Start================================== -->
            <div class="page-title" :style="{ background: `#f4f4f4 url(${bgImage})` }" data-overlay="5">
                <div class="ht-80"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 position-relative z-1">
                            <div class="_page_tetio">
                                <div class="pledtio_wrap"><span>{{ $t('contactUs.getInTouch') }}</span></div>
                                <h2 class="text-light mb-0">{{ $t('contactUs.getHelpSupport') }}</h2>
                                <p>{{ $t('contactUs.supportMessage') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ht-120"></div>
            </div>
            <!-- ============================ Page Title End ================================== -->

            <!-- ============================ Agency List Start ================================== -->
            <section class="pt-0">
                <div class="container">
                    <div class="row align-items-center pretio_top">

                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="contact-box">
                                <i class="ti-shopping-cart text-danger"></i>
                                <h4>{{ $t('contactUs.contactSupport') }}</h4>
                                <p>globalahome@gmail.com</p>
                                <span>+1 929-578-6566</span>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="contact-box">
                                <i class="ti-user text-danger"></i>
                                <h4>{{ $t('contactUs.contactSales') }}</h4>
                                <p>globalahome@gmail.com</p>
                                <span>+1 929-578-6566</span>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="contact-box">
                                <i class="ti-comment-alt text-danger"></i>
                                <h4>{{ $t('contactUs.startLiveChat') }}</h4>
                                <span>+1 929-578-6566</span>
                                <span class="live-chat">{{ $t('contactUs.startLiveChat') }}</span>
                            </div>
                        </div>

                    </div>

                    <!-- row Start -->
                    <div class="row">
                        <div class="col-lg-8 col-md-7">
                            <div class="property_block_wrap">

                                <div class="property_block_wrap_header">
                                    <h4 class="property_block_title">{{ $t('contactUs.fillForm') }}</h4>
                                </div>

                                <div class="block-body">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>{{ $t('contactUs.name') }}</label>
                                                <input type="text" class="form-control simple">
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>{{ $t('contactUs.email') }}</label>
                                                <input type="email" class="form-control simple">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <label>{{ $t('contactUs.phoneNumber') }}</label>
                                                <input type="text" class="form-control simple">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label>{{ $t('contactUs.subject') }}</label>
                                        <input type="text" class="form-control simple">
                                    </div>

                                    <div class="form-group">
                                        <label>{{ $t('contactUs.message') }}</label>
                                        <textarea class="form-control simple"></textarea>
                                    </div>

                                    <div class="form-group">
                                        <button class="btn btn-danger" type="submit">{{ $t('contactUs.submitRequest') }}</button>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="col-lg-4 col-md-5">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d255278.1512332508!2d103.65923082233377!3d1.343740392608978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1767b42b8ec9%3A0x400f7acaedaa420!2sSingapore!5e0!3m2!1sen!2smy!4v1726826281967!5m2!1sen!2smy"
                                width="100%" height="470" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                        </div>

                    </div>
                    <!-- /row -->
                </div>
            </section>
            <!-- ============================ Agency List End ================================== -->

            <!-- ============================ Call To Action ================================== -->
            <PageFooter />
            <!-- ============================ Call To Action End ================================== -->
            <!-- ============================ Footer Start ================================== -->
            <!-- ============================ Footer End ================================== -->
        </div>
    </div>
</template>

<script>
import 'magnific-popup';

import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';

import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import sampleImage1 from '@/assets/img/p-4.webp';
import oldIcon from '@/assets/img/old.png';
import grenadaFlag from '@/assets/img/grenada_flag.webp';
import north_macedonia from '@/assets/img/north-macedonia.webp';
import dominica_flag from '@/assets/img/dominica_flag.webp';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';
import bgImage from '@/assets/img/bg.jpg';


export default {
    name: 'ContactUs',
    components: {
        Navigation,
        PageFooter,
    },
    props: ['glb_id'],
    data() {
        return {
            bgImage,
            addProperty,
            menuWidget,
            profileLogo,
            globalHomeLogo,
            whatsappLogo,
            wechatLogo,
            loginLogo,
            settingsLogo,
            qrCodeLogo,
            sampleImage1,
            grenadaFlag,
            north_macedonia,
            dominica_flag,
            oldIcon,
            navMenuVisible: false,
            activeSubmenu: null,
            userLoggedIn: false,
            loginError: '',
        };
    },
    methods: {
        toggleNavMenu() {
            this.navMenuVisible = !this.navMenuVisible;
        },
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
    },
};
</script>

<style scoped>
.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
    background-color: white;
    /* Set background color to white */
    color: #000000;
    /* Set icon color */
    /* padding: 5px; */
    /* Adjust padding to ensure icon is centered */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the icon */
    width: 50px;
    /* Set a fixed width for the button */
    height: 50px;
    /* Set a fixed height for the button */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
}

.add-listing a {
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

.d-flex {
    flex-wrap: nowrap;
}

.bg-danger {
    background-color: #d9534f;
    color: white;
}

a img {
    vertical-align: middle;
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
}

#main-wrapper {
    padding-top: 70px;
}

.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.input-with-icon select.form-control {
    padding-bottom: 1px;
    /* Adjust the value as needed */
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left: 40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 70px;
    margin-left: 50px;
}
</style>
