<template>
    <div :key="$i18n.locale" class="yellow-skin">

        <!-- This is the preloader -->
        <!-- <div class="preloader"></div> -->
        <div id="main-wrapper">
            <!-- Start Navigation -->
            <Navigation />
            <!-- ============================ End Navigation ================================== -->

            <div class="clearfix"></div>
            <!-- ============================ Page Title Start================================== -->
            <div class="page-title" :style="{ background: `#f4f4f4 url(${bgImage})` }" data-overlay="5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">

                            <div class="breadcrumbs-wrap position-relative z-1">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item active" aria-current="page">{{
                                        $t('change_password.change_password') }}</li>
                                </ol>
                                <h2 class="breadcrumb-title">{{ $t('change_password.edit_change_password') }}</h2>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- ============================ Page Title End ================================== -->

            <!-- ============================ User Dashboard ================================== -->
            <section class="gray pt-5 pb-5">
                <div class="container-fluid">

                    <div class="row">

                        <div class="col-lg-3 col-md-4">
                            <div class="property_dashboard_navbar">

                                <div class="dash_user_avater">
                                    <img :src="profileLogo" class="img-fluid avater" alt="">
                                    <h4>{{ userProfile.fullName }}</h4>
                                    <span>{{ userProfile.email }}</span>
                                </div>

                                <div class="dash_user_menues">
                                    <ul>
                                        <li><router-link to="/user-dashboard"><i class="fa fa-tachometer-alt"></i>{{
                                                $t('change_password.dashboard') }}<span
                                                    class="notti_coun style-1">4</span></router-link></li>
                                        <li><router-link to="/user-profile"><i class="fa fa-user-tie"></i>{{
                                                $t('change_password.my_profile') }}</router-link></li>
                                        <li><router-link to="/user-saved-property"><i class="fa fa-bookmark"></i>{{
                                                $t('change_password.saved_property') }}<span
                                                    class="notti_coun style-2">7</span></router-link></li>
                                        <li><router-link to="/user-property"><i class="fa fa-tasks"></i>{{
                                                $t('change_password.my_properties') }}</router-link></li>
                                        <li><router-link to="/user-subscription"><i class="fa fa-gift"></i>{{
                                                $t('change_password.my_subscription') }}<span class="expiration">10 days
                                                    left</span></router-link></li>
                                        <li class="active"><router-link to="/user-change-password"><i
                                                    class="fa fa-unlock-alt"></i>{{
                                                        $t('change_password.change_password_label') }}</router-link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-9 col-md-8">
                            <div class="dashboard-body">

                                <div class="dashboard-wraper">

                                    <!-- Basic Information -->
                                    <div class="frm_submit_block">
                                        <h4 class="ms-3 mb-4">{{ $t('change_password.change_your_password') }}</h4>
                                        <div class="frm_submit_wrap">
                                            <div class="form-row">

                                                <div class="form-group col-lg-7 col-md-10">
                                                    <label>{{ $t('change_password.old_password') }}</label>
                                                    <input type="password" class="form-control" v-model="oldPassword">
                                                </div>

                                                <div class="form-group col-lg-7 col-md-10">
                                                    <label>{{ $t('change_password.new_password') }}</label>
                                                    <input type="password" class="form-control" v-model="newPassword">
                                                </div>

                                                <div class="form-group col-lg-7 col-md-10">
                                                    <label>{{ $t('change_password.confirm_password') }}</label>
                                                    <input type="password" class="form-control"
                                                        v-model="confirmPassword">
                                                </div>

                                                <div class="form-group col-lg-7 col-md-10">
                                                    <button class="btn btn-danger" @click="updatePassword">{{
                                                        $t('change_password.save_changes') }}</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <!-- ============================ User Dashboard End ================================== -->

            <!-- ============================ Call To Action ================================== -->
            <PageFooter />
            <!-- ============================ Call To Action End ================================== -->
            <!-- ============================ Footer Start ================================== -->
            <!-- ============================ Footer End ================================== -->
        </div>
    </div>
</template>

<script>
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword, signOut } from "firebase/auth";
import { auth, db } from '../../firebase'; // Import the auth object
import {
    onAuthStateChanged,
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

import 'magnific-popup';
import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';

import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import sampleImage1 from '@/assets/img/p-4.webp';
import oldIcon from '@/assets/img/old.png';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';
import bgImage from '@/assets/img/bg.jpg';


export default {
    name: 'UserDashboard',
    components: {
        Navigation,
        PageFooter,
    },
    props: ['glb_id'],
    data() {
        return {
            bgImage,
            addProperty,
            menuWidget,
            profileLogo,
            qrCodeLogo,
            sampleImage1,
            oldIcon,
            activeSubmenu: null,
            navMenuVisible: false,
            userLoggedIn: false,
            loginError: '',
            oldPassword: '',   
            newPassword: '',
            confirmPassword: '',
            userProfile: {
                fullName: '',
                email: '',
            },
        };
    },
    methods: {
        // Function to update the password
        async updatePassword() {
            // Clear password fields after the operation
            const clearPasswordFields = () => {
                this.oldPassword = '';
                this.newPassword = '';
                this.confirmPassword = '';
            };

            // Check if new password and confirm password match
            if (this.newPassword !== this.confirmPassword) {
                alert("New password and confirm password do not match.");
                clearPasswordFields(); // Clear the password fields even if there's an error
                return;
            }

            try {
                const user = auth.currentUser; // Get current authenticated user

                if (user) {
                    // Reauthenticate the user using their old password
                    const credential = EmailAuthProvider.credential(
                        user.email,
                        this.oldPassword // Old password entered by the user
                    );

                    await reauthenticateWithCredential(user, credential); // Reauthenticate with Firebase

                    // Update the user's password
                    await updatePassword(user, this.newPassword);

                    // On successful password change, log out and redirect to the home page
                    alert("Password updated successfully. You will be logged out.");
                    clearPasswordFields(); // Clear the password fields before logging out

                    // Log out the user
                    await signOut(auth);

                    // Redirect to home page
                    this.$router.push('/');
                } else {
                    alert("No user is currently signed in.");
                }
            } catch (error) {
                console.error("Error updating password: ", error);
                alert("Error updating password. Please check your old password and try again.");
                clearPasswordFields(); // Clear the password fields even if there's an error
            }
        },
        async fetchUserProfile(email) {
            try {
                // Fetch user profile from Firestore using email as the document ID
                const docRef = doc(db, 'userProfiles', email);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    this.userProfile = docSnap.data(); // Update user profile data with the fetched document
                } else {
                    console.log("No user profile found.");
                }
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        },
        toggleNavMenu() {
            this.navMenuVisible = !this.navMenuVisible;
        },
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
    },
    created() {
        // Check authentication state on component creation
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.userLoggedIn = true;
                this.fetchUserProfile(user.email);
            } else {
                this.userLoggedIn = false;
                this.userName = 'Guest';
                this.userEmail = '';
            }
        });
        const savedLanguage = localStorage.getItem('userLanguage');
        if (savedLanguage) {
            this.selectedLanguage = savedLanguage;
            this.$i18n.locale = savedLanguage;
        }
    },
};
</script>

<style scoped>
.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
    background-color: white;
    /* Set background color to white */
    color: #000000;
    /* Set icon color */
    /* padding: 5px; */
    /* Adjust padding to ensure icon is centered */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the icon */
    width: 50px;
    /* Set a fixed width for the button */
    height: 50px;
    /* Set a fixed height for the button */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.input-with-icon select.form-control {
    padding-bottom: 1px;
    /* Adjust the value as needed */
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
}

.add-listing a {
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

.d-flex {
    flex-wrap: nowrap;
}

.bg-danger {
    background-color: #d9534f;
    color: white;
}

a img {
    vertical-align: middle;
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
}

#main-wrapper {
    padding-top: 70px;
}

.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left: 40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 150px;
    margin-left: 50px;
}
</style>
