<template>
    <div :key="$i18n.locale" class="yellow-skin">
        <div id="main-wrapper">
            <!-- Start Navigation -->
            <Navigation />
            <div class="clearfix"></div>
            <div class="page-title" :style="{ background: `#f4f4f4 url(${bgImage})` }" data-overlay="5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="breadcrumbs-wrap position-relative z-1">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item active" aria-current="page">Add Blog Post</li>
                                </ol>
                                <h2 class="breadcrumb-title">Add Blog Post</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section class="gray-simple">
                <div class="container">
                    <div class="row m-0">
                        <div class="submit-page mb-4">
                            <div class="col-lg-12 col-md-12">
                                <div class="alert alert-success text-center mb-0" role="alert">
                                    Hi Dear, Have you already an account? <a href="#" data-bs-toggle="collapse"
                                        data-bs-target="#login-frm">Please Login</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row frm_submit_block">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="submit-page mb-4">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h3 class="ms-0">Add Blog Post</h3>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label>Author Name<i class="req">*</i></label>
                                            <input v-model="blogPost.authorName" type="text" style="color: black;" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label>Author Email<i class="req">*</i></label>
                                            <input v-model="blogPost.authorEmail" type="text" style="color: black;" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <label>Blog Title</label>
                                            <input v-model="blogPost.title" type="text" style="color: black;" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <label>Blog Category<i class="req">*</i></label>
                                            <select v-model="blogPost.category" id="blogCategory" class="form-control" style="color: black;">
                                                <option value="">&nbsp;</option>
                                                <option value="Greece Real Estate">Greece Real Estate</option>
                                                <option value="Malaysia Real Estate">Malaysia Real Estate</option>
                                                <option value="Singapore Real Estate">Singapore Real Estate</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label>Blog keyword 1<i class="req">*</i></label>
                                            <input v-model="blogPost.keyword1" type="text" style="color: black;" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label>Blog keyword 2<i class="req">*</i></label>
                                            <input v-model="blogPost.keyword2" type="text" style="color: black;" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label>Blog keyword 3<i class="req">*</i></label>
                                            <input v-model="blogPost.keyword3" type="text" style="color: black;" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <label>Blog Subheading 1</label>
                                            <input v-model="blogPost.subheading1" type="text" style="color: black;" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <label>Blog Description 1</label>
                                            <textarea v-model="blogPost.description1" style="color: black;" class="form-control ht-50"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <label>Blog Subheading 2</label>
                                            <input v-model="blogPost.subheading2" type="text" style="color: black;" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <label>Blog Description 2</label>
                                            <textarea v-model="blogPost.description2" class="form-control ht-50" style="color: black;"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <label>Blog Subheading 3</label>
                                            <input v-model="blogPost.subheading3" type="text" style="color: black;" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <label>Blog Description 3</label>
                                            <textarea v-model="blogPost.description3" class="form-control ht-50" style="color: black;"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <label>Important Quote</label>
                                            <input v-model="blogPost.quote" type="text" style="color: black;" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <label>Additional Information</label>
                                            <textarea v-model="blogPost.additionalInfo" class="form-control ht-50" style="color: black;"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <button class="btn btn-danger btn-lg" type="button" @click="saveBlogPost">
                                            Save Blog Post
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <PageFooter />
        </div>
    </div>
</template>

<script>
import 'magnific-popup';

import { db } from '../../firebase'; 
import { addDoc, collection } from 'firebase/firestore';

import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';

import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import sampleImage1 from '@/assets/img/p-4.webp';
import oldIcon from '@/assets/img/old.png';
import grenadaFlag from '@/assets/img/grenada_flag.webp';
import north_macedonia from '@/assets/img/north-macedonia.webp';
import dominica_flag from '@/assets/img/dominica_flag.webp';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';
import bgImage from '@/assets/img/bg.jpg';


export default {
    name: 'AddBlogPost',
    components: {
        Navigation,
        PageFooter,
    },
    props: ['glb_id'],
    data() {
        return {
            bgImage,
            addProperty,
            menuWidget,
            profileLogo,
            globalHomeLogo,
            whatsappLogo,
            wechatLogo,
            loginLogo,
            settingsLogo,
            qrCodeLogo,
            sampleImage1,
            grenadaFlag,
            north_macedonia,
            dominica_flag,
            oldIcon,
            navMenuVisible: false,
            activeSubmenu: null,
            userLoggedIn: false,
            loginError: '',
            blogPost: {
                authorName: '',
                authorEmail: '',
                title: '',
                category: '',
                keyword1: '',
                keyword2: '',
                keyword3: '',
                subheading1: '',
                description1: '',
                subheading2: '',
                description2: '',
                subheading3: '',
                description3: '',
                quote: '',
                additionalInfo: ''
            }
        };
    },
    methods: {
        async saveBlogPost() {
            try {
                // Save blogPost data to the 'blogpost' collection in Firebase
                await addDoc(collection(db, 'blogpost'), this.blogPost);
                alert('Blog post saved successfully!');
            } catch (error) {
                console.error('Error saving blog post:', error);
                alert('Failed to save blog post.');
            }
        },
        toggleNavMenu() {
            this.navMenuVisible = !this.navMenuVisible;
        },
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
    },
};
</script>

<style scoped>
.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
    background-color: white;
    /* Set background color to white */
    color: #000000;
    /* Set icon color */
    /* padding: 5px; */
    /* Adjust padding to ensure icon is centered */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the icon */
    width: 50px;
    /* Set a fixed width for the button */
    height: 50px;
    /* Set a fixed height for the button */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
}

.add-listing a {
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

.d-flex {
    flex-wrap: nowrap;
}

.bg-danger {
    background-color: #d9534f;
    color: white;
}

a img {
    vertical-align: middle;
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
}

#main-wrapper {
    padding-top: 70px;
}

.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.input-with-icon select.form-control {
    padding-bottom: 1px;
    /* Adjust the value as needed */
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left: 40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 70px;
    margin-left: 50px;
}
</style>
