// src/stores/listingMalaysiaStore.js
import { defineStore } from 'pinia';
import { db } from '../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

export const useListingMalaysiaStore = defineStore('listingMalaysiaStore', {
  state: () => ({
    listings: [],
    isLoading: false,
  }),
  actions: {
    async fetchListings() {
      if (this.listings.length > 0) return; // Avoid refetching if already loaded

      this.isLoading = true;
      try {
        // Fetch only Malaysian properties based on the `glb_id` prefix for Malaysia
        const listingsQuery = query(
          collection(db, 'property_listings'),
          where('glb_id', '>=', 'GLB_MY_'),
          where('glb_id', '<', 'GLB_MY_\uf8ff')
        );
        const querySnapshot = await getDocs(listingsQuery);
        this.listings = querySnapshot.docs.map(doc => doc.data());
      } catch (error) {
        console.error('Error fetching listings:', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
